import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import Link from 'next/link';
import { Button, Checkbox, Form, Input, message, Modal, Row, Select } from 'antd';

import useIpApi from '~/hooks/useIpApi';
import { trackEvent } from '~/lib/analytics';
import { isProd } from '~/utils/env';
import { sendEvent } from '~/lib/google-analytics-four';

import WhatsappButton from '~/components/WhatsappButton';
import AgentAvatar from '~/components/AgentAvatar';
import PropertyAddress from '~/components/PropertyAddress';
import MainFeatures from '~/components/MainFeatures';
import PersonalCollectionStatement from '~/components/PersonalCollectionStatement';

import CreatePropertyEnquiryMutation from '~/components/PropertyEnquiryForm/Operations.graphql';
import { PropertyEnquiryEnquiries } from '~/constants/enquiry';

type PropertyEnquiryFormProps = {
    agent?: Record<string, any>;
    buttonStyle?: Record<string, any>;
    propertyId: string;
    propertyType?: string;
    propertyStatus?: string;
    showInspections?: boolean;
};

const PropertyEnquiryForm: React.FC<PropertyEnquiryFormProps> = (props) => {
    const { propertyId, propertyType, propertyStatus = '', agent, buttonStyle = {}, showInspections = false } = props;
    const { ipApiData } = useIpApi();

    const [form] = Form.useForm();
    const { resetFields } = form;

    const [createPropertyEnquiry] = useMutation(CreatePropertyEnquiryMutation);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = agent || {};

    const handleSubmit = (values) => {
        setIsLoading(true);

        const input = {
            ...values,
            propertyId,
            agentId: id,
            referrer: window.document.referrer || null,
            pageUrl: window.location.href,
            ipInfo: {
                ipAddress: ipApiData?.query,
                continent: ipApiData?.continent,
                continentCode: ipApiData?.continentCode,
                country: ipApiData?.country,
                countryCode: ipApiData?.countryCode,
                region: ipApiData?.region,
                regionName: ipApiData?.regionName,
                city: ipApiData?.city,
                district: ipApiData?.district,
                zip: ipApiData?.zip,
                lat: ipApiData?.lat,
                lon: ipApiData?.lon,
                timezone: ipApiData?.timezone,
                offset: ipApiData?.offset,
                currency: ipApiData?.currency,
                isp: ipApiData?.isp,
                org: ipApiData?.org,
                as: ipApiData?.as,
                asname: ipApiData?.asname,
                mobile: ipApiData?.mobile,
            },
        };
        if (isProd) {
            createPropertyEnquiry({ variables: { input } })
                .then(() => {
                    trackEvent('Submitted Property Enquiry', { dimension_1: parseInt(propertyId, 10) });
                    // window?.ta('send', 'lead');
                    // window?.gtag('event', 'conversion', { send_to: 'AW-665772328/ViVbCPjursQBEKjCu70C' });
                    sendEvent('property-enquiry');

                    Modal.success({
                        title: 'Enquiry Successfully Sent',
                        onOk: () => resetFields(),
                    });
                })
                .catch(() => {
                    message.error('Encountered an Error');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            Modal.error({ title: 'Something went wrong' });
            setIsLoading(false);
        }
    };

    const handleSubmitFailed = () => {
        trackEvent('Attempted Property Enquiry', { dimension_1: parseInt(propertyId, 10) });
    };

    let selectOptions;
    let defaultEnquiries = [];
    switch (true) {
        case propertyType === 'RENTAL' && propertyStatus === 'CURRENT':
            defaultEnquiries = [PropertyEnquiryEnquiries.OFI];
            selectOptions = (
                <Select size="large" mode="multiple" style={{ width: '100%' }}>
                    <Select.Option disabled={!showInspections} key={PropertyEnquiryEnquiries.OFI}>
                        Inspection Times
                    </Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.RENTAL_APPLICATION}>Rental Application</Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.WHEN_AVAILABLE}>When Available</Select.Option>
                </Select>
            );
            break;

        case propertyStatus === 'OFFMARKET':
            defaultEnquiries = [PropertyEnquiryEnquiries.SALE_RENT];
            selectOptions = (
                <Select size="large" mode="multiple" style={{ width: '100%' }}>
                    <Select.Option key={PropertyEnquiryEnquiries.SALE_RENT}>
                        Is this property for sale/rent?
                    </Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.PRICE_GUIDE}>Price Guide</Select.Option>
                    <Select.Option disabled={!showInspections} key={PropertyEnquiryEnquiries.OFI}>
                        Inspection Times
                    </Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.PROPERTY_SIZE}>Property Size</Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.CONTRACT_SALE}>Contract of Sale</Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.SIMILAR_PROPERTIES}>Similar Properties</Select.Option>
                </Select>
            );
            break;
        default:
            defaultEnquiries = showInspections
                ? [PropertyEnquiryEnquiries.PRICE_GUIDE, PropertyEnquiryEnquiries.OFI]
                : [PropertyEnquiryEnquiries.PRICE_GUIDE];
            selectOptions = (
                <Select size="large" mode="multiple" style={{ width: '100%' }}>
                    <Select.Option key={PropertyEnquiryEnquiries.PRICE_GUIDE}>Price Guide</Select.Option>
                    <Select.Option disabled={!showInspections} key={PropertyEnquiryEnquiries.OFI}>
                        Inspection Times
                    </Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.PROPERTY_SIZE}>Property Size</Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.CONTRACT_SALE}>Contract of Sale</Select.Option>
                    <Select.Option key={PropertyEnquiryEnquiries.SIMILAR_PROPERTIES}>Similar Properties</Select.Option>
                </Select>
            );
            break;
    }

    return (
        <Form
            onFinish={handleSubmit}
            onFinishFailed={handleSubmitFailed}
            className="PropertyEnquiryForm pb-4"
            initialValues={{
                agentId: id,
                enquiries: defaultEnquiries,
                message: 'Hi, Please send me more information about the property.',
                isGetBroker: true,
            }}
            form={form}
        >
            <Row justify="space-between" align="top" className="w-full">
                <span className="property-enquiry-label mb-1 inline-block text-sm font-medium">Name</span>
                <Form.Item
                    className="w-full"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Your name is required',
                        },
                    ]}
                >
                    <Input className="w-full" placeholder="John Smith" size="large" />
                </Form.Item>
                <div className="flex w-full flex-wrap">
                    <div className="w-full pr-1 md:w-1/2 lg:w-full xl:w-1/2">
                        <span className="property-enquiry-label mb-1 inline-block text-sm font-medium">Email</span>
                        <Form.Item
                            className="w-full"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email',
                                },
                                {
                                    required: true,
                                    message: 'Your email is required',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="example@domain.com" />
                        </Form.Item>
                    </div>
                    <div className="w-full pl-1 md:w-1/2 lg:w-full xl:w-1/2">
                        <span className="property-enquiry-label mb-1 inline-block text-sm font-medium">
                            Phone <span className="hidden text-gray-600">(optional)</span>
                        </span>
                        <Form.Item className="w-full" name="contactNumber">
                            <Input placeholder="0491 570 156" size="large" />
                        </Form.Item>
                    </div>
                </div>
                <span className="property-enquiry-label mb-1 inline-block text-sm font-medium">Enquiry Types</span>
                <Form.Item className="w-full" name="enquiries">
                    {selectOptions}
                </Form.Item>
                <span className="property-enquiry-label mb-1 inline-block text-sm font-medium">Message</span>
                <Form.Item className="w-full" name="message">
                    <Input.TextArea rows={5} className="h-24" />
                </Form.Item>
                <Form.Item name="isGetBroker" valuePropName="checked">
                    <Checkbox>Would you like help to find the best loan at the lowest rate?</Checkbox>
                </Form.Item>
            </Row>
            <Form.Item className="mb-0">
                <Button
                    htmlType="submit"
                    size="large"
                    className="text-overflow  w-full rounded font-medium text-white"
                    loading={isLoading}
                    style={{ ...buttonStyle }}
                >
                    Send My Enquiry
                </Button>
            </Form.Item>
        </Form>
    );
};

type Props = {
    agent: any;
    buttonStyle: any;
    onClose: () => void;
    bannerColor: string;
    property: any;
    visible: boolean;
};

const AgentPropertyEnquiryModal: React.FC<Props> = (props) => {
    const { agent, onClose = () => {}, visible = false, buttonStyle, property, bannerColor = '' } = props;

    const { id, slugUrl, objects, address, features, status, mainObject } = property || {};
    const [firstImage] = objects || [];
    const { url: propertyImage } = mainObject ?? firstImage ?? {};

    const lowerCaseStatus = `${status}`.toLowerCase();

    const showInspections = lowerCaseStatus === 'current';

    return (
        <Modal
            centered
            footer={null}
            onCancel={onClose}
            visible={visible}
            title={
                <div className="flex ">
                    <div style={{ width: 150 }}>
                        <img
                            src={propertyImage ? `${propertyImage}?w=150` : null}
                            alt={address}
                            className="cover-img rounded bg-gray-300"
                        />
                    </div>
                    <div className="px-3">
                        <div className="text-sm font-semibold text-gray-700">
                            <PropertyAddress
                                property={property}
                                className="inline-block text-left capitalize"
                                newLine
                                query={{ isFromSearch: false, slug: slugUrl }}
                            />
                        </div>
                        <MainFeatures features={features} classNames={{ feature: 'mr-4 text-gray-600' }} />
                        <div className="flex items-center pt-2">
                            <AgentAvatar
                                agentSize={40}
                                agentProfilePicture={
                                    agent.profilePicture ? `${agent.profilePicture}?w=100&auto=format` : null
                                }
                                initials={agent.initials}
                                agentAlt={`${agent.firstName || ''} ${agent.lastName || ''}`}
                                classNameForProfilePicture="bg-blue-200 border border-gray-400 w-16 h-16"
                                classNameForInitials="bg-blue-200 border-1 border-gray-400 w-16 h-16 text-2xl"
                                agentStyle={{
                                    borderColor: bannerColor || '#2F3940',
                                    background: bannerColor || '#2F3940',
                                    lineHeight: '40px',
                                }}
                            />
                            <span className="px-2 text-xs font-normal text-gray-700">
                                {agent.firstName} {agent.lastName}
                            </span>
                        </div>
                    </div>
                </div>
            }
        >
            <PropertyEnquiryForm
                propertyId={id}
                agent={agent}
                buttonStyle={buttonStyle}
                showInspections={showInspections}
                propertyStatus={property?.status}
                propertyType={property?.type}
            />
            <div className="block text-center">
                {agent.whatsapp && <WhatsappButton contactNo={agent.whatsapp} propertyTitle={address} />}
                <a className="ml-4 text-primary-500 hover:underline" href={`tel:${agent.contactNo}`}>
                    or give {agent.firstName} a call{' '}
                </a>
            </div>
            <PersonalCollectionStatement className="mt-4" />
            <div className="mt-3 text-right">
                <Link href={`/boost/${slugUrl}`}>
                    <a target="_blank" className="text-primary-500 hover:underline">
                        Promote this property
                    </a>
                </Link>
            </div>
        </Modal>
    );
};

export default AgentPropertyEnquiryModal;
