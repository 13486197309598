/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { PhoneOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import AgentEnquiryForm from '~/components/AgentEnquiryForm';
import './AgentEnquiryModal.module.scss';

const AgentEnquiryModal = ({ agent, agency, onClose, bannerColor, visible }) => {
    return (
        <Modal
            centered
            footer={null}
            onCancel={onClose}
            visible={visible}
            className="AgentEnquiryModal"
            title={
                <span className="">
                    <PhoneOutlined className="mr-2" />
                    Send&nbsp;
                    {agent.firstName}
                    &nbsp;a Message
                </span>
            }
        >
            <AgentEnquiryForm agentId={agent.id} bannerColor={bannerColor} onSuccess={onClose} agency={agency} />
        </Modal>
    );
};

AgentEnquiryModal.propTypes = {
    agent: PropTypes.object,
    agency: PropTypes.object,
    onClose: PropTypes.func,
    bannerColor: PropTypes.string,
    visible: PropTypes.bool,
};

AgentEnquiryModal.defaultProps = {
    agent: {},
    agency: {},
    onClose: () => {},
    bannerColor: '',
    visible: false,
};

export default AgentEnquiryModal;
