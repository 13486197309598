import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NumberSlider from '../../NumberSlider';
import './ModalNumSlider.module.scss';

const ModalNumSlider = forwardRef(({ name, ...rest }, ref) => {
    return (
        <div className="ModalNumberSlider" ref={ref}>
            <div className="text-sm">{name}</div>
            <div className="flex justify-center block">
                <NumberSlider {...rest} />
            </div>
        </div>
    );
});

ModalNumSlider.propTypes = {
    value: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

ModalNumSlider.defaultProps = { name: null, onChange: () => {} };

export default ModalNumSlider;
