import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import './NumberSlider.module.scss';

const NumberSlider = ({ value, onChange, max, min, steps }) => {
    const getMarks = () => {
        const marks = {};
        let i = min;
        while (i <= max) {
            marks[i] = i === max ? `${i}+` : `${i}`;
            i += steps;
        }
        return marks;
    };

    const getValue = val => {
        if ((val[0] === min && val[1] === max) || (val[0] === 0 && val[1] === 0)) {
            return { min: undefined, max: undefined };
        }

        if ((val[0] === max && val[1] === max) || val[0] === val[1]) return { min: val[0], max: undefined };

        if (val[0] === 0 && val[1] > 2) {
            if (value.min > 0) {
                return { min: val[0], max: val[1] };
            }
            return { min: val[1], max: undefined };
        }

        return {
            min: val[0] === min ? undefined : val[0],
            max: val[1] === max ? undefined : val[1],
        };
    };

    const handleChange = val => {
        onChange(getValue(val));
    };

    const marks = getMarks();
    return (
        <div className="NumberSlider bg-white w-64 h-10">
            <Slider
                range
                marks={marks}
                max={max}
                value={[value.min || min, value.max || max]}
                defaultValue={[value.min || min, value.max || max]}
                onChange={val => handleChange(val)}
            />
        </div>
    );
};

NumberSlider.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    steps: PropTypes.number,
};

NumberSlider.defaultProps = {
    onChange: () => {},
    min: 0,
    max: 5,
    steps: 1,
};

export default NumberSlider;
