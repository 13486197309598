import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Alert, Button, Divider } from 'antd';
import { QuestionCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { roundNearestGroup } from '~/utils/number';
import LoadingDots from '~/components/LoadingDots';
import PropertySortingSelect from '~/components/PropertySortingSelect';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import PropertySearchResultPaginated from '~/components/PropertySearchResultPaginated';
import PropertySearchFeaturedAgentCard from '~/components/PropertySearchFeaturedAgentCard';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';
import RealtyDiscoverBanner from '~/components/RealtyDiscoverBanner';
import emptySearch from './search.png';
import styles from './PropertySearchResultListMobile.module.scss';

const PropertySearchResultListMobile = memo(
    ({
        searchOptions,
        propertySearch,
        featuredAgentData,
        featuredBrokerData,
        buildQueryVariables,
        onChangePagination,
        onChangeOfi,
        loading,
        isFromSearch,
        onChangeSorting,
        totalCount,
        hideCloseButton,
        hidePropertiesList,
        propertySearchVariables,
    }) => {
        const [isScrolled, setIsScrolled] = useState(false);
        const [loadingMore, setLoadingMore] = useState(false);

        const { featuredAgentByLocation, randomAgentByLocation, previewFeaturedAgentByLocation } =
            featuredAgentData || {};
        const { featuredBrokerByLocation } = featuredBrokerData || {};
        const { acceleratePreviewAgentId, ref, sorting, group } = searchOptions || {};

        const showLoadingMore = propertySearch?.pageInfo?.hasNextPage ?? false;
        let dataSource = propertySearch?.edges ?? [];

        const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;
        const hasPreviewFeaturedAgent = previewFeaturedAgentByLocation?.agent ?? false;
        const hasFeaturedAgent = featuredAgentByLocation?.agent ?? false;
        const hasRandomAgent = randomAgentByLocation?.agent ?? false;

        useEffect(() => {
            onChangeOfi(null);
        }, []);

        return (
            <div className={styles.propertySearchResultListMobile}>
                <div className="my-3 flex justify-between px-4">
                    {loading && <LoadingDots className="text-left" />}
                    {!loading && (
                        <div className="text-left text-sm text-gray-800">
                            {`${totalCount >= 10 ? `${roundNearestGroup(totalCount)}+` : totalCount} Properties
                                        `}
                        </div>
                    )}
                    <PropertySortingSelect
                        value={sorting || 'FEATURED'}
                        className={styles.SortResultBy}
                        style={{}}
                        onChange={onChangeSorting}
                        type={group}
                    />
                </div>
                <div className="advance-search-result w-full px-5 sm:pb-2 md:px-8">
                    {hasFeaturedAgent && (
                        <div
                            className={`sticky1 featured-agent-container z-30 bg-white pt-5 ${
                                isScrolled ? 'scrolled' : ''
                            }`}
                        >
                            <div
                                className={`${
                                    hasPreviewFeaturedAgent
                                        ? 'rounded-lg border-4 border-dashed border-red-400 p-1 text-right'
                                        : ''
                                }`}
                            >
                                {hasPreviewFeaturedAgent && (
                                    <Alert
                                        message={`Sorry, ${featuredAgentByLocation.location.suburb} is already taken!, Search for another suburb above`}
                                        type="error"
                                        showIcon
                                    />
                                )}
                                <PropertySearchFeaturedAgentCard
                                    agent={featuredAgentByLocation.agent}
                                    location={featuredAgentByLocation.location}
                                    agency={
                                        featuredAgentByLocation.agent && featuredAgentByLocation.agent.agency
                                            ? featuredAgentByLocation.agent.agency
                                            : null
                                    }
                                    mini={isScrolled}
                                    reviews={featuredAgentByLocation.agent.reviewRating}
                                    propertiesConnection={featuredAgentByLocation.agent.propertiesConnection}
                                />
                            </div>
                        </div>
                    )}

                    {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                        <div className="sticky1 featured-agent-container z-30 bg-white pt-5">
                            <PropertySearchFeaturedAgentCard
                                agent={randomAgentByLocation.agent}
                                location={randomAgentByLocation.location}
                                agency={
                                    randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                        ? randomAgentByLocation.agent.agency
                                        : null
                                }
                                mini={isScrolled}
                                reviews={randomAgentByLocation.agent.reviewRating}
                                propertiesConnection={randomAgentByLocation.agent.propertiesConnection}
                            />
                        </div>
                    )}

                    {!hasFeaturedAgent && hasRandomAgent && hasPreviewFeaturedAgent && (
                        <div className="sticky1 z-30 bg-white px-2 pt-5">
                            <div className="rounded-lg border-4 border-dashed border-red-400 p-1 text-right">
                                <div className="absolute -mt-4 ml-6 bg-white px-2 font-bold text-red-400">
                                    PREVIEW ONLY
                                </div>
                                <div className="flex items-center justify-between">
                                    <Link href="/accelerate" as="/accelerate">
                                        <a target="__blank" className="ml-4 w-1/2 text-blue-600 hover:text-blue-800">
                                            <QuestionCircleOutlined className="mr-2" />
                                            Learn More
                                        </a>
                                    </Link>

                                    <Button
                                        type="primary"
                                        icon={<ShoppingCartOutlined />}
                                        className="m-1 mt-0 rounded border-green-500 bg-green-500 hover:border-green-400 hover:bg-green-400"
                                    >
                                        <Link
                                            href={{
                                                pathname: `/accelerate/buy/[agentId]`,
                                                query: {
                                                    agentId: acceleratePreviewAgentId,
                                                    locationId: previewFeaturedAgentByLocation.location.id,
                                                    ref: ref || '',
                                                },
                                            }}
                                        >
                                            <a target="__blank" className="text-white hover:text-white">
                                                Buy Now
                                            </a>
                                        </Link>
                                    </Button>
                                </div>
                                <PropertySearchFeaturedAgentCard
                                    agent={previewFeaturedAgentByLocation.agent}
                                    location={previewFeaturedAgentByLocation.location}
                                    agency={
                                        previewFeaturedAgentByLocation.agent &&
                                        previewFeaturedAgentByLocation.agent.agency
                                            ? previewFeaturedAgentByLocation.agent.agency
                                            : null
                                    }
                                    reviews={previewFeaturedAgentByLocation.agent.reviewRating}
                                    propertiesConnection={previewFeaturedAgentByLocation.agent.propertiesConnection}
                                    showBuyButton
                                />
                            </div>
                        </div>
                    )}

                    <Divider />
                    <ScreenWidthValueProvider xs={1} sm={2} md={3} lg={2} xl={2} xxl={3}>
                        {(subMatch) => (
                            <PropertySearchResultPaginated
                                maxItemsPerRow={subMatch}
                                resultList={dataSource}
                                searchOptions={searchOptions}
                                searchFilters={buildQueryVariables(searchOptions)}
                                pageSize={24}
                                // loadMore={handleLoadMore}
                                loading={loading}
                                showLoadingMore={showLoadingMore}
                                isLoadingMore={loadingMore}
                                isFromSearch={isFromSearch}
                                totalCount={totalCount}
                                onChangePagination={onChangePagination}
                                featuredBroker={hasFeaturedBroker ? featuredBrokerByLocation : null}
                                showBrokerOnMobile={subMatch === 1}
                                propertySearchVariables={propertySearchVariables}
                                hidePropertiesList={hidePropertiesList}
                            />
                        )}
                    </ScreenWidthValueProvider>

                    {!loading && dataSource.length === 0 && (
                        <div
                            className="relative mt-2 rounded bg-white p-6 text-center"
                            style={{ height: 'calc(100vh - 208px)' }}
                        >
                            <div className="center-align">
                                <img src={emptySearch} alt="empty search results" className="w-5/6" />
                                <h2 className="mt-2 font-extralight text-gray-500">
                                    <div className="ml-6">
                                        <p className="my-4 text-xl">Sorry, no property results found</p>
                                    </div>
                                </h2>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-8 flex flex-col items-center px-4">
                    {hasFeaturedBroker && (
                        <div className="mb-8 w-full">
                            <PropertySearchBrokerCard
                                firstName={featuredBrokerByLocation.broker.firstName}
                                lastName={featuredBrokerByLocation.broker.lastName}
                                suburbName={featuredBrokerByLocation.location.suburb}
                                profilePicture={featuredBrokerByLocation.broker.profilePicture}
                                brokerId={featuredBrokerByLocation.broker.id}
                                logoUrl={featuredBrokerByLocation.broker.profileLogoUrl}
                                headerPadding="px-4 py-5"
                                slugUrl={featuredBrokerByLocation.broker.slugUrl}
                                logoShape={featuredBrokerByLocation.broker.logoShape}
                                hideCloseButton={hideCloseButton}
                            />
                        </div>
                    )}
                    <RealtyDiscoverBanner isMobile />
                </div>
            </div>
        );
    }
);

PropertySearchResultListMobile.propTypes = {
    loading: PropTypes.bool,
    onChangeOfi: PropTypes.func,
    propertySearch: PropTypes.object,
    featuredAgentData: PropTypes.object,
    featuredBrokerData: PropTypes.object,
    onChangeSorting: PropTypes.func,
    buildQueryVariables: PropTypes.func,
    onChangePagination: PropTypes.func,
    isFromSearch: PropTypes.bool,
    totalCount: PropTypes.number,
    searchOptions: PropTypes.shape({
        sorting: PropTypes.string,
        group: PropTypes.string,
    }),
    hideCloseButton: PropTypes.string,
};

PropertySearchResultListMobile.defaultProps = {
    searchOptions: {},
    propertySearch: {},
    featuredAgentData: {},
    featuredBrokerData: {},
    buildQueryVariables: {},
    onChangeOfi: () => {},
    onChangeSorting: () => {},
    onChangePagination: () => {},
    isFromSearch: false,
    loading: false,
    totalCount: 0,
    hideCloseButton: 'hidden',
};

export default PropertySearchResultListMobile;
