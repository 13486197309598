import React, { Fragment, memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserAgentContext from '~/context/user-agent-context';

/**
 * useMatchMedia
 *
 * usage:
 *   const matches = useMatchMedia("(min-width: 900px)")
 *   matches will be true or false
 *
 *
 * the format of the string is important, eg, needs ()'s
 * see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 *
 * https://github.com/gatsbyjs/gatsby/issues/14601
 * https://reactjs.org/docs/react-dom.html#hydrate
 *
 * @param  {String} media : media query to match
 * @return {Boolean} true if it matches, false if it doesn't
 */
export const useMatchMedia = media => {
    const [matches, setMatches] = useState(false);

    // define mediaQueryList inside effect because of server rendering/hydration
    // we need to render again when the client loads
    useEffect(() => {
        const mediaQueryList = window.matchMedia(media);
        const handleMatchChange = event => setMatches(event.matches);

        setMatches(mediaQueryList.matches);
        mediaQueryList.addListener(handleMatchChange);

        return () => {
            mediaQueryList.removeListener(handleMatchChange);
        };
    }, [media]);

    return matches;
};

export const ScreenMedia = memo(({ query, children }) => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? 'client' : 'server';
    const isMatched = useMatchMedia(query);

    useEffect(() => {
        setClient(true);
    }, []);

    return <Fragment key={key}>{children(isMatched)}</Fragment>;
});

ScreenMedia.propTypes = { query: PropTypes.string };
ScreenMedia.defaultProps = { query: '' };

const ScreenWidthValueProvider = props => {
    const [isClient, setClient] = useState(false);
    const { isTablet, isMobile, isDesktop } = useContext(UserAgentContext) || {};
    const key = isClient ? 'client' : 'server';

    useEffect(() => {
        setClient(true);
    }, []);

    let isXxl = useMatchMedia('(min-width: 1600px)');
    let isXl = useMatchMedia('(min-width: 1200px)');
    let isLg = useMatchMedia('(min-width: 992px)');
    let isMd = useMatchMedia('(min-width: 768px)');
    let isSm = useMatchMedia('(min-width: 576px)');
    let isXs = true;

    if (typeof window === 'undefined') {
        isXxl = isDesktop;
        isXl = isDesktop;
        isLg = isTablet;
        isMd = isMobile;
        isSm = isMobile;
        isXs = isMobile;
    }

    let result = 0;
    const { xs, sm, md, lg, xl, xxl, children } = props;

    if (isXxl) {
        result = xxl;
    } else if (isXl) {
        result = xl;
    } else if (isLg) {
        result = lg;
    } else if (isMd) {
        result = md;
    } else if (isSm) {
        result = sm;
    } else if (isXs) {
        result = xs;
    }
    return <Fragment key={key}>{children(result)}</Fragment>;
};

ScreenWidthValueProvider.propTypes = {
    xs: PropTypes.oneOfType([PropTypes.any]),
    sm: PropTypes.oneOfType([PropTypes.any]),
    md: PropTypes.oneOfType([PropTypes.any]),
    lg: PropTypes.oneOfType([PropTypes.any]),
    xl: PropTypes.oneOfType([PropTypes.any]),
    xxl: PropTypes.oneOfType([PropTypes.any]),
};
ScreenWidthValueProvider.defaultProps = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
    xxl: 0,
};

export default ScreenWidthValueProvider;
