import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '~/components/Skeleton';

const PropertyCardSkeleton = ({ noAnimation }) => (
    <div className="overflow-hidden shadow sm:rounded-md">
        <Skeleton className="mb-2" height="12rem" noAnimation={noAnimation} />
        <div className="-mt-16 px-4 py-6">
            <div className="text-right">
                <Skeleton
                    height="4rem"
                    width="4rem"
                    className="mr-4 inline-block rounded-full border-4 border-white"
                    noAnimation={noAnimation}
                />
            </div>
            <Skeleton className="-mt-4" width="75%" noAnimation={noAnimation} />
            <div className="mt-2 flex">
                <Skeleton className="mr-2" width="2.5rem" noAnimation={noAnimation} />
                <Skeleton className="mr-2" width="2.5rem" noAnimation={noAnimation} />
                <Skeleton className="mr-2" width="2.5rem" noAnimation={noAnimation} />
            </div>
        </div>
    </div>
);

PropertyCardSkeleton.propTypes = { noAnimation: PropTypes.bool };

PropertyCardSkeleton.defaultProps = { noAnimation: false };

export default PropertyCardSkeleton;
