export const getMarkersBounds = (markers = []) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
        bounds.extend({
            lat: marker.lat,
            lng: marker.lng,
        });
    });
    return bounds;
};

export const getDefaultBounds = () =>
    new window.google.maps.LatLngBounds(
        {
            lat: -44.550952930391375,
            lng: 98.89024100000006,
        },
        {
            lat: -2.141299423080466,
            lng: 169.20274100000006,
        }
    );
