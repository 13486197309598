/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';
import AgentAvatar from '~/components/AgentAvatar';
import AgentEnquiryModal from '~/components/AgentEnquiryModal';
import ImageCarousel from '~/components/ImageCarousel';
import ImageCard from '~/components/ImageCard';
import { trackEvent } from '~/lib/analytics';
import { formatToUnits } from '~/utils/number';
import PRIMARY from '~/constants/colors';
import { getBlackOrWhiteTextColorBasedOnBgColor } from '~/utils/colors';

import styles from './PropertySearchFeaturedAgentCard.module.scss';

const PropertySearchFeaturedAgentCard = ({
    agent,
    agency,
    location,
    // reviewRating,
    disableModal,
    propertiesConnection,
    mini,
}) => {
    const [isShowEnquiryModal, setIsShowEnquiryModal] = useState(false);

    const handleClose = () => {
        setIsShowEnquiryModal(false);
    };

    const showEnquiryModal = () => {
        const { id: agentId } = agent || {};

        if (disableModal === false) {
            trackEvent('Attempted Agent Enquiry', {
                dimension_1: parseInt(agentId, 10),
                dimension_2: parseInt(agency.id, 10),
            });
            setIsShowEnquiryModal(true);
        }
    };

    const { firstName, lastName, profilePicture, slugUrl, initials, sellLine, statistics } = agent || {};

    const { medianSoldPrice, listingCount, soldCount } = statistics || {};

    const { name, bannerColor, logoUrl, miniLogoUrl, slugUrl: agencySlugUrl } = agency || {};
    const { suburb } = location || {};
    const { nodes: agentProperties = [] } = propertiesConnection || {};
    // const { numberOfReviews, averageReview } = reviewRating || {};
    const isNotWhiteColor = bannerColor && bannerColor !== '#ffffff';
    const cardStyle = {
        background: (isNotWhiteColor && bannerColor) || PRIMARY,
        // color: (isNotWhiteColor && getColorShade(bannerColor)) || PRIMARY ? '#ffffff' : '#3D4852',
        color: getBlackOrWhiteTextColorBasedOnBgColor(agency?.bannerColor ?? '#000') ?? '#000',
        borderColor: (isNotWhiteColor && bannerColor) || PRIMARY,
        // maxWidth: '100%',
        height: '38px',
    };
    const images = agentProperties.reduce((acc, property) => {
        const { objects } = property || {};
        if (objects.length > 0) {
            acc.push(objects[0].url);
        }

        return acc;
    }, []);

    const isMini = mini || !(images.length > 0);

    return (
        <>
            <AgentEnquiryModal
                agent={agent}
                agency={agency}
                onClose={handleClose}
                visible={isShowEnquiryModal}
                bannerColor={bannerColor}
            />
            <div
                className={clsx(
                    `${styles.PropertySearchFeaturedAgentCard} overflow-hidden rounded-md bg-[#edf2f7] shadow-md`,
                    {
                        mini: isMini,
                    }
                )}
            >
                <div className="flex h-8 w-full justify-between text-sm sm:rounded-t" style={{ ...cardStyle }}>
                    <div className="flex w-1/2 items-center justify-center lg:flex-1">
                        <div className="truncate px-2 ">
                            <span className="px-1 text-xs">
                                {sellLine || (
                                    <>
                                        {firstName} {lastName} is your local {suburb} Agent
                                    </>
                                )}{' '}
                            </span>
                        </div>
                    </div>
                    <div className="inline-block h-full rounded p-1 lg:max-w-max">
                        <Link href={`/agency/${agencySlugUrl}`}>
                            <a>
                                <img
                                    src={miniLogoUrl || logoUrl}
                                    alt={name}
                                    className="h-full cursor-pointer"
                                    style={{ objectFit: 'contain' }}
                                />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="flex ">
                    <div className="flex w-full items-center justify-center py-2 sm:hidden ">
                        <Link href={`/agent/${slugUrl}`}>
                            <a>
                                <AgentAvatar
                                    agentAlt={`${firstName} ${lastName}`}
                                    initials={initials}
                                    agentSize={70}
                                    agentProfilePicture={profilePicture ? `${profilePicture}?w=100` : null}
                                    classNameForProfilePicture=" bg-gray-200"
                                    classNameForInitials=" bg-gray-200 sm:text-4xl text-3xl agent-profile-picture-search"
                                />
                            </a>
                        </Link>
                        <div className="flex flex-col justify-center pl-6">
                            <div className="text-xl font-semibold">
                                {firstName} {lastName}
                            </div>
                            <div className=""> {name}</div>
                            <Button
                                type="primary"
                                style={{ ...cardStyle }}
                                onClick={showEnquiryModal}
                                className="text-overflow  mt-1"
                            >
                                Contact &nbsp;
                                {firstName}
                            </Button>
                        </div>
                    </div>
                    <div className="hidden w-full items-center justify-between py-2 sm:flex md:flex lg:hidden">
                        <div className="flex w-full items-center justify-center">
                            <Link href={`/agent/${slugUrl}`}>
                                <a>
                                    <AgentAvatar
                                        agentAlt={`${firstName} ${lastName}`}
                                        initials={initials}
                                        agentSize={70}
                                        agentProfilePicture={profilePicture ? `${profilePicture}?w=100` : null}
                                        // style={{ paddingLeft: '1rem' }}
                                        classNameForProfilePicture=" bg-gray-200"
                                        classNameForInitials="bg-gray-200 sm:text-4xl text-3xl agent-profile-picture-search"
                                    />
                                </a>
                            </Link>
                            <div className="flex flex-col justify-center pl-6">
                                <div className="text-xl font-semibold">
                                    {firstName} {lastName}
                                </div>
                                <div className=""> {name}</div>
                                <Button
                                    type="primary"
                                    style={{ ...cardStyle }}
                                    onClick={showEnquiryModal}
                                    className="text-overflow "
                                >
                                    Contact &nbsp;
                                    {firstName}
                                </Button>
                            </div>
                        </div>
                        {soldCount > 0 ? (
                            <div className="flex w-full justify-around">
                                <div className="mt-3  w-1/3 sm:border-r sm:border-gray-300">
                                    <p className="m-0 text-base font-normal text-gray-800 sm:text-lg">{soldCount}</p>
                                    <p className="break-all text-xs font-normal uppercase text-gray-600">
                                        <span className="mb-2 border-b border-gray-200 pb-2 sm:mb-0 sm:border-b-0 sm:pb-0">
                                            Sales
                                        </span>
                                    </p>
                                </div>
                                <div className="ml-2 mt-3 w-1/3 sm:border-r sm:border-gray-300">
                                    <p className="m-0 text-base font-normal text-gray-800 sm:text-lg">
                                        <span className="text-sm" style={{ marginRight: 2 }}>
                                            <Tooltip title="Australian Dollar">$</Tooltip>
                                        </span>
                                        {formatToUnits(medianSoldPrice, 1)}
                                    </p>
                                    <p className="break-all text-xs font-normal uppercase text-gray-500">
                                        <span className="mb-2 border-b border-gray-500 pb-2 sm:mb-0 sm:border-b-0 sm:pb-0">
                                            Median
                                        </span>
                                    </p>
                                </div>
                                <div className="ml-2 mt-3 w-1/3">
                                    <p className="m-0 text-base font-normal text-gray-800 sm:text-lg">{listingCount}</p>
                                    <p className="break-all text-xs font-normal uppercase text-gray-500">Listings</p>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full text-center sm:border-r sm:border-gray-200">
                                Contact me for free appraisal
                            </div>
                        )}
                    </div>
                    {isMini ? (
                        <div className="hidden w-full items-center justify-between py-2 sm:hidden md:hidden lg:flex ">
                            <div className="flex w-full items-center justify-center">
                                <Link href={`/agent/${slugUrl}`}>
                                    <a>
                                        <AgentAvatar
                                            agentAlt={`${firstName} ${lastName}`}
                                            initials={initials}
                                            agentSize={70}
                                            agentProfilePicture={profilePicture ? `${profilePicture}?w=100` : null}
                                            classNameForProfilePicture=" bg-gray-200"
                                            classNameForInitials=" bg-gray-200 sm:text-4xl text-3xl agent-profile-picture-search"
                                        />
                                    </a>
                                </Link>
                                <div className="flex flex-col justify-center pl-6">
                                    <div className="text-xl font-semibold">
                                        {firstName} {lastName}
                                    </div>
                                    <div className=""> {name}</div>
                                    <Button
                                        type="primary"
                                        style={{ ...cardStyle }}
                                        onClick={showEnquiryModal}
                                        className="text-overflow "
                                    >
                                        Contact &nbsp;
                                        {firstName}
                                    </Button>
                                </div>
                            </div>
                            {soldCount > 0 ? (
                                <div className="flex w-full justify-around">
                                    <div className="w-1/3 sm:border-r sm:border-gray-200">
                                        <p className="text-base font-normal text-gray-800 sm:text-lg ">{soldCount}</p>
                                        <p className="break-all text-xs font-normal uppercase text-gray-600">
                                            <span className="mb-2 border-b border-gray-200 pb-2 sm:mb-0 sm:border-b-0 sm:pb-0">
                                                Sales
                                            </span>
                                        </p>
                                    </div>
                                    <div className="w-1/3 sm:border-r sm:border-gray-200">
                                        <p className="text-base font-normal text-gray-800 sm:text-lg">
                                            <span className="text-sm" style={{ marginRight: 2 }}>
                                                <Tooltip title="Australian Dollar">$</Tooltip>
                                            </span>
                                            {formatToUnits(medianSoldPrice, 1)}
                                        </p>
                                        <p className="break-all text-xs font-normal uppercase text-gray-500">
                                            <span className="mb-2 border-b border-gray-200 pb-2 sm:mb-0 sm:border-b-0 sm:pb-0">
                                                Median
                                            </span>
                                        </p>
                                    </div>
                                    <div className="w-1/3">
                                        <p className="text-base font-normal text-gray-800 sm:text-lg ">
                                            {listingCount}
                                        </p>
                                        <p className="break-all text-xs font-normal uppercase text-gray-500">
                                            Listings
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full text-center sm:border-r sm:border-gray-200">
                                    Contact me for free appraisal
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="hidden w-full justify-between sm:hidden md:hidden lg:flex   ">
                            <div className="flex w-3/5 items-center justify-center p-2">
                                <Link href={`/agent/${slugUrl}`}>
                                    <a className="pl-2">
                                        <AgentAvatar
                                            agentAlt={`${firstName} ${lastName}`}
                                            initials={initials}
                                            agentSize={80}
                                            agentProfilePicture={profilePicture ? `${profilePicture}?w=80` : null}
                                            classNameForProfilePicture=" bg-gray-200 my-8 mx-2"
                                            classNameForInitials="bg-gray-200 sm:text-4xl text-3xl agent-profile-picture-search"
                                        />
                                    </a>
                                </Link>
                                <div className="flex h-full w-full max-w-xs flex-col items-center justify-around ">
                                    <div>
                                        <div className="text-xl font-semibold">
                                            {firstName} {lastName}
                                        </div>
                                        <div className=""> {name}</div>
                                    </div>

                                    {soldCount > 0 ? (
                                        <div className="flex w-full justify-around py-2 ">
                                            <div className=" px-1 text-center sm:border-r sm:border-gray-200">
                                                <p className="text-base font-normal text-gray-800 sm:text-lg ">
                                                    {soldCount}
                                                </p>
                                                <p className="break-all text-xs font-normal uppercase text-gray-500">
                                                    <span className="mb-2 border-b border-gray-200 pb-2 sm:mb-0 sm:border-b-0 sm:pb-0">
                                                        Sales
                                                    </span>
                                                </p>
                                            </div>
                                            <div className=" px-1 text-center sm:border-r sm:border-gray-200">
                                                <p className="text-base font-normal text-gray-800 sm:text-lg">
                                                    <span className="text-sm" style={{ marginRight: 2 }}>
                                                        <Tooltip title="Australian Dollar">$</Tooltip>
                                                    </span>
                                                    {formatToUnits(medianSoldPrice, 1)}
                                                </p>
                                                <p className="break-all text-xs font-normal uppercase text-gray-500">
                                                    <span className="mb-2 border-b border-gray-200 pb-2 sm:mb-0 sm:border-b-0 sm:pb-0">
                                                        Median
                                                    </span>
                                                </p>
                                            </div>
                                            <div className=" px-1 text-center">
                                                <p className="text-base font-normal text-gray-800 sm:text-lg ">
                                                    {listingCount}
                                                </p>
                                                <p className="break-all text-xs font-normal uppercase text-gray-500">
                                                    Listings
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-full text-center sm:border-r sm:border-gray-200">
                                            Contact me for free appraisal
                                        </div>
                                    )}
                                    <Button
                                        type="primary"
                                        style={{ ...cardStyle }}
                                        onClick={showEnquiryModal}
                                        className="text-overflow  "
                                    >
                                        <span className="px-1 text-xs">
                                            Contact &nbsp;
                                            {firstName}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className="h-full w-2/5">
                                <ImageCarousel
                                    autoPlay={10000}
                                    animationSpeed={2000}
                                    infinite
                                    dots
                                    arrows={false}
                                    ratio="50%"
                                    className="property-carousel h-full"
                                >
                                    {images.map((url) => (
                                        <ImageCard
                                            key={url}
                                            alt="Realty.com.au"
                                            dataSizes="auto"
                                            className="h-full"
                                            src={`${url}?fit=crop&crop=entropy&auto=format`}
                                            dataSrcset={`${url}?w=300&auto=format 300w, ${url}?w=400&auto=format 400w, ${url}?w=500&auto=format 500w, ${url}?w=600&auto=format 600w,${url}?w=700&auto=format 700w, ${url}?w=1000&auto=format 1000w `}
                                            ratio="50%"
                                        />
                                    ))}
                                </ImageCarousel>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

PropertySearchFeaturedAgentCard.propTypes = {
    agent: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profilePicture: PropTypes.string,
        slugUrl: PropTypes.string,
        statistics: PropTypes.shape({
            id: PropTypes.string,
            listingCount: PropTypes.number,
            soldCount: PropTypes.number,
            medianSoldPrice: PropTypes.number,
        }),
    }),
    agency: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        bannerColor: PropTypes.string,
        agencyLogoUrl: PropTypes.string,
    }),
    location: PropTypes.shape({
        id: PropTypes.string,
        suburb: PropTypes.string,
    }),
    reviewRating: PropTypes.shape({
        numberOfReviews: PropTypes.number,
        averageReview: PropTypes.number,
    }),
    disableModal: PropTypes.bool,
    propertiesConnection: PropTypes.shape({ nodes: PropTypes.array }),
    mini: PropTypes.bool,
};

PropertySearchFeaturedAgentCard.defaultProps = {
    agent: {},
    agency: {},
    reviewRating: {},
    location: {},
    disableModal: false,
    propertiesConnection: {},
    mini: false,
};

export default PropertySearchFeaturedAgentCard;
