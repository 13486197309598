import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import styles from './ParkingNumberSelect.module.scss';

const { Option } = Select;

const ParkingNumberSelect = (props) => {
    const { onChange } = props;

    const parkingCounts = [1, 2, 3, 4, 5];

    return (
        <div className={`${styles.cutomSelect} w-full`}>
            <Select onChange={onChange} size="default" placeholder="Any" className="w-full">
                {parkingCounts.map((data) => (
                    <Option key={data} value={data} className="text-sm">
                        {data}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

ParkingNumberSelect.propTypes = { onChange: PropTypes.func };

ParkingNumberSelect.defaultProps = { onChange: () => {} };

export default ParkingNumberSelect;
