import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import PopoverBedFilterRangeFormItem from '~/components/PopoverBedFilterRangeFormItem';

const PopoverBed = forwardRef(({ min, max, onChange, value, format, suffix }, ref) => {
    return (
        <Popover
            placement="bottom"
            trigger="click"
            content={
                <PopoverBedFilterRangeFormItem
                    miniInfo={false}
                    onChange={onChange}
                    onRef={ref}
                    min={min}
                    max={max}
                    value={value}
                    suffix={suffix}
                />
            }
        >
            Bed: Any <DownOutlined className="pl-2 text-gray-400" />
        </Popover>
    );
});

PopoverBed.propTypes = {
    type: PropTypes.string,
};

PopoverBed.defaultProps = {
    type: null,
};

export default PopoverBed;
