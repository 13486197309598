import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { LoadingOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import isEmpty from 'lodash.isempty';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { faListAlt, faMapMarkedAlt, faBuilding, faSlidersH } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import { Media, MediaContextProvider } from '~/Media';
import { winHeight, winWidth } from '~/utils/windowDimensions';

import LoadingDots from '~/components/LoadingDots';
import PropertySortingSelect from '~/components/PropertySortingSelect';
import PropertySearchResultMap from '~/components/PropertySearchResultMap';
import PublicHeaderBreadcrumbs from '~/components/PublicHeaderBreadcrumbs';
import PropertyInspectionMobile from '~/components/PropertyInspectionMobile';
import PropertySearchResultList from '~/components/PropertySearchResultList';
import SearchFilterDrawerMobile from '~/components/SearchFilterDrawerMobile';
import PropertySearchResultMapMobile from '~/components/PropertySearchResultMapMobile';
import CreateAlertDrawerForSearchPage from '~/components/CreateAlertDrawerForSearchPage';
import PropertySearchResultInspection from '~/components/PropertySearchResultInspection';
import PropertySearchResultListMobile from '~/components/PropertySearchResultListMobile';

import { roundNearestGroup } from '~/utils/number';

import {
    PropertySearchFeaturedBrokerQuery,
    PropertySearchQuery,
    PropertySearchFeaturedAgentQuery,
    HidePropertiesListQuery,
} from './Operations.graphql';

import styles from './PropertySearchResults.module.scss';

const LoadingValue = memo(({ loading, value }) => (loading ? <LoadingOutlined /> : value));

LoadingValue.propTypes = {
    loading: PropTypes.bool,
    value: PropTypes.number,
};

LoadingValue.defaultProps = {
    loading: false,
    value: 0,
};

const LoadingResultWithTotal = memo(({ loading, value, totalCount }) => {
    return (
        <div className="text-center text-sm text-gray-600 ">
            Showing{' '}
            <span className="font-semibold">
                {' '}
                <LoadingValue loading={loading} value={value} />
            </span>{' '}
            of
            <span className="font-semibold">
                {' '}
                <LoadingValue loading={loading} value={totalCount} />
            </span>
        </div>
    );
});

LoadingResultWithTotal.propTypes = {
    loading: PropTypes.bool,
    value: PropTypes.number,
    totalCount: PropTypes.number,
};

LoadingResultWithTotal.defaultProps = {
    loading: false,
    value: 0,
    totalCount: 0,
};

const LoadingResult = memo((props) => {
    const { loading, value } = props;

    return (
        <div className="text-center text-sm text-gray-600">
            Showing{' '}
            <span className="font-semibold">
                {' '}
                <LoadingValue loading={loading} value={value} />
            </span>{' '}
            of Total Results
        </div>
    );
});

LoadingResult.propTypes = {
    loading: PropTypes.bool,
    value: PropTypes.number,
};

LoadingResult.defaultProps = {
    loading: false,
    value: 0,
};

const agentTypeByGroup = {
    buy: 'AGENT',
    rent: 'PM',
};

const buildQueryVariables = (searchOptions) => {
    const {
        keywords,
        price,
        parking,
        landArea,
        bed,
        mapLocation,
        bathroom,
        ofiTime,
        group,
        locationsData,
        isIncludeSurrounding,
        isIncludeOffMarket,
        sorting,
        category,
        features,
        page,
        pageSize,
        consumerId,
        isNewConstruction,
    } = searchOptions;

    return {
        datesFrom: ofiTime,
        options: {
            page,
            pageSize: pageSize || 24,
            bathroom,
            bed,
            garage: parking,
            group,
            isIncludeSurrounding,
            isIncludeOffMarket,
            keyword: keywords,
            landArea,
            location: locationsData?.map(({ id }) => id) ?? [],
            mapLocation,
            ofiTime,
            price,
            category,
            features,
            sorting: sorting ? `${sorting}`.toUpperCase() : null,
            consumerId,
            isNewConstruction,
        },
    };
};

const PropertySearchResults = (props) => {
    const {
        searchOptions,
        changeSearchUrl,
        onChangePagination,
        onChangeOfi,
        onChangeSorting,
        isFromSearch,
        viewerConsumerId,
    } = props;
    const { showMap, group, ofiTime, sorting, acceleratePreviewAgentId, ref, locations, locationsData, pageSize } =
        searchOptions || {};

    searchOptions.consumerId = viewerConsumerId;

    const { data, loading } = useQuery(PropertySearchQuery, {
        ssr: false,
        variables: buildQueryVariables(searchOptions),
    });

    const { data: hidePropertiesData, loading: hideLoading } = useQuery(HidePropertiesListQuery, {
        errorPolicy: 'ignore',
        notifyOnNetworkStatusChange: true,
        variables: { consumerId: viewerConsumerId },
    });

    const locationIds = locationsData?.map(({ id }) => id) ?? [];

    const { data: featuredBrokerData } = useQuery(PropertySearchFeaturedBrokerQuery, {
        ssr: false,
        skip: !(locationIds && locationIds.length > 0),
        variables: { locationIds },
    });

    const startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    const endDate = dayjs().format('YYYY-MM-DD');

    const { data: featuredAgentData } = useQuery(PropertySearchFeaturedAgentQuery, {
        ssr: false,
        skip: !(locationIds && locationIds.length > 0),
        variables: {
            filter: { first: 3 },
            agentId: acceleratePreviewAgentId,
            type: agentTypeByGroup[`${group}`.toLowerCase()] || 'DUAL',
            locationIds,
            randomOptions: {
                locationIds,
                type: agentTypeByGroup[`${group}`.toLowerCase()] || 'DUAL',
            },
            startDate,
            endDate,
        },
    });

    // const [loadingMore, setLoadingMore] = useState(false);
    const [visible, setVisible] = useState(false);
    // const [isScrolled, setIsScrolled] = useState(false);
    const [offsetHeaderHeight, setOffsetHeaderHeight] = useState(0);
    //
    const [inspectionStatus, setInspectionStatus] = useState(false);
    const [searchDrawerVisible, setSearchDrawerVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('inspection');
    //
    const [classWidth, setClassWidth] = useState('lg:w-3/5 lg:px-8 px-2');

    const resizeListener = () => {
        const advancedSearchHeight = !isEmpty(document.getElementsByClassName('search-filter'))
            ? document.getElementsByClassName('search-filter')[0].clientHeight
            : 60;
        const menuHeight = !isEmpty(document.getElementsByClassName('header-dark'))
            ? document.getElementsByClassName('header-dark')[0].clientHeight
            : 56;

        const isLandscape = winWidth > winHeight;
        const offsetHeaderHeightValue =
            winWidth < 576 || (isLandscape && winWidth < 769)
                ? advancedSearchHeight
                : advancedSearchHeight + menuHeight;

        setOffsetHeaderHeight(offsetHeaderHeightValue);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        window.addEventListener('resize', resizeListener);
        resizeListener();

        return function cleanup() {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    useEffect(() => {
        const currentTabKey = searchOptions?.ofiTime ?? null;
        setActiveKey(currentTabKey ? 'inspection' : 'list');
    }, [searchOptions.ofiTime]);

    const onTabChange = (key) => {
        key === 'map' ? setClassWidth('lg:w-1/2 md:px-8 lg:px-4 px-2') : setClassWidth('lg:w-3/5 md:px-8 lg:px-6 px-2');
        onChangeOfi(key === 'inspection' ? dayjs().day(6).format('YYYY-MM-DD') : null);
        setActiveKey(key);
    };

    const onTabMobileChange = (key) => {
        switch (key) {
            case 'inspection':
                setInspectionStatus(true);
                setActiveKey('inspection');
                break;
            case 'filters':
                setSearchDrawerVisible(true);
                break;
            default:
                setActiveKey(key);
                break;
        }
    };

    const onCloseDatePicker = () => {
        setInspectionStatus(false);
    };

    const onSubmitChangePropertyOfiSearchOptions = (date) => {
        onChangeOfi(date || null);
        setInspectionStatus(false);
    };

    const handleDrawer = (bool) => {
        setVisible(bool);
    };

    const handleSearchDrawerVisible = () => {
        setSearchDrawerVisible(!searchDrawerVisible);
    };

    const handleSubmitSearchDrawer = (data) => {
        setSearchDrawerVisible(false);
        changeSearchUrl({ ...data }, searchOptions);
    };

    let pathName;
    let locationParam;
    let locationsSuburb;
    let locationsState;
    let locationsPostalCode;
    const lowerCaseStatus = `${group}`.toLowerCase();

    if (searchOptions.locationsData.length === 1) {
        const { locationsData } = searchOptions;
        locationsSuburb = locationsData[0].suburb;
        locationsState = locationsData[0].state;
        locationsPostalCode = locationsData[0].postalCode;
        pathName = `${locationsSuburb}, ${locationsState} ${locationsPostalCode}`;
        locationParam = new URLSearchParams({ location: pathName }).toString();
    }

    const routes = [
        {
            href: '/',
            as: '/',
            breadcrumbName: 'Home',
        },
        {
            as: `/${lowerCaseStatus || ''}`,
            href: `/${lowerCaseStatus || ''}`,
            breadcrumbName: capitalize(lowerCaseStatus),
        },
        {
            as: `/${lowerCaseStatus || ''}/search?${locationParam}`,
            href: `/${lowerCaseStatus || ''}/search?${locationParam}`,
            breadcrumbName: `${locationsState || ''}`.toUpperCase(),
        },
        {
            href: '',
            as: '',
            breadcrumbName: `${locationsSuburb}`,
        },
    ];

    let propertyStatusText = '';
    switch (routes[1].href) {
        case '/buy':
            propertyStatusText = 'for sale';
            break;

        case '/rent':
            propertyStatusText = 'for rent';
            break;

        case '/sold':
            propertyStatusText = 'sold';
            break;

        default:
            propertyStatusText = '';
            break;
    }

    const { propertySearch } = data || {};

    const { hidePropertiesList } = hidePropertiesData || {};

    // const showLoadingMore = propertySearch?.pageInfo?.hasNextPage ?? false;
    let dataSource = propertySearch?.edges ?? [];
    if (loading) dataSource = [];

    const markers = dataSource.filter(({ node: { lat } }) => lat !== null).map(({ node }) => node);
    const totalCount = propertySearch?.totalCount ?? 0;

    return (
        <>
            <CreateAlertDrawerForSearchPage
                visible={visible}
                onClose={() => handleDrawer(false)}
                searchOptions={searchOptions}
            />
            <SearchFilterDrawerMobile
                searchDrawerVisible={searchDrawerVisible}
                onClose={handleSearchDrawerVisible}
                onOk={handleSubmitSearchDrawer}
                type={group}
                searchOptions={searchOptions}
                onTabMobileChange={onTabMobileChange}
            />

            {/* <ScreenWidthValueProvider xs sm md>
                {isMd =>
                    isMd ? ( */}
            <MediaContextProvider>
                <Media lessThan="lg">
                    <div className="w-full">
                        <Tabs activeKey={activeKey} onTabClick={onTabMobileChange} centered>
                            <Tabs.TabPane
                                tab={
                                    <div className="mx-2 flex flex-col items-center py-2">
                                        <FA icon={faListAlt} style={{ fontSize: '1rem', lineHeight: '1.5rem' }} />
                                        <span className="text-sm">List</span>
                                    </div>
                                }
                                key="list"
                            >
                                <PropertySearchResultListMobile
                                    propertySearch={propertySearch}
                                    featuredAgentData={featuredAgentData}
                                    featuredBrokerData={featuredBrokerData}
                                    searchOptions={searchOptions}
                                    buildQueryVariables={buildQueryVariables}
                                    onChangePagination={onChangePagination}
                                    onChangeOfi={onChangeOfi}
                                    loading={loading}
                                    onChangeSorting={onChangeSorting}
                                    totalCount={totalCount}
                                    propertySearchVariables={buildQueryVariables(searchOptions)}
                                    hidePropertiesList={hidePropertiesList}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <div className="mx-2 flex flex-col items-center py-2">
                                        <FA icon={faMapMarkedAlt} style={{ fontSize: '1rem', lineHeight: '1.5rem' }} />
                                        <span className="text-sm">Map</span>
                                    </div>
                                }
                                key="map"
                            >
                                <PropertySearchResultMapMobile
                                    propertySearch={propertySearch}
                                    featuredAgentData={featuredAgentData}
                                    featuredBrokerData={featuredBrokerData}
                                    searchOptions={searchOptions}
                                    buildQueryVariables={buildQueryVariables}
                                    onChangeOfi={onChangeOfi}
                                    loading={loading}
                                    onChangePagination={onChangePagination}
                                    totalCount={totalCount}
                                    markers={markers}
                                    pageSize={pageSize || 24}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <div className="mx-2 flex flex-col items-center py-2">
                                        <FA icon={faBuilding} style={{ fontSize: '1rem', lineHeight: '1.5rem' }} />
                                        <span className="text-sm">OFI</span>
                                    </div>
                                }
                                key="inspection"
                            >
                                <PropertyInspectionMobile
                                    inspectionStatus={inspectionStatus}
                                    propertySearch={propertySearch}
                                    featuredAgentData={featuredAgentData}
                                    featuredBrokerData={featuredBrokerData}
                                    searchOptions={searchOptions}
                                    buildQueryVariables={buildQueryVariables}
                                    onChangeOfi={onChangeOfi}
                                    loading={loading}
                                    onChangePagination={onChangePagination}
                                    totalCount={totalCount}
                                    onSubmitChangePropertyOfiSearchOptions={onSubmitChangePropertyOfiSearchOptions}
                                    onCloseDatePicker={onCloseDatePicker}
                                    propertySearchVariables={buildQueryVariables(searchOptions)}
                                    hidePropertiesList={hidePropertiesList}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <div className="mx-2 flex flex-col items-center py-2">
                                        <FA icon={faSlidersH} style={{ fontSize: '1rem', lineHeight: '1.5rem' }} />
                                        <span className="text-sm">Filters</span>
                                    </div>
                                }
                                key="filters"
                            />
                        </Tabs>
                    </div>
                </Media>
                <Media greaterThanOrEqual="lg">
                    <div className={`${styles.advanceSearchResult} ${classWidth} sm:px-5 sm:pb-2`}>
                        {locationParam && <PublicHeaderBreadcrumbs className="mt-8 px-0" arrayOfLinks={routes} />}
                        {loading && <LoadingDots />}
                        {!loading && (
                            <div className="my-8 px-0 text-left text-xl text-gray-800 md:text-2xl lg:text-3xl xl:px-6">
                                {totalCount >= 10 ? `${roundNearestGroup(totalCount)}+` : totalCount}
                                {locations
                                    ? ` Properties ${propertyStatusText} in ${locations[0]} ${
                                          locations.length > 1 ? `and other locations` : ``
                                      }`
                                    : ` Properties ${propertyStatusText}`}
                            </div>
                        )}
                        <Tabs
                            tabBarExtraContent={
                                <PropertySortingSelect
                                    value={sorting || 'FEATURED'}
                                    className="SortResultBy"
                                    onChange={onChangeSorting}
                                    type={group}
                                />
                            }
                            activeKey={activeKey}
                            onTabClick={onTabChange}
                            className="md:px-0 xl:px-6"
                        >
                            <Tabs.TabPane
                                tab={
                                    <>
                                        <FA icon={faListAlt} className="mr-2 text-base" />
                                        <span className="mr-2 text-base">List</span>
                                    </>
                                }
                                key="list"
                            >
                                <PropertySearchResultList
                                    propertySearch={propertySearch}
                                    featuredAgentData={featuredAgentData}
                                    featuredBrokerData={featuredBrokerData}
                                    searchOptions={searchOptions}
                                    buildQueryVariables={buildQueryVariables}
                                    onChangePagination={onChangePagination}
                                    onChangeOfi={onChangeOfi}
                                    loading={loading}
                                    isFromSearch={isFromSearch}
                                    propertySearchVariables={buildQueryVariables(searchOptions)}
                                    hidePropertiesList={hidePropertiesList}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <>
                                        <FA icon={faMapMarkedAlt} className="mr-2 text-base" />
                                        <span className="mr-2 text-base">Map</span>
                                    </>
                                }
                                key="map"
                            >
                                <PropertySearchResultMap
                                    loading={loading}
                                    isFromSearch={isFromSearch}
                                    propertySearch={propertySearch}
                                    featuredAgentData={featuredAgentData}
                                    featuredBrokerData={featuredBrokerData}
                                    searchOptions={searchOptions}
                                    onChangePagination={onChangePagination}
                                    propertySearchVariables={buildQueryVariables(searchOptions)}
                                    hidePropertiesList={hidePropertiesList}
                                    markers={markers}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <>
                                        <FA icon={faBuilding} className="mr-2 text-base" />
                                        <span className="text-base">Inspection</span>
                                    </>
                                }
                                key="inspection"
                            >
                                <PropertySearchResultInspection
                                    propertySearch={propertySearch}
                                    featuredAgentData={featuredAgentData}
                                    featuredBrokerData={featuredBrokerData}
                                    searchOptions={searchOptions}
                                    buildQueryVariables={buildQueryVariables}
                                    onChangeOfi={onChangeOfi}
                                    loading={loading}
                                    onChangePagination={onChangePagination}
                                    totalCount={totalCount}
                                    propertySearchVariables={buildQueryVariables(searchOptions)}
                                    hidePropertiesList={hidePropertiesList}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </Media>
            </MediaContextProvider>
        </>
    );
};

PropertySearchResults.propTypes = {
    searchOptions: PropTypes.shape({
        keyword: PropTypes.string,
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        bed: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        price: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        parking: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        landArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        floorArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        bathroom: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        mapLocation: PropTypes.objectOf({
            radius: PropTypes.number,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        location: PropTypes.string,
        locationId: PropTypes.string,
        locationsData: PropTypes.any,
        includeSurrounding: PropTypes.bool,
        sorting: PropTypes.string,
        isIncludeSurrounding: PropTypes.bool,
        isIncludeOffMarket: PropTypes.bool,
        keywords: PropTypes.array,
        group: PropTypes.string,
        isNewConstruction: PropTypes.bool,
    }),
    onChangeOfi: PropTypes.func,
    onChangeSorting: PropTypes.func,
    onChangePagination: PropTypes.func,
    isFromSearch: PropTypes.bool,
};

PropertySearchResults.defaultProps = {
    searchOptions: {},
    onChangeOfi: () => {},
    onChangeSorting: () => {},
    onChangePagination: () => {},
    isFromSearch: false,
};

export default PropertySearchResults;
