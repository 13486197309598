import { useMutation } from '@apollo/client';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input, Row, Alert } from 'antd';
import FacebookLoginButton from '~/components/FacebookLoginButton';

import {
    CreateConsumerTokenForExistingAccountUsingFB,
    CreateConsumerTokenForExistingAccountUsingGoogle,
    CreateConsumerTokenMutation,
} from '~/queries/CreateConsumerTokenMutation.graphql';
import GoogleLoginButton from '~/components/GoogleLoginButton';

const ConsumerAccountLoginWithSocial = memo(props => {
    const {
        form: { getFieldDecorator, validateFields },
        onHandleSubmit,
        loading,
        onClickRegister,
    } = props;

    const [formErrorCode, setFormErrorCode] = useState(null);
    const [createConsumerToken, { loading: creatingToken }] = useMutation(CreateConsumerTokenMutation);
    const [createConsumerTokenUsingFb] = useMutation(CreateConsumerTokenForExistingAccountUsingFB);
    const [createConsumerTokenUsingGoogle] = useMutation(CreateConsumerTokenForExistingAccountUsingGoogle);

    const getErrorMessage = () => {
        let message = null;

        switch (formErrorCode) {
            case 'CONSUMER_NOT_EXIST': {
                message = 'It seems that your email is not yet registered';
                break;
            }
            case 'PASSWORD_MISMATCH': {
                message = 'It seems that your password is incorrect';
                break;
            }
            case 'CONSUMER_NOT_ACTIVE': {
                message = 'It seems that the account you provided is inactive';
                break;
            }
            case 'CONSUMER_NOT_VERIFIED': {
                message = 'It seems that the account you provided is not verified';
                break;
            }
            default: {
                message = 'Unable to login';
                break;
            }
        }

        return message;
    };

    const handleSubmit = e => {
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                const { email, password } = values;
                createConsumerToken({
                    variables: {
                        username: email.trim(),
                        password,
                    },
                })
                    .then(({ data }) => {
                        const { createConsumerToken } = data || {};
                        onHandleSubmit(createConsumerToken);
                    })
                    .catch(error => {
                        const errors = error.graphQLErrors || [];
                        const formErrCode = errors.length > 0 ? errors[0].extensions.code : null;
                        setFormErrorCode(formErrCode);
                    });
            }
        });
    };

    const onSuccessLoginFb = data => {
        const { profile } = data || {};

        const { id: fbId } = profile || {};

        createConsumerTokenUsingFb({ variables: { fbId } })
            .then(({ data }) => {
                const { createConsumerTokenForExistingAccountUsingFB } = data || {};
                onHandleSubmit(createConsumerTokenForExistingAccountUsingFB);
            })
            .catch(() => {});
    };

    const onSuccessLoginGoogle = data => {
        const { profileObj } = data || {};
        const { googleId } = profileObj || {};

        createConsumerTokenUsingGoogle({ variables: { googleId } })
            .then(({ data }) => {
                const { createConsumerTokenForExistingAccountUsingGoogle } = data || {};
                onHandleSubmit(createConsumerTokenForExistingAccountUsingGoogle);
            })
            .catch(() => {});
    };
    return (
        <>
            <div className="flex justify-center pt-6 pb-4">
                <FacebookLoginButton className="mx-2" onSuccess={onSuccessLoginFb} />
                <GoogleLoginButton className="mx-2" onSuccess={onSuccessLoginGoogle} />
            </div>

            <div className="flex justify-center py-0">
                <div className="max-w-md w-full">
                    <Divider orientation="center">or</Divider>
                </div>
            </div>
            <div className="flex flex-col justify-between w-full items-center">
                <Form onSubmit={handleSubmit} className="max-w-xs w-full">
                    <Row justify="center" gutter={12}>
                        <Col sm={24}>
                            <p className="font-medium  mb-1 text-xs">Email</p>
                            <Form.Item className=" " hasFeedback>
                                {getFieldDecorator('email', {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Email is required!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Invalid email!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="example@domain.com"
                                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>

                        <Col sm={24}>
                            <p className="font-medium  mb-1 text-xs">Password</p>
                            <Form.Item className=" ">
                                {getFieldDecorator('password', {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Password is required!',
                                        },
                                    ],
                                })(
                                    <Input.Password
                                        placeholder="Your password"
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="py-3 w-full flex flex-col justify-center items-center pt-8">
                    <Button
                        onClick={handleSubmit}
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="max-w-xs"
                        block
                        loading={loading || creatingToken}
                    >
                        NEXT
                    </Button>
                    <div className="text-center text-gray-700 flex items-center pt-4">
                        No account yet?
                        <Button type="link" className="text-gray-700 " onClick={onClickRegister}>
                            <span className="underline font-medium">Register</span>
                        </Button>
                    </div>
                </div>
                <div className={`${formErrorCode ? 'h-16' : 'h-6'} py-2 transition`}>
                    {formErrorCode && <Alert message={getErrorMessage()} type="error" showIcon />}
                </div>
            </div>
        </>
    );
});

ConsumerAccountLoginWithSocial.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func,
        getFieldValue: PropTypes.func,
        validateFields: PropTypes.func,
    }),
    onHandleSubmit: PropTypes.func,
    onClickRegister: PropTypes.func,
    loading: PropTypes.bool,
};

ConsumerAccountLoginWithSocial.defaultProps = {
    form: {},
    onHandleSubmit: () => {},
    onClickRegister: () => {},
    loading: false,
};

export default Form.create()(ConsumerAccountLoginWithSocial);
