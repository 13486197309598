import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Row, Col, Divider } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { outDoorFeatures, inDoorFeatures, climateControlFeatures } from '~/constants/feature';

const PropertyFeaturesFilter = memo((props) => {
    const { onChange, value } = props;
    const [showMoreOutdoor, setShowMoreOutDoor] = useState(false);
    const [itemsToShowOutdoor, setItemsToShowOutDoor] = useState(4);
    const [showMoreIndoor, setShowMoreIndoor] = useState(false);
    const [itemsToShowIndoor, setItemsToShowIndoor] = useState(4);
    const [showMoreClimateControl, setShowMoreClimateControl] = useState(false);
    const [itemsToShowClimateControl, setItemsToShowClimateControl] = useState(4);

    const handleChange = (values) => {
        onChange(values);
    };

    const handleViewMoreOutDoor = (outDoorFeatures) => {
        itemsToShowOutdoor
            ? (setItemsToShowOutDoor(outDoorFeatures.length), setShowMoreOutDoor(true))
            : (setItemsToShowOutDoor(4), setShowMoreOutDoor(false));
    };

    const handleViewMoreInDoor = (inDoorFeatures) => {
        itemsToShowIndoor
            ? (setItemsToShowIndoor(inDoorFeatures.length), setShowMoreIndoor(true))
            : (setItemsToShowIndoor(4), setShowMoreIndoor(false));
    };

    const handleViewMoreClimateControl = (climateControlFeatures) => {
        itemsToShowClimateControl
            ? (setItemsToShowClimateControl(climateControlFeatures.length), setShowMoreClimateControl(true))
            : (setItemsToShowClimateControl(4), setShowMoreClimateControl(false));
    };

    return (
        <div>
            <Checkbox.Group onChange={handleChange} defaultValue={value} className={'flex w-full'}>
                <div className="mb-4 text-sm">Outdoor Features</div>
                <div className="grid grid-cols-2">
                    {outDoorFeatures.slice(0, itemsToShowOutdoor).map((outDoorFeature) => (
                        <div key={outDoorFeature.value} className="mb-1">
                            <Checkbox value={outDoorFeature.value} className="mr-6 text-xs">
                                <span className="text-xs">{outDoorFeature.label}</span>
                            </Checkbox>
                        </div>
                    ))}
                </div>

                <div className="block text-primary-500" onClick={handleViewMoreOutDoor}>
                    {showMoreOutdoor ? (
                        <span className="cursor-pointer">
                            View less <UpOutlined />
                        </span>
                    ) : (
                        <span className="cursor-pointer">
                            View more <DownOutlined />
                        </span>
                    )}
                </div>
                <Divider />
                <div className="mb-4 text-sm">Indoor Features</div>
                <div className="grid grid-cols-2">
                    {inDoorFeatures.slice(0, itemsToShowIndoor).map((inDoorFeature) => (
                        <div key={inDoorFeature.value} className="mb-1">
                            <Checkbox value={inDoorFeature.value} className="mr-12 text-xs">
                                <span className="text-xs"> {inDoorFeature.label}</span>
                            </Checkbox>
                        </div>
                    ))}
                </div>

                <div className="block text-primary-500" onClick={handleViewMoreInDoor}>
                    {showMoreIndoor ? (
                        <span className="cursor-pointer">
                            View less <UpOutlined />
                        </span>
                    ) : (
                        <span className="cursor-pointer">
                            View more <DownOutlined />
                        </span>
                    )}
                </div>
                <Divider />
                <div className="mb-4 text-sm">Climate Control &amp; Energy Features</div>
                <div className="grid grid-cols-2">
                    {climateControlFeatures.slice(0, itemsToShowClimateControl).map((climateControlFeature) => (
                        <div key={climateControlFeature.value} className="mb-1">
                            <Checkbox value={climateControlFeature.value}>
                                <span className="text-xs"> {climateControlFeature.label}</span>
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <div className="block text-primary-500" onClick={handleViewMoreClimateControl}>
                    {showMoreClimateControl ? (
                        <span className="cursor-pointer">
                            View less <UpOutlined />
                        </span>
                    ) : (
                        <span className="cursor-pointer">
                            View more <DownOutlined />
                        </span>
                    )}
                </div>
            </Checkbox.Group>
        </div>
    );
});

PropertyFeaturesFilter.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
};

PropertyFeaturesFilter.defaultProps = {
    value: [],
    onChange: () => {},
};

export default PropertyFeaturesFilter;
