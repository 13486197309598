import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Select } from 'antd';
import omit from 'lodash.omit';

import styles from './PropertyStatusSelect.module.scss';

const PropertyStatusSelect = ({ type }) => {
    const router = useRouter();
    const currentStatus = type ? type.toLowerCase() : 'buy';
    const { query, asPath } = router || {};
    const omittedQuery = omit(query, ['priceMin', 'priceMax']);
    const queryParam = new URLSearchParams({ omittedQuery }).toString();
    // const queryParam = qs.stringify(omittedQuery, { arrayFormat: 'repeat' });

    const onStatusChange = (value) => {
        router.push(`/${value}/search${queryParam ? `?${queryParam}` : ''}`);
    };

    return (
        <div className={`${styles.PropertyStatusSelect} flex items-center`}>
            <Select
                // className="w-6/12"
                dropdownClassName="property-status-dropdown"
                onChange={onStatusChange}
                value={currentStatus}
            >
                <Select.Option value="buy">Property for sale</Select.Option>
                <Select.Option value="rent">Property for rent</Select.Option>
                <Select.Option value="sold">Sold property</Select.Option>
            </Select>
        </div>
    );
};

PropertyStatusSelect.propTypes = { type: PropTypes.string };

PropertyStatusSelect.defaultProps = { type: null };

export default PropertyStatusSelect;
