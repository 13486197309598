import { useMutation } from '@apollo/client';
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormOutlined } from '@ant-design/icons';
import { Button, Radio, Col, message, Modal, Row, Drawer, Form } from 'antd';
import omit from 'lodash.omit';
import { connect } from 'react-redux';
import { loginUser } from '~/actions';
import AlertPropertySearch from '~/components/AlertPropertySearch';
import PriceFilterRangeFormItem from '~/components/PriceFilterRangeFormItem';
import FeatureFilterFormItem from '~/components/FeatureFilterFormItem';
import GuestLoginForm from '~/components/GuestLoginForm';
import { ConsumerAlertsConnection, CreateConsumerAlert } from './Operations.graphql';

const CreateAlertDrawerForSearchPage = ({ history, isAuthenticated, onClose, onLoginUser, visible, searchOptions }) => {
    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const [createConsumerAlert] = useMutation(CreateConsumerAlert);
    const [isLoginModal, setIsLoginModal] = useState(false);
    const [alertValues, setAlertValues] = useState(null);
    const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);
    const [formErrorCode, setFormErrorCode] = useState(null);

    const getErrorMessage = (collectionName) => {
        let errorMessage = null;

        switch (formErrorCode) {
            case 'COLLECTION_NAME_ALREADY_EXIST':
                errorMessage = `${collectionName} was already created, Try to create a new one.`;
                break;
            default:
                break;
        }
        return errorMessage;
    };

    const handleCreateNewAlert = (alertVal) => {
        createConsumerAlert({
            variables: { input: alertVal },
            refetchQueries: [
                {
                    query: ConsumerAlertsConnection,
                    variables: { filter: { first: 12 } },
                },
            ],
        })
            .then(() => {
                message.success('Alert successfully created.', 2);
                setIsLoadingSaveButton(false);
                if (!isAuthenticated) history.push({ pathname: '/alerts' });
            })
            .catch((error) => {
                const errors = error.graphQLErrors || [];
                const formErrorCodeValue = errors.length > 0 ? errors[0].extensions.code : null;
                setFormErrorCode(formErrorCodeValue);
                setIsLoadingSaveButton(false);
                message.warning(getErrorMessage(), 2);
            })
            .finally(() => {
                onClose(false);
            });
    };

    const closeLoginModal = () => {
        setIsLoginModal(false);
    };

    const onLoginSuccess = async (loginData) => {
        const { accessToken, expiresIn, refreshToken, user, type } = loginData;

        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });

        setIsLoadingSaveButton(true);
        await handleCreateNewAlert(alertValues);
        setIsLoadingSaveButton(false);
        closeLoginModal();
    };

    const showLoginModal = (bool, alertVal) => {
        setIsLoginModal(bool);
        setAlertValues(alertVal);
    };

    const handleSubmit = (values) => {
        setIsLoadingSaveButton(true);

        const { bathrooms, bedrooms, cars, group } = values;
        let transFormedValues = {
            bathrooms: bathrooms.min,
            bedrooms: bedrooms.min,
            cars: cars.min,
            group: [group],
            locations: values.locations.map(({ key }) => key),
            minPrice: values.price.min,
            maxPrice: values.price.max,
        };
        transFormedValues = omit(transFormedValues, 'price');
        if (isAuthenticated) {
            handleCreateNewAlert(transFormedValues);
        } else {
            showLoginModal(true, transFormedValues);
        }
        console.log('Received values of form: ', transFormedValues);
    };

    const { bathroom, bed, group, parking, price, keywords, locations } = searchOptions || {};

    return (
        <>
            <Drawer width={null} onClose={onClose} visible={visible}>
                <h1 className="py-4 text-lg text-gray-800 lg:text-2xl">
                    <FormOutlined />
                    &nbsp; Create an Alert
                </h1>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{
                        locations: [
                            ...(keywords
                                ? searchOptions.keywords.map((keyword) => ({
                                      key: keyword,
                                      label: keyword,
                                  }))
                                : []),
                            ...(locations
                                ? searchOptions.locations.map((keyword) => ({
                                      key: keyword,
                                      label: keyword,
                                  }))
                                : []),
                        ],
                        group,
                        price: {
                            min: price && price.min ? price.min : 0,
                            max: price && price.max ? price.max : 5000000,
                        },
                        bedrooms: { min: bed && bed.min ? bed.min : 1 },
                        bathrooms: { min: bathroom && bathroom.min ? bathroom.min : 1 },
                        cars: { min: parking && parking.min ? parking.min : 0 },
                    }}
                >
                    <div className="rounded-lg bg-white pt-6">
                        <p className="mb-4 text-lg font-medium">Location</p>
                        <Form.Item
                            className="mb-4"
                            name="locations"
                            rules={[
                                {
                                    required: true,
                                    message: 'Location is required!',
                                },
                            ]}
                        >
                            <AlertPropertySearch />
                        </Form.Item>
                        <p className="mb-4 mt-10 text-lg font-medium">I&apos;m looking for</p>
                        <Form.Item name="group">
                            <Radio.Group style={{ width: '100%' }}>
                                <Row>
                                    <Col span={6}>
                                        <Radio value="BUY">
                                            <span className="text-lg">SALE</span>
                                        </Radio>
                                    </Col>
                                    <Col span={6}>
                                        <Radio value="RENT">
                                            <span className="text-lg">RENT</span>
                                        </Radio>
                                    </Col>
                                    <Col span={8}>
                                        <Radio value="SOLD">
                                            <span className="text-lg">SOLD/LEASED</span>
                                        </Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="rounded-lg bg-white pb-6">
                        <div className="flex flex-col">
                            <p className="mb-2 text-lg font-medium">Price</p>
                            <Form.Item className="w-full self-center" name="price">
                                <PriceFilterRangeFormItem
                                    type={getFieldValue('group') || group}
                                    selectOptions={{ style: { width: 110 } }}
                                />
                            </Form.Item>
                        </div>
                        <div className="mt-4 flex flex-wrap justify-around lg:flex-row">
                            <div className="mr-6 inline-block">
                                <p className="mb-4 text-center text-lg font-medium">Bedrooms</p>
                                <Form.Item name="bedrooms">
                                    <FeatureFilterFormItem label="bed" />
                                </Form.Item>
                            </div>
                            <div className="mr-6 inline-block">
                                <p className="mb-4 text-center text-lg font-medium">Bathrooms</p>
                                <Form.Item name="bathrooms">
                                    <FeatureFilterFormItem label="bathrooms" />
                                </Form.Item>
                            </div>
                            <div className="inline-block">
                                <p className="mb-4 text-center text-lg font-medium">Parking</p>
                                <Form.Item name="cars">
                                    <FeatureFilterFormItem label="cars" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="py-2 text-center">
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large" loading={isLoadingSaveButton} block>
                                Create Alert
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Drawer>
            <Modal
                wrapClassName="vertical-center-modal"
                visible={isLoginModal}
                onOk={() => showLoginModal(false)}
                onCancel={() => showLoginModal(false)}
                footer={null}
                zIndex={1060}
            >
                <GuestLoginForm onSuccess={onLoginSuccess} onSuccessAccount={onLoginSuccess} />
            </Modal>
        </>
    );
};

CreateAlertDrawerForSearchPage.propTypes = {
    history: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    onClose: PropTypes.func,
    onLoginUser: PropTypes.func,
    visible: PropTypes.bool,
    searchOptions: PropTypes.object,
};

CreateAlertDrawerForSearchPage.defaultProps = {
    history: {},
    isAuthenticated: false,
    onClose: () => {},
    onLoginUser: () => {},
    visible: false,
    searchOptions: {},
};

export default connect(
    (state) => ({ isAuthenticated: state.auth.isAuthenticated && state.auth.type === 'CONSUMER' }),
    (dispatch) => ({
        onLoginUser(accessToken) {
            dispatch(loginUser(accessToken));
        },
    })
)(CreateAlertDrawerForSearchPage);
