import { useMutation } from '@apollo/client';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DollarOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, Modal, Row } from 'antd';
import { trackEvent } from '~/lib/analytics';
import { getColorShade } from '~/utils/colors';
import PRIMARY from '~/constants/colors';
import PersonalCollectionStatement from '~/components/PersonalCollectionStatement';
import { CreateBrokerEnquiryMutation } from './Operations.graphql';

const BrokerEnquiryForm = (props) => {
    const { onClose, propertyId, brokerId, bannerColor } = props;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [createBrokerEnquiry] = useMutation(CreateBrokerEnquiryMutation);

    const { resetFields } = form;

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = (values) => {
        setIsLoading(true);

        const transformedValues = {
            ...values,
            propertyId,
            brokerId,
            referrer: window.document.referrer || null,
        };

        createBrokerEnquiry({ variables: { input: transformedValues } })
            .then(() => {
                trackEvent('Submitted Broker Enquiry', { dimension_1: parseInt(brokerId, 10) });

                Modal.success({
                    title: 'Enquiry Successfully Sent',
                    onOk: () => {
                        resetFields();
                        handleCancel();
                    },
                });
            })
            .catch(() => {
                message.error('Encountered an Error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const isNotWhiteColor = bannerColor && bannerColor !== '#fff';

    const buttonStyle = {
        background: (isNotWhiteColor && bannerColor) || PRIMARY,
        color: (isNotWhiteColor && getColorShade(bannerColor)) || PRIMARY ? '#fff' : '#3D4852',
    };

    return (
        <Form
            onFinish={handleSubmit}
            form={form}
            initialValues={{ message: "Hi, I'm interested in securing finance on this property." }}
        >
            <Row justify="space-between" align="top" className="w-full">
                <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">My Name</span>
                <Form.Item
                    className="w-full"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Your name is required',
                        },
                    ]}
                >
                    <Input className="w-full" prefix={<UserOutlined className="text-blue-500" />} />
                </Form.Item>
                <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">Email</span>
                <Form.Item
                    className="w-full"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'Please enter a valid email',
                        },
                        {
                            required: true,
                            message: 'Your email is required',
                        },
                    ]}
                >
                    <Input prefix={<MailOutlined className="text-blue-500" />} />
                </Form.Item>
                <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">
                    Phone Number <span className="text-gray-600">(optional)</span>
                </span>
                <Form.Item className="w-full" name="contactNumber">
                    <Input prefix={<PhoneOutlined className="text-blue-500" />} />
                </Form.Item>
                <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">Listed Price</span>
                <Form.Item
                    className="w-full"
                    name="listedPrice"
                    rules={[
                        {
                            required: true,
                            message: 'Listed Price is required',
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix={<DollarOutlined className="text-blue-500" />}
                    />
                </Form.Item>
                <span className="mb-2 inline-block text-xs font-semibold uppercase text-gray-700">Your Message</span>
                <Form.Item className="w-full" name="message">
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Row>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                    style={{ ...buttonStyle }}
                    size="large"
                >
                    Enquire Now
                </Button>
            </Form.Item>
            <PersonalCollectionStatement className="text-left" />
        </Form>
    );
};

BrokerEnquiryForm.propTypes = {
    bannerColor: PropTypes.string,
    brokerId: PropTypes.string,
    onClose: PropTypes.func,
    propertyId: PropTypes.string,
};

BrokerEnquiryForm.defaultProps = {
    bannerColor: PRIMARY,
    brokerId: null,
    onClose: () => {},
    propertyId: null,
};

export default BrokerEnquiryForm;
