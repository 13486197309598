export const outDoorFeatures = [
    { label: 'Outdoor Entertainment', value: 'outdoor_ent' },
    { label: 'Pool Above Ground', value: 'pool_above_ground' },
    { label: 'Open Fire Place', value: 'open_fire_place' },
    { label: 'Pool in Ground', value: 'pool_in_ground' },
    { label: 'Fully Fenced', value: 'fully_fenced' },
    { label: 'Tennis Court', value: 'tennis_court' },
    { label: 'Open Spaces', value: 'open_spaces' },
    { label: 'Outside Spa', value: 'outside_spa' },
    { label: 'Courtyard', value: 'courtyard' },
    { label: 'Balcony', value: 'balcony' },
    { label: 'Deck', value: 'deck' },
    { label: 'Shed', value: 'shed' },
];

export const inDoorFeatures = [
    { label: 'Study', value: 'study' },
    { label: 'Dishwasher', value: 'dishwasher' },
    { label: 'Built In Robes', value: 'built_in_robes' },
    { label: 'Gym', value: 'gym' },
    { label: 'Workshop', value: 'workshop' },
    { label: 'Rumpus Room', value: 'rumpus_room' },
    { label: 'Inside Spa', value: 'inside_spa' },
];

export const climateControlFeatures = [
    { label: 'Ducted Heating', value: 'ducted_heating' },
    { label: 'Ducted Cooling', value: 'ducted_cooling' },
    { label: 'Split System Heating', value: 'splitsystem_heating' },
    { label: 'Hydronic Heating', value: 'hydronic_heating' },
    { label: 'Split System Aircon', value: 'splitsystem_aircon' },
    { label: 'Gas Heating', value: 'gas_heating' },
    { label: 'Reverse Cycle Aircon', value: 'reverse_cycle_aircon' },
    { label: 'Evaporative Cooling', value: 'evaporative_cooling' },
    { label: 'Air Conditioning', value: 'air_conditioning' },
    { label: 'Alarm System', value: 'alarm_system' },
    { label: 'Solar Panels', value: 'solar_panels' },
    { label: 'Solar Hot Water', value: 'solar_hot_water' },
];

export default outDoorFeatures;
