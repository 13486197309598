/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import Proptypes from 'prop-types';
import Link from 'next/link';
import { CloseOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import isEmpty from 'lodash.isempty';
import clsx from 'clsx';
import { trackEvent } from '~/lib/analytics';
import { getColorShade } from '~/utils/colors';
import BrokerEnquiryModal from '../BrokerEnquiryModal';
import PRIMARY from '~/constants/colors';
import './PropertySearchBrokerCard.module.scss';

const PropertySearchBrokerCard = ({
    brokerId,
    disableModal,
    firstName,
    lastName,
    suburbName,
    profilePicture,
    logoUrl,
    headerColor,
    slugUrl,
    logoShape,
    onClose,
    className,
    hideCloseButton,
    headerPadding,
}) => {
    const [isShowBrokerEnquiryModal, setIsShowBrokerEnquiryModal] = useState(false);

    const handleClose = () => {
        setIsShowBrokerEnquiryModal(false);
    };

    const showBrokerEnquiryModal = () => {
        if (disableModal === false) {
            trackEvent('Attempted Broker Enquiry', { dimension_1: parseInt(brokerId, 10) });
            setIsShowBrokerEnquiryModal(true);
        }
    };

    // const isNotWhiteColor = headerColor && headerColor !== '#fff';

    // const cardStyle = {
    //     background: (isNotWhiteColor && headerColor) || PRIMARY,
    //     color: isNotWhiteColor && getColorShade(headerColor) ? '#fff' : '#3D4852',
    //     borderColor: (isNotWhiteColor && headerColor) || PRIMARY,
    // };

    const cardStyleHeader = {
        backgroundColor: headerColor,
        borderColor: headerColor,
        fontSize: '1.2em',
    };

    return (
        <>
            <BrokerEnquiryModal
                firstName={firstName}
                brokerId={brokerId}
                onClose={handleClose}
                visible={isShowBrokerEnquiryModal}
                bannerColor={headerColor}
            />
            <div className="bg-white rounded overflow-hidden shadow-md">
                <div className="flex items-center justify-between relative" style={{ ...cardStyleHeader }}>
                    <div className={` w-full flex items-center text-primary-500 justify-center ${headerPadding}`}>
                        Do you need a loan?
                    </div>

                    <Tooltip title="Close" placement="right">
                        <CloseOutlined
                            className={`bg-white1 right-0 top-0 p-2 absolute text-white rounded-full text-xs ${hideCloseButton}`}
                            onClick={onClose}
                        />
                    </Tooltip>
                </div>

                <div className={clsx('px-3 py-5 xl:px-8', className)}>
                    <div className="flex justify-center items-center">
                        <Link href={`/mortgagebroker/${slugUrl}`}>
                            <a className="relative">
                                <Avatar
                                    alt="asd"
                                    size={80}
                                    icon={<UserOutlined />}
                                    src={profilePicture ? `${profilePicture}?w=100` : null}
                                    className="shadow bg-gray-200"
                                />
                                {logoShape === 'CIRCLE' && !isEmpty(logoUrl) ? (
                                    <Avatar
                                        alt="asd"
                                        size={40}
                                        icon={<ShopOutlined />}
                                        src={logoUrl ? `${logoUrl}?w=100` : null}
                                        className="shadow bg-gray-200 absolute bottom-0 right-0"
                                        style={{ right: '-10px' }}
                                    />
                                ) : null}

                                {logoShape === 'RECTANGLE' && !isEmpty(logoUrl) ? (
                                    <div
                                        className="shadow bg-gray-200 absolute bottom-0 right-0 rounded-sm"
                                        style={{
                                            right: '-20px',
                                            width: 40,
                                            height: 'auto',
                                        }}
                                    >
                                        <img
                                            alt={logoUrl}
                                            src={logoUrl ? `${logoUrl}?w=100` : null}
                                            className="rounded-sm"
                                        />
                                    </div>
                                ) : null}
                            </a>
                        </Link>
                    </div>

                    <div className="flex flex-col justify-around w-full">
                        <div className="text-center my-4">
                            Hi there, I&apos;m{' '}
                            <span className={'font-semibold text-primary-500'}>
                                {firstName} {lastName}
                            </span>{' '}
                            your {suburbName} Mortgage specialist.
                        </div>
                        <div className="flex justify-center pb-4">
                            <Button type="primary" onClick={showBrokerEnquiryModal} className=" text-overflow">
                                Send a message
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PropertySearchBrokerCard.propTypes = {
    firstName: Proptypes.string,
    lastName: Proptypes.string,
    suburbName: Proptypes.string,
    profilePicture: Proptypes.string,
    brokerId: Proptypes.string,
    logoUrl: Proptypes.string,
    slugUrl: Proptypes.string,
    headerColor: Proptypes.string,
    disableModal: Proptypes.bool,
    logoShape: Proptypes.string,
    className: Proptypes.string,
    hideCloseButton: Proptypes.string,
    onClose: Proptypes.func,
    headerPadding: Proptypes.string,
};

PropertySearchBrokerCard.defaultProps = {
    firstName: '',
    lastName: '',
    suburbName: '',
    profilePicture: '',
    brokerId: '',
    logoUrl: '',
    slugUrl: '',
    className: '',
    hideCloseButton: '',
    disableModal: false,
    headerColor: '#1A202D',
    logoShape: 'CIRCLE',
    onClose: () => {},
    headerPadding: 'px-4 py-5',
};

export default PropertySearchBrokerCard;
