import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import { roundNearestGroup } from '~/utils/number';
import PropertyMapWithMarkerClusterer from '~/components/PropertyMapWithMarkerClusterer';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';
import './PropertySearchResultMapMobile.module.scss';

const PropertySearchResultMapMobile = memo(
    ({
        searchOptions,
        featuredAgentData,
        featuredBrokerData,
        onChangePagination,
        isFromSearch,
        totalCount,
        markers,
        pageSize,
    }) => {
        const [isOpenBroker, setIsOpenBroker] = useState(true);

        const { featuredAgentByLocation } = featuredAgentData || {};
        const { featuredBrokerByLocation } = featuredBrokerData || {};
        const { page } = searchOptions || {};

        const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;

        const curPage = page || 1;

        const fromRange = (curPage - 1) * pageSize + 1;
        const toRange = (curPage - 1) * pageSize + markers.length;

        return (
            <>
                <div className="PropertySearchResultMapMobile relative">
                    <div className="advance-search-map-mobile w-full h-full">
                        <PropertyMapWithMarkerClusterer
                            featuredBrokerByLocation={featuredBrokerByLocation}
                            hasFeaturedBroker={hasFeaturedBroker}
                            featuredAgentId={featuredAgentByLocation?.agent?.id ?? null}
                            markers={markers}
                            isFromSearch={isFromSearch}
                        />

                        {hasFeaturedBroker && isOpenBroker === true && (
                            <div className="w-3/4 sm:w-1/2 absolute m-auto left-0 right-0 bottom-0 ">
                                <PropertySearchBrokerCard
                                    className="flex items-center"
                                    firstName={featuredBrokerByLocation.broker.firstName}
                                    lastName={featuredBrokerByLocation.broker.lastName}
                                    suburbName={featuredBrokerByLocation.location.suburb}
                                    profilePicture={featuredBrokerByLocation.broker.profilePicture}
                                    brokerId={featuredBrokerByLocation.broker.id}
                                    logoUrl={featuredBrokerByLocation.broker.profileLogoUrl}
                                    slugUrl={featuredBrokerByLocation.broker.slugUrl}
                                    logoShape={featuredBrokerByLocation.broker.logoShape}
                                    onClose={() => setIsOpenBroker(false)}
                                    headerPadding="px-3 py-2"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="pt-8">
                    {markers.length ? (
                        <>
                            <Pagination
                                className="text-center"
                                pageSize={pageSize}
                                current={page || 1}
                                defaultCurrent={1}
                                total={totalCount}
                                onChange={onChangePagination}
                                pageSizeOptions={[10, 24, 50, 100]}
                            />
                            <div className="text-sm text-center text-gray-600 pt-4">
                                {fromRange} - {toRange} of{' '}
                                {`${
                                    totalCount >= 10 && toRange !== totalCount
                                        ? `${roundNearestGroup(totalCount)}+`
                                        : totalCount
                                } properties`}
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        );
    }
);

PropertySearchResultMapMobile.propTypes = {
    featuredAgentData: PropTypes.object,
    featuredBrokerData: PropTypes.object,
    onChangePagination: PropTypes.func,
    isFromSearch: PropTypes.bool,
    totalCount: PropTypes.number,
    pageSize: PropTypes.number,
    markers: PropTypes.arrayOf(PropTypes.object),
    searchOptions: PropTypes.shape({
        sorting: PropTypes.string,
        group: PropTypes.string,
    }),
};

PropertySearchResultMapMobile.defaultProps = {
    searchOptions: {},
    featuredAgentData: {},
    featuredBrokerData: {},
    markers: {},
    onChangePagination: () => {},
    isFromSearch: false,
    totalCount: 0,
    pageSize: 0,
};

export default PropertySearchResultMapMobile;
