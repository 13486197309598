export enum PropertyEnquiryEnquiries {
    PRICE_GUIDE = 'PRICE_GUIDE',
    OFI = 'OFI',
    PROPERTY_SIZE = 'PROPERTY_SIZE',
    CONTRACT_SALE = 'CONTRACT_SALE',
    SIMILAR_PROPERTIES = 'SIMILAR_PROPERTIES',
    SALE_RENT = 'SALE_RENT',
    RENTAL_APPLICATION = 'RENTAL_APPLICATION',
    WHEN_AVAILABLE = 'WHEN_AVAILABLE',
    APPRAISE_PROPERTY = 'APPRAISE_PROPERTY',
}
