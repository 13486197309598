/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Row, Col } from 'antd';
import clsx from 'clsx';
import compose from 'lodash.flowright';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';

import './ConsumerOffMarketSubPayment.module.scss';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);

const ConsumerOffMarketSubPayment = (props) => {
    const { onHandleSubmit, loading, offMarketPlan } = props;

    const stripe = useStripe();
    const elements = useElements();

    const [errorCard, setErrorCard] = useState(false);
    const [isProcessLoading, setIsProcessLoading] = useState(false);

    const handleSignUp = async () => {
        if (stripe) {
            const cardElement = elements.getElement(CardNumberElement);

            const { token, error } = await stripe.createToken(cardElement);

            if (error) {
                throw new Error(`Error setting credit card information`);
            }

            setIsProcessLoading(true);
            setErrorCard(false);

            try {
                onHandleSubmit(token.id);
            } catch {
                (error) => {
                    console.log(error);
                    setErrorCard(true);
                };
            } finally {
                setIsProcessLoading(false);
            }
        } else {
            console.log("Stripe.js hasn't loaded yet.");
            setErrorCard(false);
        }
    };

    const subscriptionPrice = offMarketPlan === 'OFF_MARKET_PRO_ALERTS' ? '$199' : '$29';

    return (
        <div className="ConsumerOffMarketSubPayment w-full justify-center lg:justify-between">
            <h1 className=" pb-2 text-center text-2xl font-normal text-gray-700">Billing Info</h1>
            <p className=" text-center font-light text-gray-700  ">
                Your alert settings includes subscription to
                <br />
                Off-Market property listings for only <span className="font-medium">{subscriptionPrice} AUD</span> per
                month.
            </p>
            <div className="flex justify-center py-4">
                <div className="w-full max-w-md">
                    <Divider />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-between">
                <div className="w-full max-w-sm">
                    <Elements stripe={stripePromise}>
                        <WrappedStripeCheckoutForm
                            loading={loading || isProcessLoading}
                            errorCard={errorCard}
                            onSubmit={handleSignUp}
                        />
                    </Elements>
                </div>
            </div>
        </div>
    );
};

ConsumerOffMarketSubPayment.propTypes = {
    onHandleSubmit: PropTypes.func,
    loading: PropTypes.bool,
    offMarketPlan: PropTypes.string,
};

ConsumerOffMarketSubPayment.defaultProps = {
    onHandleSubmit: () => {},
    loading: false,
    offMarketPlan: null,
};

const StripeCheckoutForm = (props) => {
    const { errorCard, stripe, onSubmit, loading } = props;

    const [elementFocused, setElementFocused] = useState(null);

    const createOptions = () => ({
        onFocus: ({ elementType }) => setElementFocused(elementType),
        onBlur: () => setElementFocused(null),
        style: {
            base: {
                // fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                // fontFamily: "'Poppins', sans-serif",
                '::placeholder': { color: '#c7c7c7' },
            },
            invalid: { color: '#9e2146' },
        },
    });

    return (
        <>
            <div className="ConsumerOffMarketSubPaymentStripeCheckout">
                <div className="StripeCheckout">
                    {errorCard ? <div className="text-center text-sm text-red-500">Invalid Payment Details</div> : null}
                    <section className={clsx('element text-gray-600', elementFocused === 'cardNumber' && 'focused')}>
                        Card number
                        <CardNumberElement {...createOptions()} />
                    </section>
                    <Row justify="center" gutter={12}>
                        <Col span={12}>
                            <section
                                className={clsx('element text-gray-600', elementFocused === 'cardExpiry' && 'focused')}
                            >
                                Expiration date
                                <CardExpiryElement {...createOptions()} />
                            </section>
                        </Col>
                        <Col span={12}>
                            <section
                                className={clsx('element text-gray-600', elementFocused === 'cardCvc' && 'focused')}
                            >
                                CVC
                                <CardCvcElement {...createOptions()} />
                            </section>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="flex w-full justify-center py-3 pt-8">
                <Button
                    onClick={() => onSubmit(stripe)}
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className="max-w-xs"
                    block
                    loading={loading}
                >
                    SAVE
                </Button>
            </div>
        </>
    );
};

StripeCheckoutForm.propTypes = {
    errorCard: PropTypes.bool,
    success: PropTypes.bool,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
    stripe: PropTypes.object,
};

StripeCheckoutForm.defaultProps = {
    errorCard: false,
    success: false,
    loading: false,
    onSuccess: () => {},
    onSubmit: () => {},
    stripe: {},
};
const WrappedStripeCheckoutForm = compose(Form.create())(StripeCheckoutForm);

export default ConsumerOffMarketSubPayment;

// /* eslint-disable react/forbid-prop-types */
// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Button, Divider, Row, Col } from 'antd';
// import clsx from 'clsx';
// import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe } from 'react-stripe-elements';
// import './ConsumerOffMarketSubPayment.module.scss';

// const ConsumerOffMarketSubPayment = (props) => {
//     const { onHandleSubmit, loading, offMarketPlan } = props;

//     const [errorCard, setErrorCard] = useState(false);
//     const [isProcessLoading, setIsProcessLoading] = useState(false);

//     const handleSignUp = (stripe) => {
//         if (stripe) {
//             setIsProcessLoading(true);
//             setErrorCard(false);
//             stripe
//                 .createToken()
//                 .then((payload) => {
//                     const { token } = payload;
//                     onHandleSubmit(token.id);
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                     setErrorCard(true);
//                 })
//                 .finally(() => {
//                     setIsProcessLoading(false);
//                 });
//         } else {
//             console.log("Stripe.js hasn't loaded yet.");
//             setErrorCard(false);
//         }
//     };

//     const subscriptionPrice = offMarketPlan === 'OFF_MARKET_PRO_ALERTS' ? '$199' : '$29';

//     return (
//         <div className="ConsumerOffMarketSubPayment w-full justify-center lg:justify-between">
//             <h1 className=" pb-2 text-center text-2xl font-normal text-gray-700">Billing Info</h1>
//             <p className=" text-center font-light text-gray-700  ">
//                 Your alert settings includes subscription to
//                 <br />
//                 Off-Market property listings for only <span className="font-medium">{subscriptionPrice} AUD</span> per
//                 month.
//             </p>
//             <div className="flex justify-center py-4">
//                 <div className="w-full max-w-md">
//                     <Divider />
//                 </div>
//             </div>
//             <div className="flex w-full flex-col items-center justify-between">
//                 <div className="w-full max-w-sm">
//                     <Elements>
//                         <WrappedStripeCheckoutForm
//                             loading={loading || isProcessLoading}
//                             errorCard={errorCard}
//                             onSubmit={handleSignUp}
//                         />
//                     </Elements>
//                 </div>
//             </div>
//         </div>
//     );
// };

// ConsumerOffMarketSubPayment.propTypes = {
//     onHandleSubmit: PropTypes.func,
//     loading: PropTypes.bool,
//     offMarketPlan: PropTypes.string,
// };

// ConsumerOffMarketSubPayment.defaultProps = {
//     onHandleSubmit: () => {},
//     loading: false,
//     offMarketPlan: null,
// };

// const StripeCheckoutForm = (props) => {
//     const { errorCard, stripe, onSubmit, loading } = props;

//     const [elementFocused, setElementFocused] = useState(null);

//     const createOptions = () => ({
//         onFocus: ({ elementType }) => setElementFocused(elementType),
//         onBlur: () => setElementFocused(null),
//         style: {
//             base: {
//                 // fontSize: '16px',
//                 color: '#424770',
//                 letterSpacing: '0.025em',
//                 // fontFamily: "'Poppins', sans-serif",
//                 '::placeholder': { color: '#c7c7c7' },
//             },
//             invalid: { color: '#9e2146' },
//         },
//     });

//     return (
//         <>
//             <div className="ConsumerOffMarketSubPaymentStripeCheckout">
//                 <div className="StripeCheckout">
//                     {errorCard ? <div className="text-center text-sm text-red-500">Invalid Payment Details</div> : null}
//                     <section className={clsx('element text-gray-600', elementFocused === 'cardNumber' && 'focused')}>
//                         Card number
//                         <CardNumberElement {...createOptions()} />
//                     </section>
//                     <Row justify="center" gutter={12}>
//                         <Col span={12}>
//                             <section
//                                 className={clsx('element text-gray-600', elementFocused === 'cardExpiry' && 'focused')}
//                             >
//                                 Expiration date
//                                 <CardExpiryElement {...createOptions()} />
//                             </section>
//                         </Col>
//                         <Col span={12}>
//                             <section
//                                 className={clsx('element text-gray-600', elementFocused === 'cardCvc' && 'focused')}
//                             >
//                                 CVC
//                                 <CardCVCElement {...createOptions()} />
//                             </section>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>

//             <div className="flex w-full justify-center py-3 pt-8">
//                 <Button
//                     onClick={() => onSubmit(stripe)}
//                     type="primary"
//                     size="large"
//                     htmlType="submit"
//                     className="max-w-xs"
//                     block
//                     loading={loading}
//                 >
//                     SAVE
//                 </Button>
//             </div>
//         </>
//     );
// };

// StripeCheckoutForm.propTypes = {
//     errorCard: PropTypes.bool,
//     success: PropTypes.bool,
//     loading: PropTypes.bool,
//     onSubmit: PropTypes.func,
//     onSuccess: PropTypes.func,
//     stripe: PropTypes.object,
// };

// StripeCheckoutForm.defaultProps = {
//     errorCard: false,
//     success: false,
//     loading: false,
//     onSuccess: () => {},
//     onSubmit: () => {},
//     stripe: {},
// };
// const WrappedStripeCheckoutForm = injectStripe(StripeCheckoutForm);

// export default ConsumerOffMarketSubPayment;
