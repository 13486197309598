import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import styles from './PopoverPricingFilterRange.module.scss';

const PopoverPricingFilterRange = forwardRef(({ min, max, onChange, value, range, format, suffix }, ref) => {
    const setRange = () =>
        range.map((val) => ({
            val,
            disabled: false,
        }));
    const newRange = setRange();

    const [minRange, setMinRange] = useState(newRange);
    const [maxRange, setMaxRange] = useState(newRange);

    useEffect(() => {
        setMaxRange(setRange());
        setMinRange(setRange());
    }, [range]);

    useEffect(() => {
        setMaxRange(
            maxRange.map(({ val }) => ({
                val,
                disabled: value.min === undefined ? false : val < value.min,
            }))
        );
        setMinRange(
            minRange.map(({ val }) => ({
                val,
                disabled: value.max === null ? false : val > value.max,
            }))
        );
    }, [value.min, value.max]);

    const handleChange = (minValue, maxValue) => {
        onChange({ min: minValue === min ? undefined : minValue, max: maxValue === max ? undefined : maxValue });
    };

    const handleMinChange = (minValue) => {
        handleChange(minValue, value.max);
    };

    const handleMaxChange = (maxValue) => {
        handleChange(value.min, maxValue);
    };

    return (
        <div className={`${styles.PopoverPricingFilterRange} flex items-center justify-center`} ref={ref}>
            <section>
                <Select
                    style={{ width: 110 }}
                    defaultValue={value.min || null}
                    placeholder="Min price"
                    onChange={handleMinChange}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="mr-4"
                >
                    <Select.Option key="min" value={null}>
                        Min
                    </Select.Option>
                    {minRange.map(({ val, disabled }) => (
                        <Select.Option key={val} value={val} disabled={disabled}>
                            {(format === 'price' ? `$ ${val}` : `${val}`).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            {suffix}
                        </Select.Option>
                    ))}
                </Select>
            </section>
            <span className="mx-1">&nbsp;</span>
            <section>
                <Select
                    style={{ width: 110 }}
                    placeholder="Max price"
                    defaultValue={value.max || null}
                    onChange={handleMaxChange}
                    getPopupContainer={(trigger) => trigger.parentNode}
                >
                    <Select.Option key="max" value={null}>
                        Max
                    </Select.Option>
                    {maxRange.map(({ val, disabled }) => (
                        <Select.Option key={val} value={val} disabled={disabled}>
                            {format === 'price' ? `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : val}
                            {suffix}
                        </Select.Option>
                    ))}
                </Select>
            </section>
        </div>
    );
});

PopoverPricingFilterRange.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    range: PropTypes.arrayOf(PropTypes.number),
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    format: PropTypes.string,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PopoverPricingFilterRange.defaultProps = {
    value: [0, 0],
    range: [0, 0],
    min: 0,
    max: 15000000,
    onChange: () => {},
    format: null,
    suffix: null,
};
export default PopoverPricingFilterRange;
