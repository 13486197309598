/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Marker } from '@react-google-maps/api';
import PRIMARY from '~/constants/colors';
import markerBlue from '~/assets/marker-blue.png';
import markerGold from '~/assets/marker-gold.png';

const getMarker = (markerType, isOpen) => {
    let propertyMarker = markerBlue;

    switch (markerType) {
        case 'MARKER_GOLD': {
            propertyMarker = markerGold;
            break;
        }
        case 'MARKER_BLUE': {
            propertyMarker = markerBlue;
            break;
        }
        case 'MARKER_DOT': {
            propertyMarker = {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 6,
                fillColor: PRIMARY,
                fillOpacity: isOpen ? 0 : 1,
                strokeColor: PRIMARY,
            };
            break;
        }
        default: {
            propertyMarker = markerBlue;
        }
    }

    return propertyMarker;
};

const PropertyMapMarkerWithInfoBox = memo((props) => {
    const { propertyId, lat, lng, isBouncing, onOpen, clusterer, markerType, isOpen } = props;

    return (
        <Marker
            key={propertyId}
            position={{ lat, lng }}
            onClick={() => onOpen(propertyId)}
            onMouseOver={() => onOpen(propertyId)}
            onFocus={() => {}}
            icon={getMarker(markerType, isOpen)}
            clusterer={clusterer}
            animation={isBouncing ? window.google.maps.Animation.BOUNCE : window.google.maps.Animation.DROP}
        />
    );
});

PropertyMapMarkerWithInfoBox.propTypes = {
    propertyId: PropTypes.string.isRequired,
    clusterer: PropTypes.object,
    markerType: PropTypes.oneOf(['MARKER_GOLD', 'MARKER_BLUE', 'MARKER_DOT']),
    lat: PropTypes.number,
    lng: PropTypes.number,
    onOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    isBouncing: PropTypes.bool,
};

PropertyMapMarkerWithInfoBox.defaultProps = {
    lat: -19.56703,
    lng: 146.15156,
    onOpen: () => {},
    clusterer: {},
    isOpen: false,
    isBouncing: false,
    markerType: 'MARKER_BLUE',
};

export default PropertyMapMarkerWithInfoBox;
