import React from 'react';
import { FacebookProvider } from 'react-facebook';
import withApolloProvider from '~/lib/apollo/with-apollo-provider';
import StripeLoader from '~/components/StripeLoader';
import PropertySearchPage from '~/pages/PropertySearchPage';

const Search = () => (
    <StripeLoader>
        <FacebookProvider appId={process.env.NEXT_PUBLIC_FB_APP_ID} version="v3.2">
            <PropertySearchPage />
        </FacebookProvider>
    </StripeLoader>
);

export default withApolloProvider(Search);
