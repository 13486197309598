import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './NumberPlusMinusInput.module.scss';

const NumberPlusMinusInput = props => {
    const { value, defaultValue, onChange, label, min, max, className, size } = props;

    const [val, setVal] = useState(value === undefined ? defaultValue : value);

    const triggerChange = values => {
        onChange(values);
    };

    const onClickMinus = () => {
        setVal(val > min ? val - 1 : val);
        triggerChange(val > min ? val - 1 : val);
    };

    const onClickAdd = () => {
        setVal(val < max ? val + 1 : val);
        triggerChange(val < max ? val + 1 : val);
    };

    return (
        <span className="NumberPlusMinusInput inline-block">
            <Button
                disabled={!(value > min)}
                type="primary"
                shape="circle"
                icon={<MinusOutlined />}
                ghost
                size={size}
                className="button-minus"
                onClick={onClickMinus}
            />
            <span className={className}>
                <span className="mr-1 inline-block">{value}+</span>
                {label}
            </span>
            <Button
                disabled={!(value < max)}
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                ghost
                size={size}
                className="button-plus"
                onClick={onClickAdd}
            />
        </span>
    );
};

NumberPlusMinusInput.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
};

NumberPlusMinusInput.defaultProps = {
    onChange: () => {},
    defaultValue: 0,
    value: 0,
    min: 0,
    max: 10,
    label: '',
    className: 'text-lg mx-2 font-medium',
    size: 'large',
};

export default NumberPlusMinusInput;
