/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import CalendarPicker from '~/utils/generatePicker/CalendarPicker';
import './PropertyMobileOfiTime.module.scss';

const PropertyMobileOfiTime = ({ statusOfDatePicker, onSubmit, onClose, defaultDate }) => {
    const [chosenDate, setChosenDate] = useState(dayjs().format('YYYY-MM-DD'));

    const onSelectDate = (date) => {
        if (date) {
            setChosenDate(dayjs(date).format('YYYY-MM-DD'));
        }
    };

    const onOfiSubmit = () => {
        onSubmit(chosenDate);
    };

    const disablePastDates = (dates) => {
        const currentDay = dayjs().format('YYYY-MM-DD');
        const formatDates = dayjs(dates).format('YYYY-MM-DD');
        if (dayjs(formatDates).isBefore(currentDay)) {
            return dates;
        }
    };

    return (
        <Modal
            title="Open for Inspection Date"
            visible={statusOfDatePicker}
            onOk={onOfiSubmit}
            placement="bottom"
            okText="Apply"
            onCancel={onClose}
            style={{ top: 0 }}
            className="PropertyMobileOfiTime"
        >
            <div
                style={{
                    width: 'auto',
                    margin: 'auto',
                    borderRadius: 4,
                }}
            >
                <CalendarPicker
                    fullscreen={false}
                    mode="month"
                    onSelect={onSelectDate}
                    disabledDate={disablePastDates}
                    className="calendar-ofi"
                    defaultValue={dayjs(defaultDate)}
                />
            </div>
        </Modal>
    );
};

PropertyMobileOfiTime.propTypes = {
    statusOfDatePicker: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    defaultDate: PropTypes.string,
};

PropertyMobileOfiTime.defaultProps = {
    statusOfDatePicker: false,
    onSubmit: () => {},
    onClose: () => {},
    defaultDate: dayjs().format('YYYY-MM-DD'),
};

export default PropertyMobileOfiTime;
