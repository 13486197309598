import React from 'react';
import PropTypes from 'prop-types';
import { faSortAmountDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import omit from 'lodash.omit';
import styles from './PropertySortingSelect.module.scss';

const PropertySortingSelect = (props) => {
    const { type } = props;
    const selectProps = omit(props, ['type']);

    return (
        <div className={`${styles.PropertySortingSelect} flex items-center justify-end`}>
            <div className="mr-1 w-full">
                <FA
                    icon={faSortAmountDown}
                    style={{ fontSize: '1.125rem', fontWidth: '1.75rem' }}
                    className="mr-0 sm:mr-1"
                />
                <span className="hidden text-sm sm:inline">Sort By:</span>
            </div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <div>
                <Select {...selectProps} optionLabelProp="children" style={{ width: 200 }}>
                    <Select.Option value="FEATURED">
                        <span className="block text-center text-sm">Featured</span>
                    </Select.Option>
                    {type === 'SOLD' ? (
                        <Select.Option value="SALES_DATE">
                            <span className="block text-center text-sm">Sales Date</span>
                        </Select.Option>
                    ) : null}
                    <Select.Option value="PRICE_HIGH_LOW">
                        <span className="block text-center text-sm">Highest to Lowest Price</span>
                    </Select.Option>
                    <Select.Option value="PRICE_LOW_HIGH">
                        <span className="block text-center text-sm">Lowest to Highest Price</span>
                    </Select.Option>
                    <Select.Option value="NEWEST">
                        <span className="block text-center text-sm">Newest to Oldest</span>
                    </Select.Option>
                    <Select.Option value="OLDEST">
                        <span className="block text-center text-sm">Oldest to Newest</span>
                    </Select.Option>
                </Select>
            </div>
        </div>
    );
};

PropertySortingSelect.propTypes = { type: PropTypes.string };

PropertySortingSelect.defaultProps = { type: null };

export default PropertySortingSelect;
