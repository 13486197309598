/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
// /* eslint-disable no-shadow */
import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Pagination, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { encode } from 'universal-base64';
import { useRouter } from 'next/router';
import FeaturedPropertyCard from '~/components/FeaturedPropertyCard';
import FeaturedPropertyCardLg from '~/components/FeaturedPropertyCardLg';
import FeaturedPropertyCardSm from '~/components/FeaturedPropertyCardSm';
import PropertyCardSkeleton from '~/components/PropertyCardSkeleton';
import PropertyCard from '~/components/PropertyCard';
import OffMarketPropertyCard from '~/components/OffMarketPropertyCard';
import { Media, MediaContextProvider } from '~/Media';
// import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import { FEATURED_69, FEATURED_99, HIGHLIGHT_199, PREMIERE_499, STANDARD } from '~/constants/property-ad-types';
import { EmitterContext, EventNames } from '~/context/emitter-context';
import PropertyResultHideProperty from '~components/PropertySearchResultHideProperty';
import './PropertySearchResultPaginated.module.scss';
import { roundNearestGroup } from '~/utils/number';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';

// https://codesandbox.io/s/y2oz7m7wx
const largePropertyTiers = [HIGHLIGHT_199, PREMIERE_499];

const PropertySearchResultPaginated = memo(props => {
    const {
        resultList,
        totalCount,
        loading,
        searchOptions: { showMap, ofiTime, page },
        searchFilters,
        isFromSearch,
        isMobile,
        onChangePagination,
        pageSize,
        featuredBroker,
        showBrokerOnMobile,
        propertySearchVariables,
        hidePropertiesList,
    } = props;

    const router = useRouter();

    const curPage = page || 1;

    const fromRange = (curPage - 1) * pageSize + 1;
    const toRange = (curPage - 1) * pageSize + resultList.length;

    const [hasScrolled, setHasScrolled] = useState(false);
    //
    // const [newList, setNewList] = useState(false);
    // const [hideNewResult, setHideNewResult] = useState(false);
    // const [hidePage, setHidePage] = useState(false);
    const [viewMore, setViewMore] = useState(false);
    // let copyResult = [];
    // let currentResultList;
    // let fetchObject;

    //
    const { emit } = useContext(EmitterContext);

    const onPropertyMouseOver = (propertyId, propertyStatus) => {
        if (propertyStatus !== 'OFFMARKET') {
            emit(EventNames.ON_PROPERTY_MOUSE_OVER, propertyId);
        }
    };
    //

    // currentResultList = hidePage && curPage === hidePage ? newList : resultList;

    const handleHideViewMore = () => {
        setViewMore(!viewMore);
    };

    // const onUnhideList = index => {
    //     let selectedPropertyToUnhide;
    //     let copyUnhideNewResult = [];

    //     copyResult = hideNewResult.slice(0);
    //     selectedPropertyToUnhide = copyResult.splice(index, 1);
    //     fetchObject = selectedPropertyToUnhide[0];
    //     copyUnhideNewResult = newList;
    //     copyUnhideNewResult.splice(0, 0, fetchObject);
    //     setHideNewResult(copyResult);
    //     setNewList(copyUnhideNewResult);
    // };

    // const onHideList = index => {
    //     let selectedPropertyToHide;
    //     let copyHideNewResult = [];

    //     copyResult = newList ? newList.slice(0) : resultList.slice(0);
    //     selectedPropertyToHide = copyResult.splice(index, 1);
    //     fetchObject = selectedPropertyToHide[0];
    //     if (hideNewResult) {
    //         copyHideNewResult = hideNewResult;
    //     }
    //     copyHideNewResult.push(fetchObject);
    //     setHideNewResult(copyHideNewResult);
    //     setNewList(copyResult);
    //     setHidePage(curPage);
    // };

    const renderHidePropertyCard = (property, nextHideProperty, prevHideProperty, index) => {
        const cardProps = {
            property,
            ofiTime: !!ofiTime,
            isFromSearch,
        };
        const encodedSearchOptions = encode(
            JSON.stringify({
                ...searchFilters.options,
                isFromSearch: true,
                searchUrl: router.asPath,
            })
        );

        const isHighlightOrPremiumNext = largePropertyTiers.includes(nextHideProperty?.featuredTier ?? null);
        const isHighlightOrPremiumPrev = largePropertyTiers.includes(prevHideProperty?.featuredTier ?? null);

        const paidPropertySize = {
            xs: 24,
            // xl: showMap ? 24 : 12,
            xl: showMap ? (isHighlightOrPremiumNext || isHighlightOrPremiumPrev ? 24 : 24) : 12,
            xxl: showMap ? (isHighlightOrPremiumNext || isHighlightOrPremiumPrev ? 24 : 24) : 12,
        };

        const cardSmWidth = {
            xs: 24,
            sm: 12,
            md: 8,
            lg: showMap ? 12 : 8,
            xl: showMap ? 12 : 6,
            xxl: showMap ? 8 : 6,
        };

        return (
            <Col
                xs={24}
                md={12}
                // {...paidPropertySize}
                className="pb-8 w-full"
                key={property.id}
                onMouseEnter={() => onPropertyMouseOver(property.id)}
                onMouseLeave={() => onPropertyMouseOver(null)}
            >
                <PropertyResultHideProperty
                    {...cardProps}
                    ofiTime
                    hasScrolled={hasScrolled}
                    // offsetHeaderHeight={offsetHeaderHeight}
                    isFromSearch
                    searchOptions={encodedSearchOptions}
                    searchUrl={router.asPath}
                    // onChangeList={onUnhideList}
                    // getIndex={index}
                    propertySearchVariables={propertySearchVariables}
                />
            </Col>
        );
    };
    //

    const renderCard = (property, cursor, nextProperty, prevProperty, index) => {
        const { featuredTier } = property;
        // const advancedSearchHeight = !isEmpty(document.getElementsByClassName('advanced-search'))
        //     ? document.getElementsByClassName('advanced-search')[0].clientHeight
        //     : 74;
        // const menuHeight = !isEmpty(document.getElementsByClassName('header-dark'))
        //     ? document.getElementsByClassName('header-dark')[0].clientHeight
        //     : 56;
        // const offsetHeaderHeight = advancedSearchHeight + menuHeight;

        const cardProps = {
            property,
            ofiTime: !!ofiTime,
            isFromSearch,
        };
        const encodedSearchOptions = encode(
            JSON.stringify({
                ...searchFilters.options,
                cursor,
                isFromSearch: true,
                searchUrl: router.asPath,
            })
        );

        const isHighlightOrPremiumNext = largePropertyTiers.includes(nextProperty?.featuredTier ?? null);
        const isHighlightOrPremiumPrev = largePropertyTiers.includes(prevProperty?.featuredTier ?? null);

        const paidPropertySize = {
            xs: 24,
            // xl: showMap ? 24 : 12,
            xl: showMap ? (isHighlightOrPremiumNext || isHighlightOrPremiumPrev ? 24 : 24) : 12,
            xxl: showMap ? (isHighlightOrPremiumNext || isHighlightOrPremiumPrev ? 24 : 24) : 12,
        };

        const cardSmWidth = {
            xs: 24,
            sm: 12,
            md: 8,
            lg: showMap ? 12 : 8,
            xl: showMap ? 12 : 6,
            xxl: showMap ? 8 : 6,
        };

        switch (featuredTier) {
            case STANDARD: {
                return (
                    <Col
                        {...cardSmWidth}
                        className="pb-8"
                        key={property.id}
                        onMouseEnter={() => onPropertyMouseOver(property.id, property.status)}
                        onMouseLeave={() => onPropertyMouseOver(null, property.status)}
                    >
                        {property.status === 'OFFMARKET' ? (
                            <OffMarketPropertyCard
                                {...cardProps}
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                                // carouselImageHeight="100%"
                                propertySearchVariables={propertySearchVariables}
                            />
                        ) : (
                            <PropertyCard
                                showHideListing
                                {...cardProps}
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                searchOptions={encodedSearchOptions}
                                carouselImageHeight="100%"
                                searchUrl={router.asPath}
                                propertySearchVariables={propertySearchVariables}
                            />
                        )}
                    </Col>
                );
            }
            case FEATURED_69:
            case FEATURED_99: {
                return (
                    <Col
                        {...cardSmWidth}
                        className="pb-8"
                        key={property.id}
                        onMouseEnter={() => onPropertyMouseOver(property.id)}
                        onMouseLeave={() => onPropertyMouseOver(null)}
                    >
                        <FeaturedPropertyCardSm
                            {...cardProps}
                            ofiTime
                            hasScrolled={hasScrolled}
                            searchOptions={encodedSearchOptions}
                            // offsetHeaderHeight={offsetHeaderHeight}
                            isFromSearch
                            searchUrl={router.asPath}
                            // onChangeList={onHideList}
                            // getIndex={index}
                            propertySearchVariables={propertySearchVariables}
                        />
                    </Col>
                );
            }
            case HIGHLIGHT_199: {
                return (
                    <Col
                        {...paidPropertySize}
                        className="pb-8"
                        key={property.id}
                        onMouseEnter={() => onPropertyMouseOver(property.id)}
                        onMouseLeave={() => onPropertyMouseOver(null)}
                    >
                        {isMobile ? (
                            <FeaturedPropertyCardSm
                                {...cardProps}
                                ofiTime
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                                // onChangeList={onHideList}
                                // getIndex={index}
                                propertySearchVariables={propertySearchVariables}
                            />
                        ) : (
                            <FeaturedPropertyCard
                                {...cardProps}
                                ofiTime
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                                // onChangeList={onHideList}
                                propertySearchVariables={propertySearchVariables}
                            />
                        )}
                    </Col>
                );
            }
            case PREMIERE_499: {
                return (
                    <Col
                        {...paidPropertySize}
                        className="pb-8"
                        key={property.id}
                        onMouseEnter={() => onPropertyMouseOver(property.id)}
                        onMouseLeave={() => onPropertyMouseOver(null)}
                    >
                        {isMobile ? (
                            <FeaturedPropertyCardSm
                                {...cardProps}
                                ofiTime
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                                // onChangeList={onHideList}
                                // getIndex={index}
                                propertySearchVariables={propertySearchVariables}
                            />
                        ) : (
                            <FeaturedPropertyCardLg
                                {...cardProps}
                                ofiTime
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                                propertySearchVariables={propertySearchVariables}
                            />
                        )}
                    </Col>
                );
            }
            default: {
                return (
                    <Col
                        {...cardSmWidth}
                        className="pb-8"
                        key={property.id}
                        onMouseEnter={() => onPropertyMouseOver(property.id, property.status)}
                        onMouseLeave={() => onPropertyMouseOver(null, property.status)}
                    >
                        {property.status === 'OFFMARKET' ? (
                            <OffMarketPropertyCard
                                {...cardProps}
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                carouselImageHeight="100%"
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                            />
                        ) : (
                            <PropertyCard
                                showHideListing
                                {...cardProps}
                                hasScrolled={hasScrolled}
                                // offsetHeaderHeight={offsetHeaderHeight}
                                isFromSearch
                                carouselImageHeight="100%"
                                searchOptions={encodedSearchOptions}
                                searchUrl={router.asPath}
                                propertySearchVariables={propertySearchVariables}
                            />
                        )}
                    </Col>
                );
            }
        }
    };

    return (
        <div className="PropertySearchResultPaginated">
            <Row gutter={40} justify="center">
                {loading &&
                    [1, 2, 3, 4, 5, 6].map(val => (
                        <Col xs={24} sm={24} lg={12} xl={12} className="pb-3" key={val}>
                            <PropertyCardSkeleton />
                        </Col>
                    ))}
                {hidePropertiesList && hidePropertiesList.length > 0 && (
                    <Col span={24}>
                        <div className="flex justify-between text-base pb-8">
                            <span>
                                {hidePropertiesList.length} {hidePropertiesList.length > 1 ? `properties` : `property`}{' '}
                                removed from list
                            </span>
                            <a className={`block ${viewMore ? `text-primary-500` : ''}`} onClick={handleHideViewMore}>
                                {viewMore ? (
                                    <span>
                                        Hide removed listings <UpOutlined />
                                    </span>
                                ) : (
                                    <span>
                                        View removed listings <DownOutlined />
                                    </span>
                                )}
                            </a>
                        </div>
                    </Col>
                )}
                <Col span={24} className="flex flex-wrap items-center justify-between" style={{ padding: 0 }}>
                    {hidePropertiesList &&
                        viewMore &&
                        hidePropertiesList.map(({ ...rest }, index, array) => {
                            const nextHideIndex = index + 1;
                            const previousHideIndex = index - 1;
                            const nextHideProperty = array.length > nextHideIndex ? array[nextHideIndex]?.node : null;
                            const prevHideProperty =
                                previousHideIndex >= 0 && nextHideIndex < array.length
                                    ? array[previousHideIndex]?.node
                                    : null;

                            const hideResult = [];
                            hideResult.push(renderHidePropertyCard(rest, nextHideProperty, prevHideProperty, index));
                            return hideResult;
                        })}
                </Col>
                {!loading &&
                    resultList.map(({ node, cursor }, index, array) => {
                        const nextIndex = index + 1;
                        const previousIndex = index - 1;
                        const nextProperty = array.length > nextIndex ? array[nextIndex]?.node : null;
                        const prevProperty =
                            previousIndex >= 0 && nextIndex < array.length ? array[previousIndex]?.node : null;

                        const result = [];
                        if (featuredBroker && showBrokerOnMobile && index % 5 === 0 && index > 0) {
                            result.push(
                                <Col span={24} className="flex items-stretch mb-5 " key="featured_broker">
                                    <PropertySearchBrokerCard
                                        firstName={featuredBroker?.broker.firstName}
                                        lastName={featuredBroker?.broker.lastName}
                                        suburbName={featuredBroker?.location.suburb}
                                        profilePicture={featuredBroker?.broker.profilePicture}
                                        brokerId={featuredBroker?.broker.id}
                                        logoUrl={featuredBroker?.broker.profileLogoUrl}
                                        headerColor={featuredBroker?.broker.headerColor}
                                        slugUrl={featuredBroker?.broker.slugUrl}
                                        logoShape={featuredBroker?.broker.logoShape}
                                        pagePath={featuredBroker}
                                    />
                                </Col>
                            );
                        }
                        result.push(renderCard(node, cursor, nextProperty, prevProperty, index));
                        return result;
                    })}
                {resultList.length ? (
                    <>
                        <Col span={24} className="pt-8">
                            <Pagination
                                className="text-center"
                                pageSize={pageSize}
                                current={page || 1}
                                defaultCurrent={1}
                                total={totalCount}
                                onChange={onChangePagination}
                                pageSizeOptions={[10, 24, 50, 100]}
                            />
                        </Col>
                        <Col span={24} className="py-6">
                            <div className="text-sm text-center text-gray-600">
                                {fromRange} - {toRange} of{' '}
                                {`${
                                    totalCount >= 10 && toRange !== totalCount
                                        ? `${roundNearestGroup(totalCount)}+`
                                        : totalCount
                                } properties`}
                            </div>
                        </Col>
                    </>
                ) : null}
            </Row>
        </div>
    );
});

PropertySearchResultPaginated.propTypes = {
    resultList: PropTypes.oneOfType([PropTypes.any]),
    loading: PropTypes.bool,
    maxItemsPerRow: PropTypes.number,
    totalCount: PropTypes.number,
    pageSize: PropTypes.number,
    isMobile: PropTypes.bool,
    searchOptions: PropTypes.shape({
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        page: PropTypes.number,
    }),
    searchFilters: PropTypes.object,
    featuredBroker: PropTypes.object,
    isFromSearch: PropTypes.bool,
    showLoadingMore: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    showBrokerOnMobile: PropTypes.bool,
    loadMore: PropTypes.func,
    onChangePagination: PropTypes.func,
};

PropertySearchResultPaginated.defaultProps = {
    resultList: [],
    loading: false,
    maxItemsPerRow: 1,
    totalCount: 0,
    pageSize: 24,
    isMobile: false,
    searchOptions: {},
    searchFilters: {},
    featuredBroker: {},
    isFromSearch: null,
    showLoadingMore: false,
    isLoadingMore: false,
    showBrokerOnMobile: false,
    loadMore: () => {},
    onChangePagination: () => {},
};

const PropertySearchResultPaginatedComponent = props => (
    // <ScreenWidthValueProvider xs sm>
    //     {isMobile => <PropertySearchResultPaginated {...props} isMobile={isMobile || false} />}
    <MediaContextProvider>
        <Media lessThan="sm">
            <PropertySearchResultPaginated {...props} isMobile />
        </Media>
        <Media greaterThanOrEqual="sm">
            <PropertySearchResultPaginated {...props} />
        </Media>
    </MediaContextProvider>
);

export default PropertySearchResultPaginatedComponent;
