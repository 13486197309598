/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Tooltip } from 'antd';
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons';
import { faGlobeAsia, faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import compact from 'lodash/compact';
import has from 'lodash/has';
import Select from 'rc-select';
import 'rc-select/assets/index.css';
import { CSSTransition } from 'react-transition-group';
import { loginUser } from '~/actions';
import LoadingDots from '~/components/LoadingDots';
import PropertySortingSelect from '~/components/PropertySortingSelect';
import SearchFilterModal from '~/components/SearchFilterModal';
import PublicCreateOffMarketSubModal from '~/components/PublicCreateOffMarketSubModal';
import PropertyStatusSelect from '~/components/PropertyStatusSelect';
import PopoverPrice from '~/components/PopoverPrice';
import PopoverBed from '~/components/PopoverBed';
import LocationSuggestionsQuery from '~/queries/LocationSuggestionsQuery.graphql';
import { Consumer } from './Operations.graphql';
import styles from './PropertyAdvancedSearch.module.scss';

const PropertyAdvancedSearch = ({
    searchOptions,
    changeSearchUrl,
    onChangeSorting,
    isAuthenticated,
    authUserId,
    className,
    onChange,
}) => {
    const client = useApolloClient();
    const [form] = Form.useForm();
    const { getFieldsValue, getFieldValue, setFieldsValue, resetFields } = form;
    const [isLoading, setIsLoading] = useState(false);
    const [placeholder, setPlaceholder] = useState('Search by suburb or postcode');
    const [searchStyle, setSearchStyle] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [show, setShow] = useState(false);
    const [ranges, setRanges] = useState({});
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);
    const [currentKeyword, setCurrentKeyword] = useState('');
    const [visiblePopover, setVisiblePopover] = useState(false);
    const [isOpenSuburbSelect, setIsOpenSuburbSelect] = useState(false);

    const showEmptySearchMessage = () => {
        setSearchStyle('search-error');
        setPlaceholder('Please select a suburb');

        return setTimeout(() => {
            setSearchStyle('');
            setPlaceholder('Search by suburb or postcode');
        }, 5000);
    };

    useEffect(() => {
        if (!show) {
            setShow(true);
        }

        return function cleanup() {
            clearTimeout(showEmptySearchMessage);
        };
    }, []);

    useEffect(() => {
        resetFields();
    }, [searchOptions?.group]);

    const changeSorting = (value) => {
        onChangeSorting(value);
    };

    const onClosePopover = () => {
        setVisiblePopover(false);
    };

    const handleVisibleChange = (visible) => {
        setVisiblePopover(visible);
    };

    const onInputKeyDown = (keyEvent) => {
        const { locations } = searchOptions || {};

        const currentLocations = !isEmpty(locations)
            ? locations.map((loc) => ({
                  key: loc,
                  label: loc,
              }))
            : [];
        const hasSearchValue = !isEmpty(getFieldValue('keyword')) ? getFieldValue('keyword') : currentLocations;

        if (keyEvent.which === 13) {
            if (!(hasSearchValue && hasSearchValue.length > 0)) {
                keyEvent.preventDefault();
                showEmptySearchMessage();
            } else {
                setFieldsValue({ keyword: hasSearchValue });
                changeSearchUrl(getFieldsValue(), searchOptions);
            }
        }
    };

    const handleSearch = async (value) => {
        const keyword = value;

        setIsLoading(true);
        setCurrentKeyword(value);

        try {
            const {
                data: { locationSuggestions },
            } = await client.query({
                query: LocationSuggestionsQuery,
                variables: {
                    filter: {
                        keyword,
                        first: 10,
                    },
                },
            });

            setIsLoading(false);
            setDataSource(locationSuggestions || []);
            setIsOpenSuburbSelect(true);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearch = useCallback(
        debounce((value) => handleSearch(value), 275),
        []
    );

    const handleSubmitFilterModal = (data) => {
        setFieldsValue({
            landArea: data.landArea,
            features: data.features,
            parking: data.parking,
            category: data.category,
            bathroom: data.bathroom,
            isNewConstruction: data.isNewConstruction,
        });
        changeSearchUrl(getFieldsValue(), searchOptions);
    };

    const searchButtonClick = (event) => {
        const { locations } = searchOptions || {};

        const currentLocations = !isEmpty(locations)
            ? locations.map((loc) => ({
                  key: loc,
                  label: loc,
              }))
            : [];
        const hasSearchValue = !isEmpty(getFieldValue('keyword')) ? getFieldValue('keyword') : currentLocations;

        if (hasSearchValue && hasSearchValue.length > 0) {
            changeSearchUrl(getFieldsValue(), searchOptions);
        } else {
            event.preventDefault();
            showEmptySearchMessage();
        }
    };

    const renderOptions = (sourceData = [], keywordValue) => {
        if (sourceData.length === 0 && keywordValue) {
            return [
                <Select.Option key={keywordValue} className="py-3" value={`k:${keywordValue}`} title={keywordValue}>
                    <span className="text-sm">Search "{keywordValue}" </span>
                </Select.Option>,
            ];
        }
        const filteredResult = sourceData.filter((item) => item !== null);

        return (
            filteredResult.length && (
                <Select.OptGroup key="locations" label={<span className="text-xs">Locations</span>}>
                    {filteredResult.map((item) => (
                        <Select.Option key={item.full} className="py-3" value={item.full}>
                            <div className="flex items-center">
                                <FA
                                    icon={item.type === 'SUBURB' ? faMapMarkerAlt : faGlobeAsia}
                                    className="mr-2 text-xl"
                                />
                                <span>
                                    <span className="text-base">{item.full}</span>
                                    {item.type !== 'SUBURB' && (
                                        <span className="ml-1 text-xs text-gray-500">
                                            <span className="mr-1">&bull;</span>
                                            {item.type}
                                        </span>
                                    )}
                                </span>
                            </div>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )
        );
    };

    const renderRecentSearch = () => {
        const savedItemsJson = typeof localStorage !== 'undefined' ? localStorage.getItem('previousLocation') : '[]';
        const previousLocation = compact(JSON.parse(savedItemsJson));

        return (
            previousLocation.length && (
                <Select.OptGroup key="recentSearches" label={<span className="text-xs">Recent Searches</span>}>
                    {previousLocation.map((location) => (
                        <Select.Option key={location} className="py-3" value={`${location}`}>
                            <span className="text-base">{location}</span>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )
        );
    };

    const checkAuthentication = async () => {
        if (!isAuthenticated) {
            setIsShowLoginModal(true);
        } else {
            try {
                const {
                    data: { viewer },
                } = await client.query({ query: Consumer });

                const { hasOffMarketSubscription } = viewer || {};

                if (!hasOffMarketSubscription) {
                    setIsShowLoginModal(true);
                } else {
                    setFieldsValue({ isIncludeOffMarket: !searchOptions?.isIncludeOffMarket });
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const closeLoginModal = () => {
        setIsShowLoginModal(false);
    };

    const { group, sorting } = searchOptions;
    const fieldsValue = getFieldsValue(['bed', 'price']);
    const modalFieldsValue = getFieldsValue([
        'landArea',
        'features',
        'parking',
        'category',
        'bathroom',
        'isNewConstruction',
    ]);
    const searchOptionsFieldsValue = {
        category: has(searchOptions, 'category') ? searchOptions?.category : undefined,
        features: has(searchOptions, 'features') ? searchOptions?.features : undefined,
        parking: !isEmpty(searchOptions?.parking) ? searchOptions?.parking : undefined,
        landArea: !isEmpty(searchOptions?.landArea) ? searchOptions?.landArea : undefined,
        bathroom: !isEmpty(searchOptions?.bathroom) ? searchOptions?.bathroom : undefined,
        isNewConstruction: !isEmpty(searchOptions?.isNewConstruction) ? searchOptions?.isNewConstruction : undefined,
    };
    const modalFilterCount = Object.entries(modalFieldsValue)
        .map((field) =>
            !isEmpty(field[1]) && field[1] && typeof field[1] === 'object'
                ? Object.entries(field[1])[0].filter((val) => val).length
                : field[1] && field[1].length
        )
        .filter((val) => val > 1).length;

    const searchOptionsCount = Object.entries(searchOptionsFieldsValue)
        .map((field) =>
            field[1] && typeof field[1] === 'object'
                ? Object.entries(field[1])[0].filter((val) => val).length
                : field[1] && field[1].length
        )
        .filter((val) => val > 1).length;

    const onDropdownVisibleChange = (open) => {
        setIsOpenSuburbSelect(open);
    };

    const onSelectSuburb = () => {
        if (isOpenSuburbSelect) {
            setIsOpenSuburbSelect(false);
        }
    };
    const onDeSelectSuburb = () => {
        if (isOpenSuburbSelect) {
            setIsOpenSuburbSelect(false);
        }
    };

    return (
        <>
            <PublicCreateOffMarketSubModal
                visible={isShowLoginModal}
                onClose={closeLoginModal}
                authUserId={authUserId}
                headingText="Access our off-market properties using your social media"
            />
            <CSSTransition in={!!show} classNames="fade" timeout={1000} unmountOnExit>
                <Row justify="center" className={`${styles.advancedSearch} w-full shadow md:px-3 xl:px-12`}>
                    <Col xs={24} className="text-center">
                        <div className={`${styles.searchInput}`}>
                            <Form
                                initialValues={{
                                    parking: {
                                        min: searchOptions?.parking.min,
                                        max: searchOptions?.parking.max,
                                    } || {
                                        min: 0,
                                        max: 6,
                                    },
                                    landArea: {
                                        min: searchOptions?.landArea.min,
                                        max: searchOptions?.landArea.max,
                                    } || {
                                        min: 0,
                                        max: 200000,
                                    },
                                    bathroom: {
                                        min: searchOptions?.bathroom.min,
                                        max: searchOptions?.bathroom.max,
                                    } || {
                                        min: 0,
                                        max: 12,
                                    },
                                    category: searchOptions?.category,
                                    features: searchOptions?.features,
                                    keyword: [
                                        ...(searchOptions?.keywords
                                            ? searchOptions?.keywords.map((keyword) => ({
                                                  key: `k:${keyword}`,
                                                  label: keyword,
                                              }))
                                            : []),
                                        ...(searchOptions?.locations
                                            ? searchOptions?.locations.map((keyword) => ({
                                                  key: keyword,
                                                  label: keyword,
                                              }))
                                            : []),
                                    ],
                                    isIncludeSurrounding: searchOptions?.isIncludeSurrounding,
                                    isIncludeOffMarket: searchOptions?.isIncludeOffMarket,
                                    price: {
                                        min: searchOptions?.price.min,
                                        max: searchOptions?.price.max,
                                    } || {
                                        min: 0,
                                        max: 2000000,
                                    },
                                    bed: {
                                        min: searchOptions?.bed.min,
                                        max: searchOptions?.bed.max,
                                    } || {
                                        min: 0,
                                        max: 12,
                                    },
                                    isNewConstruction: searchOptions?.isNewConstruction,
                                }}
                                // onFinish={handleSubmit}
                                form={form}
                                onValuesChange={(changedValues, allValues) => {
                                    onChange(changedValues, allValues, searchOptions);
                                }}
                            >
                                <Row className="items-center justify-between">
                                    <Col xs={24} xl={24}>
                                        <Row justify="start" align="middle">
                                            <Col
                                                className={`keyword-search border-r sm:p-1 ${searchStyle} w-full sm:w-auto sm:flex-1`}
                                                md={6}
                                                xl={5}
                                                xxl={7}
                                            >
                                                <Row
                                                    justify="space-between"
                                                    align="middle"
                                                    className="overflow-hidden border border-gray-400"
                                                >
                                                    <Col className="flex-1">
                                                        <Form.Item
                                                            name="keyword"
                                                            className="form-item advanced-search-select"
                                                        >
                                                            <Select
                                                                onInputKeyDown={onInputKeyDown}
                                                                labelInValue
                                                                className="w-full"
                                                                animation="slide-up"
                                                                placeholder={placeholder}
                                                                multiple
                                                                open={isOpenSuburbSelect}
                                                                allowClear
                                                                dropdownClassName="property-search-dropdown"
                                                                notFoundContent={isLoading ? <LoadingDots /> : null}
                                                                onSearch={onSearch}
                                                                filterOption={false}
                                                                onDropdownVisibleChange={onDropdownVisibleChange}
                                                                onSelect={onSelectSuburb}
                                                                onDeselect={onDeSelectSuburb}
                                                            >
                                                                {renderOptions(dataSource, currentKeyword)}
                                                                {renderRecentSearch()}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col
                                                xs={0}
                                                sm={18}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={3}
                                                className="border-r sm:p-4"
                                            >
                                                <div className="flex items-center">
                                                    <Form.Item
                                                        name="group"
                                                        className="form-item search-option hidden w-full md:inline-block"
                                                    >
                                                        <div>
                                                            <PropertyStatusSelect type={group} />
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col
                                                xs={0}
                                                sm={18}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={3}
                                                className="border-r sm:p-1"
                                            >
                                                <div className={`${styles.numberInput} flex items-center`}>
                                                    <Form.Item
                                                        name="price"
                                                        className="form-item search-option hidden w-full md:inline-block"
                                                    >
                                                        <PopoverPrice type={group} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col className="border-r sm:p-1">
                                                <div
                                                    className={`${styles.numberInput} flex flex-wrap items-center hover:bg-gray-300`}
                                                >
                                                    <Form.Item
                                                        name="bed"
                                                        className="form-item search-option hidden w-auto p-1 md:inline-block"
                                                    >
                                                        <PopoverBed />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col className="search-btn-checkbox relative ml-2 bg-white">
                                                <div className="flex h-full items-center justify-center">
                                                    <div className="mr-1">
                                                        <Tooltip
                                                            placement="top"
                                                            title="Enables Surrounding Suburb Search"
                                                        >
                                                            <Form.Item
                                                                noStyle
                                                                name="isIncludeSurrounding"
                                                                valuePropName="checked"
                                                                className="form-item mr-1 inline-block"
                                                            >
                                                                <Checkbox>
                                                                    <span className="text-xs">Surrounding suburbs</span>
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="cursor-pointer" onClick={checkAuthentication}>
                                                        <Tooltip placement="top" title="Shows Off-Market Properties">
                                                            <Form.Item
                                                                noStyle
                                                                name="isIncludeOffMarket"
                                                                valuePropName="checked"
                                                                className="form-item inline-block"
                                                            >
                                                                <Checkbox className="pointer-events-none">
                                                                    <span className="text-xs">Off-Market</span>
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="form-item w-2/50 inline-block ">
                                                        <Button
                                                            onClick={searchButtonClick}
                                                            className="search-btn hover:text-blue-500"
                                                            htmlType="submit"
                                                            type="link"
                                                        >
                                                            <SearchOutlined style={{ fontSize: '20px' }} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="ml-2 filter">
                                                    <Form.Item className="form-item search-option inline-block w-auto  ">
                                                        <div className="flex items-center">
                                                            <Popover
                                                                getPopupContainer={(node) => node.parentNode}
                                                                visible={visiblePopover}
                                                                placement="bottomRight"
                                                                trigger="click"
                                                                onVisibleChange={handleVisibleChange}
                                                                content={
                                                                    <SearchFilterModal
                                                                        onOk={handleSubmitFilterModal}
                                                                        onClosePopover={onClosePopover}
                                                                        queryString={{}}
                                                                        data={{ ...fieldsValue, ...modalFieldsValue }}
                                                                        ranges={ranges}
                                                                        type={group}
                                                                    />
                                                                }
                                                            >
                                                                <Button size="small" type="link">
                                                                    <div className="flex items-center">
                                                                        <FA
                                                                            icon={faSlidersH}
                                                                            style={{ fontSize: '1.125rem' }}
                                                                            className="mr-2 text-primary-500"
                                                                        />
                                                                        <span className="mr-2 hidden xl:inline-block">
                                                                            More Filters
                                                                        </span>
                                                                    </div>
                                                                </Button>
                                                            </Popover>
                                                            {modalFilterCount || searchOptionsCount ? (
                                                                <span className="rounded-full bg-primary-500 px-3 py-1 text-xs text-white">
                                                                    {modalFilterCount || searchOptionsCount}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                {/* <Form.Item name="landArea" styles="hidden">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="features" className="hidden">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="parking" className="hidden">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="bathroom" className="hidden">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="category" className="hidden">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="isNewConstruction" className="hidden">
                                                    <Input />
                                                </Form.Item> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="mx-auto mt-1 block text-center sm:hidden">
                                        <div>
                                            <PropertySortingSelect
                                                value={sorting || 'FEATURED'}
                                                style={{ width: 200 }}
                                                onChange={changeSorting}
                                                type={group}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </CSSTransition>
        </>
    );
};

PropertyAdvancedSearch.propTypes = {
    className: PropTypes.string,
    changeSearchUrl: PropTypes.func,
    onChangeOfi: PropTypes.func,
    onChangeSorting: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    authUserId: PropTypes.string,
    searchOptions: PropTypes.shape({
        keyword: PropTypes.string,
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        group: PropTypes.string,
        bed: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        price: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        parking: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        landArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        floorArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        bathroom: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        mapLocation: PropTypes.shape({
            radius: PropTypes.number,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        locations: PropTypes.arrayOf(PropTypes.string),
        category: PropTypes.arrayOf(PropTypes.string),
        features: PropTypes.arrayOf(PropTypes.string),
        isIncludeSurrounding: PropTypes.bool,
        isIncludeOffMarket: PropTypes.bool,
        sorting: PropTypes.string,
        keywords: PropTypes.array,
        acceleratePreviewAgentId: PropTypes.string,
        isNewConstruction: PropTypes.bool,
    }),
    onChange: PropTypes.func,
};

PropertyAdvancedSearch.defaultProps = {
    changeSearchUrl: () => {},
    onChangeOfi: () => {},
    className: '',
    isAuthenticated: false,
    searchOptions: {},
    onChangeSorting: () => {},
    authUserId: null,
    onChange: () => {},
};

export default connect(
    (state) => ({
        isAuthenticated: state.auth.isAuthenticated,
        authUserId: state.auth.user ? state.auth.user.id : null,
    }),
    (dispatch) => ({
        onLoginUser(accessToken) {
            dispatch(loginUser(accessToken));
        },
    })
)(PropertyAdvancedSearch);
