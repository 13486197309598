import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import PopoverPriceFilterRangeFormItem from '~/components/PopoverPriceFilterRangeFormItem';

const PopoverPrice = forwardRef(({ type, min, max, onChange, value, format, suffix }, ref) => {
    return (
        <Popover
            placement="bottom"
            trigger="click"
            content={
                <PopoverPriceFilterRangeFormItem
                    type={type}
                    miniInfo={false}
                    onChange={onChange}
                    onRef={ref}
                    min={min}
                    max={max}
                    value={value}
                    format={format}
                    suffix={suffix}
                />
            }
        >
            Price: Any <DownOutlined className="pl-10 text-gray-400" />
        </Popover>
    );
});

PopoverPrice.propTypes = {
    onChange: PropTypes.func,
    type: PropTypes.string,
    format: PropTypes.string,
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PopoverPrice.defaultProps = {
    onChange: () => {},
    type: null,
    format: null,
    suffix: null,
};

export default PopoverPrice;
