import React from 'react';
import PropTypes from 'prop-types';
import PopoverPricingFilterRange from '~/components/PopoverPricingFilterRange';
import { bedRange } from '~/constants/range';

const PopoverBedFilterRangeFormItem = ({ onRef, onChange, min, max, value, range, format, suffix }) => {
    return (
        <PopoverPricingFilterRange
            range={bedRange}
            onChange={onChange}
            min={min}
            max={max}
            value={value}
            suffix={suffix}
            // {...rentFilter}
            ref={onRef}
        />
    );
};

PopoverBedFilterRangeFormItem.propTypes = {
    type: PropTypes.string,
};

PopoverBedFilterRangeFormItem.defaultProps = {
    type: null,
};

export default PopoverBedFilterRangeFormItem;
