import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import FilterRange from '~/components/FilterRange';
import { buyRange, rentRange } from '~/constants/range';

const PriceFilterRangeFormItem = forwardRef(({ type, ...rest }, ref) => {
    const isRent = `${type}`.toLowerCase() === 'rent';

    const rentFilter = isRent
        ? {
              min: 0,
              max: 5000,
              step: 5,
          }
        : {};

    return <FilterRange range={isRent ? rentRange : buyRange} format="price" {...rest} {...rentFilter} ref={ref} />;
});

PriceFilterRangeFormItem.propTypes = { type: PropTypes.string };

PriceFilterRangeFormItem.defaultProps = { type: null };

export default PriceFilterRangeFormItem;
