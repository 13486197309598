/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Affix } from 'antd';
import debounce from 'lodash.debounce';
import PublicLayout from '~/layouts/PublicLayout';
import LocationResolver from '~/components/LocationResolver';
import PropertyAdvancedSearch from '~/components/PropertyAdvancedSearch';
import PropertyAdvancedSearchMobile from '~/components/PropertyAdvancedSearchMobile';
import PropertySearchResults from '~/components/PropertySearchResults';
import PropertySearchSeoHeader from '~/components/PropertySearchSeoHeader';
import { Media } from '~/Media';
import SearchPageQueryParameterParser from '~/components/SearchPageQueryParameterParser';
import ConsumerViewer from './Operations.graphql';
import styles from './PropertySearchPage.module.scss';

const PropertySearchPage = ({ changeUrl, searchOptions, location }) => {
    const { data: consumerViewerData } = useQuery(ConsumerViewer);
    const { viewer } = consumerViewerData || {};

    const viewerConsumerId = viewer?.id ?? null;

    const onChangeOfi = (ofiTime) => {
        changeUrl({
            ...searchOptions,
            ofiTime,
            page: ofiTime ? 1 : searchOptions.page,
        });
    };

    const onChangeSorting = (sorting) => {
        changeUrl({
            ...searchOptions,
            sorting,
            page: 1,
        });
    };

    const onChangePagination = (page, pageSize) => {
        changeUrl({
            ...searchOptions,
            page,
            pageSize,
        });
    };

    const buildSearchOptions = (values, searchOptionsObj) => {
        const searchData = values.keyword;
        const { showMap, ofiTime, group, sorting, acceleratePreviewAgentId, ref, page, pageSize } = searchOptionsObj;

        const locations = searchData.filter(({ key }) => !key.startsWith('k:')).map(({ key }) => key);
        const keyword = searchData.filter(({ key }) => key.startsWith('k:')).map(({ key }) => key.slice(2));

        return {
            ...values,
            keywords: keyword,
            locations,
            category: values.category ? (values.category.includes('All') ? undefined : values.category) : undefined,
            features: values.features || undefined,
            landArea: {
                max: values?.landArea?.max,
                min: values?.landArea?.min,
            },
            bed: {
                max: values?.bed?.max,
                min: values?.bed?.min,
            },

            bathroom: {
                max: values?.bathroom?.max,
                min: values?.bathroom?.min,
            },
            parking: {
                max: values?.parking?.max,
                min: values?.parking?.min,
            },
            price: {
                max: values?.price?.max,
                min: values?.price?.min,
            },
            showMap: showMap === true ? true : undefined,
            ofiTime,
            sorting,
            group: values.group ? values.group : group,
            // group,
            acceleratePreviewAgentId,
            ref,
            page,
            pageSize,
        };
    };

    const onChange = (changedValues, allValues, searchFilters) => {
        if (
            (changedValues.keyword && changedValues.keyword.length > 0) ||
            changedValues.bed ||
            changedValues.price ||
            changedValues.bathroom ||
            changedValues.landArea ||
            changedValues.category ||
            changedValues.features ||
            changedValues.isIncludeSurrounding !== undefined ||
            changedValues.isIncludeOffMarket !== undefined ||
            changedValues.parking ||
            changedValues.group ||
            changedValues.isNewConstruction
        ) {
            const options = buildSearchOptions(allValues, searchFilters);
            changeUrl({
                ...options,
                page: 1,
            });
        }
    };

    const handleSearch = useCallback(
        debounce((changedValues, allValues, searchFilters) => onChange(changedValues, allValues, searchFilters), 1000),
        []
    );

    const changeSearchUrl = (formValues, searchOptionsObj) => {
        const options = buildSearchOptions(formValues, searchOptionsObj);
        changeUrl(options);
    };

    return (
        <div className={styles.SearchPage}>
            <PublicLayout showFooter={false}>
                <PropertySearchSeoHeader searchOptions={searchOptions} />
                <div className={styles.contentWrapper}>
                    {/* <PublicHeaderBreadcrumbs arrayOfLinks={routes} /> */}
                    <div className={styles.content}>
                        <div className={styles.searchFilter}>
                            {/* <ScreenWidthValueProvider xs sm md>
                                {matches =>
                                    matches ? ( */}
                            <Media lessThan="lg">
                                <Affix offsetTop={0}>
                                    <div>
                                        <PropertyAdvancedSearchMobile
                                            isSearch
                                            changeUrl={changeUrl}
                                            searchOptions={searchOptions}
                                            onChange={handleSearch}
                                            changeSearchUrl={changeSearchUrl}
                                            onChangeSorting={onChangeSorting}
                                            onChangeOfi={onChangeOfi}
                                        />
                                    </div>
                                </Affix>
                            </Media>
                            <Media greaterThanOrEqual="lg">
                                <PropertyAdvancedSearch
                                    className="px-12"
                                    isSearch
                                    searchOptions={searchOptions}
                                    onChange={handleSearch}
                                    changeSearchUrl={changeSearchUrl}
                                    onChangeSorting={onChangeSorting}
                                    onChangeOfi={onChangeOfi}
                                />
                            </Media>
                        </div>
                        <div className={styles.searchResultsWrapper}>
                            <PropertySearchResults
                                searchOptions={searchOptions}
                                changeSearchUrl={changeSearchUrl}
                                onChangeSorting={onChangeSorting}
                                onChangeOfi={onChangeOfi}
                                onChangePagination={onChangePagination}
                                isFromSearch
                                pagePath={location}
                                viewerConsumerId={viewerConsumerId}
                            />
                        </div>
                    </div>
                </div>
            </PublicLayout>
        </div>
    );
};

PropertySearchPage.propTypes = {
    changeUrl: PropTypes.func,
    searchOptions: PropTypes.shape({
        keywords: PropTypes.arrayOf(PropTypes.string),
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        bed: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        price: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        toilet: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        parking: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        landArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        floorArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        mapLocation: PropTypes.objectOf({
            radius: PropTypes.number,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        locations: PropTypes.arrayOf(PropTypes.string),
        category: PropTypes.arrayOf(PropTypes.string),
        features: PropTypes.arrayOf(PropTypes.string),
        locationsData: PropTypes.any,
        isIncludeSurrounding: PropTypes.bool,
        isIncludeOffMarket: PropTypes.bool,
        sorting: PropTypes.string,
        acceleratePreviewAgentId: PropTypes.string,
        group: PropTypes.string,
        ref: PropTypes.string,
        page: PropTypes.number,
        pageSize: PropTypes.number,
        isNewConstruction: PropTypes.bool,
    }),
    location: PropTypes.object,
};

PropertySearchPage.defaultProps = {
    changeUrl: () => {},
    searchOptions: {},
    location: {},
};

const PropertySearchPageComponent = (props) => (
    <SearchPageQueryParameterParser {...props}>
        {({ searchOptions, changeUrl }) => (
            <LocationResolver locations={searchOptions.locations}>
                {(locations) => (
                    <PropertySearchPage
                        searchOptions={{
                            ...searchOptions,
                            locationsData: locations || [],
                        }}
                        changeUrl={changeUrl}
                        {...props}
                    />
                )}
            </LocationResolver>
        )}
    </SearchPageQueryParameterParser>
);

export default PropertySearchPageComponent;
