import React from 'react';
import styles from './Skeleton.module.scss';

type Props = {
    // eslint-disable-next-line react/require-default-props
    className?: string;
    // eslint-disable-next-line react/require-default-props
    height?: string | number;
    // eslint-disable-next-line react/require-default-props
    width?: number | string;
    // eslint-disable-next-line react/require-default-props
    noAnimation?: boolean;
    // eslint-disable-next-line react/require-default-props
    children?: React.ReactNode;
};

const Skeleton = (props: Props) => {
    const { className = 'mt-3', height = 16, width = '100%', noAnimation = false, children } = props;

    return (
        <div
            className={`${styles.Skeleton} ${className} bg-white`}
            style={{
                height,
                width,
                animationName: noAnimation ? 'none' : 'placeHolderShimmer',
            }}
        >
            {children ?? null}
        </div>
    );
};

export default Skeleton;
