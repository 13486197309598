import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input, Row, Select } from 'antd';
import FacebookLoginButton from '~/components/FacebookLoginButton';
import { IsExistConsumer } from './Operations.graphql';

import phoneCodes from '~/constants/phoneCodes.json';
import GoogleLoginButton from '~/components/GoogleLoginButton';

const ConsumerCreateAccountWithSocial = memo(props => {
    const {
        form: { getFieldDecorator, validateFields, getFieldValue, setFieldsValue },
        onHandleSubmit,
        loading,
        onClickSignIn,
    } = props;

    const [socialAccountDetail, setSocialAccountDetail] = useState({});
    const client = useApolloClient();

    const isExistConsumer = email => {
        return client.query({
            query: IsExistConsumer,
            variables: { email },
            fetchPolicy: 'network-only',
        });
    };
    const handleSubmit = e => {
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                const { confirmPassword, ...rest } = values;
                onHandleSubmit({
                    ...socialAccountDetail,
                    ...rest,
                });
            }
        });
    };

    const handleConfirmPassword = (rule, value, callback) => {
        if (value && value !== getFieldValue('password')) {
            callback('Password mismatch');
        }
        callback();
    };

    const handleCheckExistEmail = (rule, value, callback) => {
        isExistConsumer(value).then(({ data }) => {
            if (data.isExistConsumer === true) {
                callback('Email already exists');
            }
            callback();
        });
    };

    const prefixSelector = getFieldDecorator('phoneCountryCode', { initialValue: '61' })(
        <Select style={{ width: 90 }}>
            {phoneCodes.map(value => (
                <Select.Option value={value} key={value}>
                    +{value}
                </Select.Option>
            ))}
        </Select>
    );

    const onSuccessLoginFb = data => {
        const { profile } = data || {};

        const { id: fbId, first_name: firstName, last_name: lastName, email, picture } = profile || {};

        setSocialAccountDetail({
            fbId,
            fbProfileUrl: picture?.data?.url ?? null,
        });

        setFieldsValue(
            {
                firstName,
                lastName,
                email,
            },
            () => {
                validateFields(['email']);
            }
        );
    };

    const onSuccessLoginGoogle = data => {
        const { profileObj } = data || {};
        const { googleId, imageUrl: googleProfileUrl, givenName: firstName, familyName: lastName, email } =
            profileObj || {};

        setSocialAccountDetail({
            googleId,
            googleProfileUrl,
        });

        setFieldsValue(
            {
                firstName,
                lastName,
                email,
            },
            () => {
                validateFields(['email']);
            }
        );
    };
    return (
        <>
            <div className="flex justify-center pt-6 pb-4">
                <FacebookLoginButton className="mx-2" onSuccess={onSuccessLoginFb} />
                <GoogleLoginButton className="mx-2" onSuccess={onSuccessLoginGoogle} />
            </div>

            <div className="flex justify-center py-0">
                <div className="max-w-md w-full">
                    <Divider orientation="center">or</Divider>
                </div>
            </div>
            <div className="flex flex-col justify-between w-full items-center">
                <Form onSubmit={handleSubmit} className="max-w-sm w-full">
                    <Row justify="center" gutter={12}>
                        <Col sm={24} md={12}>
                            <p className="font-medium  mb-1 text-xs">First Name</p>
                            <Form.Item className=" ">
                                {getFieldDecorator('firstName', {
                                    initialValue: [],
                                    rules: [
                                        {
                                            required: true,
                                            message: 'First Name is required!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="John"
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={12}>
                            <p className="font-medium  mb-1 text-xs">Last Name</p>
                            <Form.Item className=" ">
                                {getFieldDecorator('lastName', {
                                    initialValue: [],
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Last Name is required!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Smitch"
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={24}>
                            <p className="font-medium  mb-1 text-xs">Email</p>
                            <Form.Item className=" " hasFeedback>
                                {getFieldDecorator('email', {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Email is required!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Invalid email!',
                                        },
                                        { validator: handleCheckExistEmail },
                                    ],
                                })(
                                    <Input
                                        placeholder="example@domain.com"
                                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={24}>
                            <p className="font-medium  mb-1 text-xs">Phone</p>
                            <Form.Item className=" " hasFeedback>
                                {getFieldDecorator('phoneNumber', {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Phone Number is required!',
                                        },
                                    ],
                                })(<Input addonBefore={prefixSelector} placeholder="(07) 4521 3379" />)}
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={12}>
                            <p className="font-medium  mb-1 text-xs">Password</p>
                            <Form.Item className=" " hasFeedback>
                                {getFieldDecorator('password', {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Password is required!',
                                        },
                                    ],
                                })(
                                    <Input.Password
                                        placeholder="Your password"
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={12}>
                            <p className="font-medium  mb-1 text-xs">Confirm Password</p>
                            <Form.Item className=" " hasFeedback>
                                {getFieldDecorator('confirmPassword', {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Confirm Password is required!',
                                        },
                                        { validator: handleConfirmPassword },
                                    ],
                                })(
                                    <Input.Password
                                        placeholder="Re-type password"
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="py-3 w-full flex flex-col justify-center items-center pt-8">
                    <Button
                        onClick={handleSubmit}
                        type="primary"
                        size="large"
                        htmlType="submit"
                        className="max-w-xs"
                        block
                        loading={loading}
                    >
                        NEXT
                    </Button>
                    <div className="text-center text-gray-700 flex items-center pt-4">
                        Have an account?
                        <Button type="link" className="text-gray-700 " onClick={onClickSignIn}>
                            <span className="underline font-medium">Sign In</span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
});

ConsumerCreateAccountWithSocial.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func,
        getFieldValue: PropTypes.func,
        validateFields: PropTypes.func,
    }),
    onHandleSubmit: PropTypes.func,
    onClickSignIn: PropTypes.func,
    loading: PropTypes.bool,
};

ConsumerCreateAccountWithSocial.defaultProps = {
    form: {},
    onHandleSubmit: () => {},
    onClickSignIn: () => {},
    loading: false,
};

export default Form.create()(ConsumerCreateAccountWithSocial);
