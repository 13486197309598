import React from 'react';
import { withRouter } from 'next/router';
import { NextSeo } from 'next-seo';

const PropertySearchSeoHeader = props => {
    const { searchOptions, router } = props;

    const { locations } = searchOptions || {};

    const firstLocation = locations && locations.length > 0 ? locations[0] : null;

    const title = `${firstLocation ? `Search ${firstLocation} | ` : 'Search Properties | '}Realty.com.au`;
    const metaDescription = `${
        firstLocation ? `Search ${firstLocation}. ` : ''
    }Search residential for sale, rent/lease and sold around ${
        firstLocation ? `${firstLocation}` : 'the'
    } area in realty.`;

    return (
        <NextSeo
            title={title}
            description={metaDescription}
            canonical={`https://www.realty.com.au${router.asPath}`}
            openGraph={{
                type: 'place',
                title,
                description: metaDescription,
                locale: 'en_US',
                url: `https://www.realty.com.au${router.asPath}`,
            }}
        />
    );
};

export default withRouter(PropertySearchSeoHeader);
