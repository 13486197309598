import { useMutation } from '@apollo/client';
import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Modal } from 'antd';
import { connect } from 'react-redux';
import Router from 'next/router';
import includes from 'lodash/includes';
import ConsumerOffMarketPackage from '~/components/ConsumerOffMarketPackage';
import ConsumerOffMarketSubPayment from '~/components/ConsumerOffMarketSubPayment';
import {
    CreateConsumerOffMarketSubWithConsumerSignup,
    CreateConsumerOffMarketSubWithExistingConsumer,
} from './Operations.graphql';
import styles from './PublicCreateOffMarketSubModal.module.scss';
import ConsumerAccountLoginWithSocial from '~/components/ConsumerAccountLoginWithSocial';
import ConsumerCreateAccountWithSocial from '~/components/ConsumerCreateAccountWithSocial';
import LocationIcon from './location-icon.svg';
import { loginUser } from '~/actions';

const CreateOffMarketSubDoneStep = memo(({ onOk }) => {
    return (
        <>
            <div className="flex justify-center py-4">
                <div className="w-40">
                    <LocationIcon />
                </div>
            </div>
            <h1 className="py-3 text-center text-xl font-semibold">
                Thank you for subscribing to our Premium
                <br />
                off-market listing package!
            </h1>
            <p className="mt-4 text-center text-base text-gray-700">
                Your receipt is on its way to your email.
                <br />
                Enjoy browsing our off-market and sold property listings.
            </p>
            <div className="py-3 pt-8 text-center">
                <Button onClick={onOk} type="primary" size="large" className="max-w-xs" block>
                    DONE
                </Button>
            </div>
        </>
    );
});

CreateOffMarketSubDoneStep.propTypes = { onOk: PropTypes.func };

CreateOffMarketSubDoneStep.defaultProps = { onOk: () => {} };

const PublicCreateOffMarketSubModal = memo((props) => {
    const { onClose, visible, onLoginUser, authUserId, headingText } = props;
    const [currentStepNumber, setCurrentStepNumber] = useState(1);
    const [offMarketPlan, setOffMarketPlan] = useState('OFF_MARKET_PREMIUM_ALERTS');
    const [createAccountDetails, setCreateAccountDetails] = useState({});
    const [existingAccountDetails, setExistingAccountDetails] = useState({});
    const [isFromExistingAccount, setIsFromExistingAccount] = useState(false);
    const [isLoadingSaveButton, setIsLoadingSaveButton] = useState(false);
    const [stepOne, setStepOne] = useState('signin');
    const [createConsumerOffMarketSubWithConsumerSignup] = useMutation(CreateConsumerOffMarketSubWithConsumerSignup);
    const [createConsumerOffMarketSubWithExistingConsumer] = useMutation(
        CreateConsumerOffMarketSubWithExistingConsumer
    );

    useEffect(() => {
        if (authUserId) {
            setExistingAccountDetails({ consumerId: authUserId });
            setCreateAccountDetails({});
            setIsFromExistingAccount(true);
            setCurrentStepNumber(2);
        } else {
            setCreateAccountDetails({});
            setExistingAccountDetails({});
            setIsFromExistingAccount(false);
            setCurrentStepNumber(1);
            setStepOne('signin');
        }
    }, [authUserId, visible]);

    const handleCreateNewOffMarketSubWithSignUp = (details) => {
        setIsLoadingSaveButton(true);

        createConsumerOffMarketSubWithConsumerSignup({ variables: { input: details } })
            .then(() => {
                setCurrentStepNumber(4);
            })
            .catch((error) => {
                const errors = error.graphQLErrors || [];
                const errorCode = errors.length > 0 ? errors[0].extensions.code : null;

                message.warning('Something went wrong', 2);
                console.log(errorCode);
            })
            .finally(() => {
                setIsLoadingSaveButton(false);
            });
    };

    const handleCreateConsumerOffMarketSubWithExistingConsumer = (details) => {
        setIsLoadingSaveButton(true);

        createConsumerOffMarketSubWithExistingConsumer({ variables: { input: details } })
            .then(() => {
                setCurrentStepNumber(4);
            })
            .catch((error) => {
                const errors = error.graphQLErrors || [];
                const errorCode = errors.length > 0 ? errors[0].extensions.code : null;

                message.warning('Something went wrong', 2);
                console.log(errorCode);
            })
            .finally(() => {
                setIsLoadingSaveButton(false);
            });
    };

    const numberOfSteps = 3;

    const handleSubmitCreateAccount = (details) => {
        setCreateAccountDetails(details);
        setIsFromExistingAccount(false);
        setExistingAccountDetails({});
        setCurrentStepNumber(2);
    };

    const onClickSignIn = () => {
        setCreateAccountDetails({});
        setStepOne('signin');
    };

    const onClickRegister = () => {
        setCreateAccountDetails({});
        setExistingAccountDetails({});
        setStepOne('register');
    };

    const handleSubmitLogInAccount = (consumerDetails) => {
        const { router } = Router;

        if (consumerDetails.user.hasOffMarketSubscription) {
            const { accessToken, expiresIn, refreshToken, user, type } = consumerDetails;

            onClose();

            onLoginUser({
                accessToken,
                expiresIn,
                refreshToken,
                user,
                type,
            });

            if (includes(router.asPath, '?')) {
                Router.replace(`${router.asPath}&isIncludeOffMarket=true`);
            } else {
                Router.replace(`${router.asPath}?isIncludeOffMarket=true`);
            }

            // reset state
            setCreateAccountDetails({});
            setExistingAccountDetails({});
            setIsFromExistingAccount(false);
            setCurrentStepNumber(1);
        } else {
            setExistingAccountDetails(consumerDetails);
            setIsFromExistingAccount(true);
            setCreateAccountDetails({});
            setCurrentStepNumber(2);
        }
    };

    const handleSubmitPayment = (stripeTokenId) => {
        if (isFromExistingAccount) {
            const { user } = existingAccountDetails;
            handleCreateConsumerOffMarketSubWithExistingConsumer({
                stripeTokenId,
                offMarketPlan,
                consumerId: authUserId || user.id,
            });
        } else {
            handleCreateNewOffMarketSubWithSignUp({
                stripeTokenId,
                offMarketPlan,
                ...createAccountDetails,
            });
        }
    };

    const handleSubmitPackage = (offMarketPackage) => {
        setOffMarketPlan(offMarketPackage);
        setCurrentStepNumber(3);
    };

    const handleOnClickDone = () => {
        const { router } = Router;
        onClose();

        if (isFromExistingAccount) {
            const { accessToken, expiresIn, refreshToken, user, type } = existingAccountDetails;
            if (!authUserId) {
                onLoginUser({
                    accessToken,
                    expiresIn,
                    refreshToken,
                    user,
                    type,
                });
            }

            if (includes(router.asPath, '?')) {
                Router.replace(`${router.asPath}&isIncludeOffMarket=true`);
            } else {
                Router.replace(`${router.asPath}?isIncludeOffMarket=true`);
            }
        }

        // reset state
        setCreateAccountDetails({});
        setExistingAccountDetails({});
        setIsFromExistingAccount(false);
        setCurrentStepNumber(1);
    };

    return (
        <Modal
            title={null}
            visible={visible}
            destroyOnClose
            onCancel={onClose}
            maskClosable={false}
            width={600}
            className={styles.PublicCreateOffMarketSubModal}
            footer={null}
            centered
        >
            <div className="p-2">
                {currentStepNumber === 1 ? (
                    <>
                        {stepOne === 'signin' && (
                            <>
                                <h1 className=" pb-2 text-center text-xl font-normal text-gray-700">Account Log In</h1>
                                <p className=" text-center font-light text-gray-700">
                                    {headingText || 'Sign in with your social media account'}
                                    <br />
                                    or email address
                                </p>

                                <ConsumerAccountLoginWithSocial
                                    onHandleSubmit={handleSubmitLogInAccount}
                                    loading={isLoadingSaveButton}
                                    onClickRegister={onClickRegister}
                                />
                            </>
                        )}
                        {stepOne === 'register' && (
                            <>
                                <h1 className=" pb-2 text-center text-xl font-normal text-gray-700">
                                    Create an Account
                                </h1>
                                <p className=" text-center font-light text-gray-700">
                                    Signup with your social media account
                                    <br />
                                    or email address
                                </p>
                                <ConsumerCreateAccountWithSocial
                                    onHandleSubmit={handleSubmitCreateAccount}
                                    loading={isLoadingSaveButton}
                                    onClickSignIn={onClickSignIn}
                                />
                            </>
                        )}
                    </>
                ) : null}
                {currentStepNumber === 2 ? (
                    <ConsumerOffMarketPackage
                        onHandleSubmit={handleSubmitPackage}
                        offMarketChosenPlan={offMarketPlan}
                    />
                ) : null}
                {currentStepNumber === 3 ? (
                    <ConsumerOffMarketSubPayment
                        onHandleSubmit={handleSubmitPayment}
                        loading={isLoadingSaveButton}
                        offMarketPlan={offMarketPlan}
                    />
                ) : null}
                {currentStepNumber === 4 ? <CreateOffMarketSubDoneStep onOk={handleOnClickDone} /> : null}
                <div className="mt-6 text-xs text-gray-700">
                    {currentStepNumber === 1 ? (
                        <div className="text-center ">
                            Step {currentStepNumber} of {numberOfSteps}
                        </div>
                    ) : null}
                    {currentStepNumber >= 2 && currentStepNumber <= 3 ? (
                        <div className="flex items-center justify-between">
                            <Button
                                onClick={() => setCurrentStepNumber(currentStepNumber - 1)}
                                type="link"
                                className="text-xs text-gray-700"
                            >
                                Back
                            </Button>
                            Step {currentStepNumber} of {numberOfSteps}
                        </div>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
});

PublicCreateOffMarketSubModal.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func,
        getFieldValue: PropTypes.func,
        validateFields: PropTypes.func,
    }),
    headingText: PropTypes.string,
    onClose: PropTypes.func,
    onLoginUser: PropTypes.func,
    visible: PropTypes.bool,
    authUserId: PropTypes.string,
};

PublicCreateOffMarketSubModal.defaultProps = {
    form: {},
    onClose: () => {},
    onLoginUser: () => {},
    visible: false,
    authUserId: null,
    headingText: 'Sign in with your social media account',
};

export default connect(
    (state) => ({}),
    (dispatch) => ({
        onLoginUser(accessToken) {
            dispatch(loginUser(accessToken));
        },
    })
)(PublicCreateOffMarketSubModal);
