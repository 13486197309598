import React from 'react';
import PropTypes from 'prop-types';
import FilterRange from '~/components/FilterRange';
import { landAreaRange } from '~/constants/range';

const LandAreaFilterRange = (props) => {
    return (
        <FilterRange
            range={landAreaRange}
            suffix={
                <>
                    &nbsp;m<sup>2</sup>
                </>
            }
            {...props}
        />
    );
};

LandAreaFilterRange.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
};

LandAreaFilterRange.defaultProps = {
    min: 0,
    max: 900000,
    onChange: () => {},
};

export default LandAreaFilterRange;
