import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import dayjs from 'dayjs';
import { QuestionCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Affix } from 'antd';
import PropertySearchFeaturedAgentCard from '~/components/PropertySearchFeaturedAgentCard';
import PropertySearchResultPaginated from '~/components/PropertySearchResultPaginated';
import ScreenWidthValueProvider from '~/components/ScreenWidthValueProvider';
import PropertySearchBrokerCard from '~/components/PropertySearchBrokerCard';
import PropertyOfiTime from '~/components/PropertyOfiTime';
import RealtyDiscoverBanner from '~/components/RealtyDiscoverBanner';
import FeaturedSearchResultAgent from '~/components/FeaturedSearchResultAgent';
import ofiGraphic from './OFIgraphic.png';

const PropertySearchResultInspection = memo(
    ({
        loading,
        buildQueryVariables,
        onChangeOfi,
        propertySearch,
        featuredAgentData,
        featuredBrokerData,
        searchOptions,
        onChangePagination,
        isFromSearch,
        hideCloseButton,
        totalCount,
        hidePropertiesList,
        propertySearchVariables,
    }) => {
        const [isScrolled, setIsScrolled] = useState(false);
        const [loadingMore, setLoadingMore] = useState(false);

        const { featuredAgentByLocation, randomAgentByLocation, previewFeaturedAgentByLocation } =
            featuredAgentData || {};
        const { acceleratePreviewAgentId, ref, locations, locationsData, ofiTime } = searchOptions || {};
        const { featuredBrokerByLocation } = featuredBrokerData || {};
        const hasFeaturedBroker = featuredBrokerByLocation?.broker ?? false;
        const hasPreviewFeaturedAgent = previewFeaturedAgentByLocation?.agent ?? false;
        const hasFeaturedAgent = featuredAgentByLocation?.agent ?? false;
        const hasRandomAgent = randomAgentByLocation?.agent ?? false;

        const showLoadingMore = propertySearch?.pageInfo?.hasNextPage ?? false;
        const dataSource = propertySearch?.edges ?? [];

        return (
            <div className="PropertySearchResultInspection">
                <div className="flex">
                    <div className="advance-search-result pt-51  w-full sm:pb-2">
                        {hasFeaturedAgent && (
                            <>
                                <div
                                    className={`sticky1 featured-agent-container z-30 bg-white pt-5 ${
                                        isScrolled ? 'scrolled' : ''
                                    }`}
                                >
                                    <div
                                        className={`${
                                            hasPreviewFeaturedAgent
                                                ? 'rounded-lg border-4 border-dashed border-red-400 p-1 text-right'
                                                : ''
                                        }`}
                                    >
                                        {hasPreviewFeaturedAgent && (
                                            <Alert
                                                message={`Sorry, ${featuredAgentByLocation.location.suburb} is already taken!, Search for another suburb above`}
                                                type="error"
                                                showIcon
                                            />
                                        )}
                                        <PropertySearchFeaturedAgentCard
                                            agent={featuredAgentByLocation.agent}
                                            location={featuredAgentByLocation.location}
                                            agency={
                                                featuredAgentByLocation.agent && featuredAgentByLocation.agent.agency
                                                    ? featuredAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={featuredAgentByLocation.agent.reviewRating}
                                            propertiesConnection={featuredAgentByLocation.agent.propertiesConnection}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </>
                        )}

                        {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                            <>
                                <div className="sticky1 featured-agent-container z-30 bg-white pt-5">
                                    <div>
                                        <PropertySearchFeaturedAgentCard
                                            agent={randomAgentByLocation.agent}
                                            location={randomAgentByLocation.location}
                                            agency={
                                                randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                                    ? randomAgentByLocation.agent.agency
                                                    : null
                                            }
                                            mini={isScrolled}
                                            reviews={randomAgentByLocation.agent.reviewRating}
                                            propertiesConnection={randomAgentByLocation.agent.propertiesConnection}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </>
                        )}

                        {!hasFeaturedAgent && hasRandomAgent && hasPreviewFeaturedAgent && (
                            <>
                                <div className="sticky1 z-30 bg-white px-2 pt-5">
                                    <div className="rounded-lg border-4 border-dashed border-red-400 p-1 text-right">
                                        <div className="absolute -mt-4 ml-6 bg-white px-2 font-bold text-red-400">
                                            PREVIEW ONLY
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <Link href="/accelerate" as="/accelerate">
                                                <a
                                                    target="__blank"
                                                    className="ml-4 w-1/2 text-blue-600 hover:text-blue-800"
                                                >
                                                    <QuestionCircleOutlined className="mr-2" />
                                                    Learn More
                                                </a>
                                            </Link>

                                            <Button
                                                type="primary"
                                                icon={<ShoppingCartOutlined />}
                                                className="m-1 mt-0 rounded border-green-500 bg-green-500 hover:border-green-400 hover:bg-green-400"
                                            >
                                                <Link
                                                    href={{
                                                        pathname: `/accelerate/buy/[agentId]`,
                                                        query: {
                                                            agentId: acceleratePreviewAgentId,
                                                            locationId: previewFeaturedAgentByLocation.location.id,
                                                            ref: ref || '',
                                                        },
                                                    }}
                                                >
                                                    <a target="__blank" className="text-white hover:text-white">
                                                        Buy Now
                                                    </a>
                                                </Link>
                                            </Button>
                                        </div>
                                        <PropertySearchFeaturedAgentCard
                                            agent={previewFeaturedAgentByLocation.agent}
                                            location={previewFeaturedAgentByLocation.location}
                                            agency={
                                                previewFeaturedAgentByLocation.agent &&
                                                previewFeaturedAgentByLocation.agent.agency
                                                    ? previewFeaturedAgentByLocation.agent.agency
                                                    : null
                                            }
                                            reviews={previewFeaturedAgentByLocation.agent.reviewRating}
                                            propertiesConnection={
                                                previewFeaturedAgentByLocation.agent.propertiesConnection
                                            }
                                            showBuyButton
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </>
                        )}

                        {ofiTime && <PropertyOfiTime searchOptions={searchOptions} onChangeOfi={onChangeOfi} />}
                        <ScreenWidthValueProvider xs={1} sm={2} md={3} lg={2} xl={2} xxl={3}>
                            {(subMatch) => (
                                <PropertySearchResultPaginated
                                    maxItemsPerRow={subMatch}
                                    resultList={dataSource}
                                    searchOptions={searchOptions}
                                    searchFilters={buildQueryVariables(searchOptions)}
                                    pageSize={24}
                                    // loadMore={handleLoadMore}
                                    loading={loading}
                                    showLoadingMore={showLoadingMore}
                                    isLoadingMore={loadingMore}
                                    isFromSearch={isFromSearch}
                                    totalCount={totalCount}
                                    onChangePagination={onChangePagination}
                                    featuredBroker={hasFeaturedBroker ? featuredBrokerByLocation : null}
                                    showBrokerOnMobile={subMatch === 1}
                                    propertySearchVariables={propertySearchVariables}
                                    hidePropertiesList={hidePropertiesList}
                                />
                            )}
                        </ScreenWidthValueProvider>
                        {dataSource.length === 0 && (
                            <div className="relative mt-2 rounded bg-white p-6">
                                <div className="no-inspection flex-none items-center justify-between sm:flex">
                                    <div className="m-auto max-w-lg pb-6 sm:pb-0">
                                        <img src={ofiGraphic} alt="no inspection" />
                                    </div>
                                    <div className="pl-6">
                                        <p className="text-base">
                                            There are no properties matching your criteria that are open for inspection
                                            on {dayjs(ofiTime).format('dddd D MMM YYYY')}.
                                        </p>
                                        <p className="mt-4 text-sm">
                                            View open times for a different day of the week by selecting another day
                                            above.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="advance-search-rightside-content absolute right-0 top-0 hidden h-full w-2/5 pl-2 pt-24 md:pl-8 lg:block">
                    <div className="mt-8">
                        {hasFeaturedBroker && (
                            <div className="mb-8 max-w-xs">
                                <PropertySearchBrokerCard
                                    firstName={featuredBrokerByLocation.broker.firstName}
                                    lastName={featuredBrokerByLocation.broker.lastName}
                                    suburbName={featuredBrokerByLocation.location.suburb}
                                    profilePicture={featuredBrokerByLocation.broker.profilePicture}
                                    brokerId={featuredBrokerByLocation.broker.id}
                                    logoUrl={featuredBrokerByLocation.broker.profileLogoUrl}
                                    headerPadding="px-4 py-5"
                                    slugUrl={featuredBrokerByLocation.broker.slugUrl}
                                    logoShape={featuredBrokerByLocation.broker.logoShape}
                                    hideCloseButton={hideCloseButton}
                                />
                            </div>
                        )}
                        <div className="max-w-xs">
                            <Affix offsetTop={180}>
                                {hasFeaturedAgent && (
                                    <div className="pb-10">
                                        <FeaturedSearchResultAgent
                                            agent={featuredAgentByLocation.agent}
                                            location={featuredAgentByLocation.location}
                                            agency={
                                                featuredAgentByLocation.agent && featuredAgentByLocation.agent.agency
                                                    ? featuredAgentByLocation.agent.agency
                                                    : null
                                            }
                                        />
                                    </div>
                                )}
                                {!hasFeaturedAgent && !hasPreviewFeaturedAgent && hasRandomAgent && (
                                    <div className="pb-10">
                                        <FeaturedSearchResultAgent
                                            agent={randomAgentByLocation.agent}
                                            location={randomAgentByLocation.location}
                                            agency={
                                                randomAgentByLocation.agent && randomAgentByLocation.agent.agency
                                                    ? randomAgentByLocation.agent.agency
                                                    : null
                                            }
                                        />
                                    </div>
                                )}
                                <RealtyDiscoverBanner />
                            </Affix>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
PropertySearchResultInspection.propTypes = {
    onChangePagination: PropTypes.func,
    loading: PropTypes.bool,
    hideCloseButton: PropTypes.string,
    totalCount: PropTypes.number,
};

PropertySearchResultInspection.defaultProps = {
    onChangePagination: () => {},
    loading: false,
    hideCloseButton: 'hidden',
    totalCount: 0,
};

export default PropertySearchResultInspection;
