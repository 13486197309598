import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import FilterRange from '~/components/FilterRange';
import { buyRange, rentRange } from '~/constants/range';

const ModalBuyPriceFilterRangeFormItem = forwardRef(({ type, value, onChange, ...rest }, ref) => {
    const isRent = `${type}`.toLowerCase() === 'rent';

    const rentFilter = isRent
        ? {
              min: 0,
              max: 5000,
              step: 5,
              range: rentRange,
          }
        : {};

    return (
        <div className="flex flex-col justify-center" ref={ref}>
            <div className="text-base font-medium">Price</div>

            <div className="mb-3 ">
                {type === 'RENT' ? (
                    <FilterRange
                        format="price"
                        value={value}
                        onChange={onChange}
                        {...rentFilter}
                        {...rest}
                        selectOptions={{ style: { width: 110 } }}
                    />
                ) : (
                    <FilterRange
                        format="price"
                        value={value}
                        onChange={onChange}
                        range={buyRange}
                        {...rest}
                        selectOptions={{ style: { width: 110 } }}
                    />
                )}
            </div>
        </div>
    );
});

ModalBuyPriceFilterRangeFormItem.propTypes = {
    onChange: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.object,
};

ModalBuyPriceFilterRangeFormItem.defaultProps = {
    onChange: () => {},
    type: 'BUY',
};
export default ModalBuyPriceFilterRangeFormItem;
