import React, { useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { Button, Divider, Form } from 'antd';
import ParkingNumberSelect from '~/components/SearchFilterPopover/ParkingNumberSelect';
import BathroomNumberSelect from '~/components/SearchFilterPopover/BathroomNumberSelect';
import LandAreaFilterRange from '~/components/LandAreaFilterRange';
import PropertyFeaturesFilter from '~/components/PropertyFeaturesFilter';
import NewOrEstablishedSelect from '~/components/SearchFilterPopover/NewOrEstablishedSelect';
import PopoverCategory from './PopoverCategory';

const checkHasParking = (parking) => parking && !(parking.min === undefined && parking.max === undefined);
const checkHasCategories = (category) => category && category.length > 0 && !category.includes('All');
const checkHasBathroom = (bathroom) => bathroom && !(bathroom.min === undefined && bathroom.max === undefined);
const SearchFilterModal = ({ data, isShowFilterModal, onOk, onClose, onClosePopover }) => {
    const [form] = Form.useForm();
    const { landArea, parking, category, features, bathroom, isNewConstruction } = data || {};
    const [hasParking, setHasParking] = useState(checkHasParking(parking));
    const [hasCategories, setHasCategories] = useState(checkHasCategories(category));
    const [hasBathroom, setHasBathroom] = useState(checkHasBathroom(bathroom));

    const { getFieldsValue, setFieldsValue } = form;
    // const handleCancel = () => {
    //     onClose();
    // };

    // const clearPropertyFeatures = () => {
    //     setFieldsValue({
    //         parking: {
    //             min: undefined,
    //             max: undefined,
    //         },
    //     });
    // };
    const clearCategories = () => {
        setFieldsValue({ category: ['All'] });
    };

    const onChangeParking = (value) => {
        setFieldsValue({ parking: { min: value } });
    };

    const onChangeBathroom = (value) => {
        setFieldsValue({ bathroom: { min: value } });
    };

    const onChangeConstruction = (value) => {
        setFieldsValue({ isNewConstruction: value });
    };

    const onClickApply = (e) => {
        e.preventDefault();
        onOk(getFieldsValue(['parking', 'bathroom', 'landArea', 'category', 'features', 'isNewConstruction']));
        onClosePopover();
    };

    // if (!isShowFilterModal) return '';

    return (
        // <Modal
        //     title="More Filters"
        //     width={700}
        //     visible={isShowFilterModal}
        //     centered
        //     onCancel={handleCancel}
        //     okText="Apply Filters"
        //     okButtonProps={{ className: 'rounded' }}
        //     cancelButtonProps={{ className: 'rounded' }}
        //     bodyStyle={{
        //         height: '86vh',
        //         overflow: 'auto',
        //     }}
        //     onOk={onClickApply}
        <div style={{ width: 700 }} className="h-[70vh] overflow-y-scroll">
            <Form
                form={form}
                initialValues={{
                    category: category || ['All'],
                    parking,
                    bathroom,
                    landArea,
                    features: features || null,
                    isNewConstruction,
                }}
                onValuesChange={(changedValues, allValues) => {
                    const { parking, category, bathroom } = allValues || {};
                    setHasParking(checkHasParking(parking));
                    setHasCategories(checkHasCategories(category));
                    setHasBathroom(checkHasParking(bathroom));
                }}
            >
                <div className="flex items-center justify-between ">
                    <span className="inline-block text-base font-medium">Categories</span>
                    <Button disabled={!hasCategories} type="text" onClick={clearCategories}>
                        Clear
                    </Button>
                </div>
                <Form.Item name="category" className="form-item search-option mt-6 block w-auto">
                    <PopoverCategory />
                </Form.Item>
                <Divider className="block sm:hidden" />

                <div className="flex items-center justify-end">
                    {/* <span className="inline-block lg:font-medium font-bold text-base">Features</span> */}
                    {/* <Button disabled={!hasParking} type="text" onClick={clearPropertyFeatures}>
                        Clear
                    </Button> */}
                </div>
                <div className="mb-5 flex items-center justify-between">
                    <div className="w-2/5">
                        <div className="text-sm">Bath</div>
                        <Form.Item name="bathroom" className="form-item search-option mt-6 block w-auto">
                            <BathroomNumberSelect onChange={onChangeBathroom} />
                        </Form.Item>
                    </div>
                    <div className="w-1/2">
                        <div className="text-sm">New or Established</div>
                        <Form.Item name="isNewConstruction" className="form-item search-option mt-6 block w-auto">
                            <NewOrEstablishedSelect currentValue={isNewConstruction} onChange={onChangeConstruction} />
                        </Form.Item>
                    </div>
                </div>
                <div className="flex items-center justify-between ">
                    <div className="w-2/5">
                        <div className="text-sm">Parking</div>
                        <Form.Item name="parking" className="form-item search-option mt-6 block w-auto">
                            <ParkingNumberSelect onChange={onChangeParking} />
                        </Form.Item>
                    </div>
                    <div className="w-1/2">
                        <div className="text-sm">Land Area</div>
                        <Form.Item name="landArea" className="form-item search-option mt-6 block w-auto">
                            <LandAreaFilterRange min={0} selectOptions={{ style: { width: 110 } }} />
                        </Form.Item>
                    </div>
                </div>
                {/* <Divider className="my-0" />
                <div className="flex justify-between items-center ">

                </div>
                <Divider /> */}
                <div>
                    <Form.Item name="features">
                        <PropertyFeaturesFilter />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={onClickApply}
                            style={{ borderRadius: '0.25rem', fontSize: '12px' }}
                            className="text-xs"
                        >
                            Apply Filters
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

SearchFilterModal.propTypes = {
    data: shape({
        bed: object,
        bathroom: object,
        price: object,
        landArea: object,
        parking: object,
        features: arrayOf(string),
        category: arrayOf(string),
    }),
    isShowFilterModal: bool,
    onOk: func,
    onClose: func,
};

SearchFilterModal.defaultProps = {
    data: {
        bed: {},
        bathroom: {},
        price: {},
        landArea: {},
        parking: {},
        features: null,
        category: null,
    },
    isShowFilterModal: false,
    onClose: () => {},
    onOk: () => {},
};

export default SearchFilterModal;
