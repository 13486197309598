import React, { CSSProperties } from 'react';
import useToggle from '~/hooks/useToggle';
import clsx from 'clsx';
import Link from 'next/link';

type Props = {
    className?: string;
    style?: CSSProperties;
};

const PersonalCollectionStatement = (props: Props) => {
    const { className = '', style = { fontSize: '0.8em' } } = props;
    const [isShowFullStmt, toggleIsShowFullStmt] = useToggle(false);

    return (
        <div style={style} className={className}>
            <p>
                Please view our{' '}
                <button
                    className="cursor-pointer text-primary-500 underline"
                    onClick={() => {
                        toggleIsShowFullStmt();
                    }}
                    type="button"
                >
                    personal information collection statement
                </button>
            </p>
            <p
                className={clsx(
                    {
                        hidden: !isShowFullStmt,
                        block: isShowFullStmt,
                    },
                    'mt-3'
                )}
            >
                Your personal information and associated behavioural data related to search activities will be passed to
                the Agency, Broker and/or its authorised service provider to assist the Agency or Broker to contact you
                about your property or loan enquiry. They are required not to use your information for any other
                purpose. Our{' '}
                <Link href="/privacy">
                    <a className="text-primary-600 underline" target="_blank">
                        Privacy policy
                    </a>
                </Link>{' '}
                explains how we store personal information and how you may access, correct or complain about the
                handling of personal information.
            </p>
            <p className="mt-3">
                This form is only to be used for sending genuine email enquiries to the Agent/Agency or Broker. Realty
                Media Group Pty Ltd reserves its right to take any legal or other appropriate action in relation to
                misuse of this service.
            </p>
        </div>
    );
};

export default PersonalCollectionStatement;
