/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-return-assign */
import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Divider, Tag, Tooltip } from 'antd';
import { GoogleMap, MarkerClusterer, useGoogleMap, useJsApiLoader } from '@react-google-maps/api';
import isEmpty from 'lodash.isempty';
import AgentPropertyEnquiryModal from '~/components/AgentPropertyEnquiryModal';
import LoadingDots from '~/components/LoadingDots';
import MainFeatures from '~/components/MainFeatures';
import PropertyAddress from '~/components/PropertyAddress';
import PropertyCarousel from '~/components/PropertyCarousel';
import PropertyPriceParse from '~/components/PropertyPriceParse';
import { getColorShade } from '~/utils/colors';
import { libraries } from '~/constants/map';
import MapStyleSilver from '~/constants/mapStyle';
import { FEATURED_69, FEATURED_99, HIGHLIGHT_199, PREMIERE_499 } from '~/constants/property-ad-types';
import { EmitterContext, EventNames } from '~/context/emitter-context';
import PropertyMapMarkerWithInfoBox from '~/components/PropertyMapMarkerWithInfoBox';
import PRIMARY from '~/constants/colors';
import { getDefaultBounds, getMarkersBounds } from '~/utils/map';
import CreateHTMLMapMarker from './CreateHTMLMapMarker';
import styles from './PropertyMapWithMarkerClusterer.module.scss';

const PropertyMapWithMarkerClusterer = memo((props) => {
    const { featuredAgentId, markers, isFromSearch } = props;

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
        libraries,
        version: 'weekly',
    });

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return (
        <div
            className={styles['advance-search-map-container']}
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            {isLoaded ? (
                <GoogleMap
                    zoom={4}
                    mapContainerStyle={{ height: '100%' }}
                    center={{
                        lat: -25.1547443,
                        lng: 134.046491,
                    }}
                    options={{
                        mapTypeControlOptions: { position: window.google.maps.ControlPosition.LEFT_BOTTOM },
                        fullscreenControlOptions: { position: window.google.maps.ControlPosition.RIGHT_BOTTOM },
                        styles: MapStyleSilver,
                    }}
                >
                    <MapContent markers={markers} isFromSearch={isFromSearch} featuredAgentId={featuredAgentId} />
                </GoogleMap>
            ) : (
                <LoadingDots />
            )}
        </div>
    );
});

PropertyMapWithMarkerClusterer.propTypes = {
    markers: PropTypes.any,
    isFromSearch: PropTypes.bool,
    featuredAgentId: PropTypes.string,
};

PropertyMapWithMarkerClusterer.defaultProps = {
    markers: [],
    isFromSearch: false,
    featuredAgentId: null,
};

const MapContent = memo((props) => {
    const { markers, isFromSearch, featuredAgentId } = props;

    const { removeAllListeners, addListener } = useContext(EmitterContext);
    const [openedProperty, setOpenedProperty] = useState(null);
    const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
    const [markerGroup, setMarkerGroup] = useState(1);
    const map = useGoogleMap();

    const fitMarkersToMap = (markers) => {
        if (markers.length > 0) {
            if (markers.length === 1) {
                const marker = markers[0];
                map.setZoom(18);
                map.panTo({
                    lat: marker.lat,
                    lng: marker.lng,
                });
            } else {
                map.fitBounds(getMarkersBounds(markers));
            }
        } else {
            map.fitBounds(getDefaultBounds(), 0);
        }
    };

    useEffect(() => {
        if (map) {
            fitMarkersToMap(markers);
        }
    }, [markers]);

    useEffect(() => {
        addListener(EventNames.ON_PROPERTY_MOUSE_OVER, (propertyId) => {
            if (propertyId) {
                const property = markers.find((propertyItem) => propertyItem.id === propertyId);

                if (property) {
                    setHoveredMarkerId(propertyId);
                    setOpenedProperty(property);
                    setMarkerGroup(markerGroup + 1);
                    map.setZoom(15);
                    map.panTo({
                        lat: property.lat,
                        lng: property.lng,
                    });
                }
            }
        });

        return function cleanup() {
            removeAllListeners(EventNames.ON_PROPERTY_MOUSE_OVER);
        };
    });

    const isPropertyOpened = (propertyId) => openedProperty && openedProperty?.id === propertyId;

    const isPropertyHovered = (propertyId) => propertyId === hoveredMarkerId;

    const getMarkerType = (property) => {
        let markerType = 'MARKER_DOT';

        switch (property.featuredTier) {
            case PREMIERE_499: {
                markerType = 'MARKER_GOLD';
                break;
            }
            case FEATURED_69:
            case FEATURED_99:
            case HIGHLIGHT_199: {
                markerType = 'MARKER_BLUE';
                break;
            }
            default: {
                markerType = 'MARKER_DOT';
            }
        }
        return markerType;
    };

    const isBouncingMarker = (propertyId) => isPropertyHovered(propertyId);

    return (
        <>
            <MarkerClusterer key="marker-clusterer" averageCenter maxZoom={14} gridSize={60} enableRetinaIcons>
                {(clusterer) =>
                    markers.map((property) => {
                        const { agency, featuredTier, lat, lng } = property;

                        if (!isEmpty(agency) && (featuredTier === 'HIGHLIGHT_199' || featuredTier === 'PREMIERE_499')) {
                            const customMarker = CreateHTMLMapMarker({
                                latlng: new window.google.maps.LatLng(lat, lng),
                                map,
                                // eslint-disable-next-line max-len
                                html: `<div key="${property?.id}" class="asset-map-image-marker"><div class="image" style="background-image: url(${agency.logoUrl})"></div></div>`,
                                bouncing: isBouncingMarker(property.id),
                                markerGroup,
                            });

                            customMarker.addListener('click', () => {
                                setOpenedProperty(property);
                                setMarkerGroup(markerGroup + 1);
                            });

                            if (!isEmpty(openedProperty) && openedProperty?.id !== property.id) {
                                customMarker.addListener('mouseenter', () => {
                                    setOpenedProperty(property);
                                    setMarkerGroup(markerGroup + 1);
                                });
                            }

                            return <div key={`${property.id}`} className="hidden" />;
                        }

                        return (
                            <PropertyMapMarkerWithInfoBox
                                markerType={getMarkerType(property)}
                                isBouncing={isBouncingMarker(property?.id)}
                                propertyId={property?.id}
                                property={property}
                                lat={property?.lat}
                                lng={property?.lng}
                                key={property?.id}
                                isOpen={isPropertyOpened(property?.id)}
                                onOpen={() => {
                                    setOpenedProperty(property);
                                    setMarkerGroup(markerGroup + 1);
                                }}
                                onClose={() => setOpenedProperty(null)}
                                isFromSearch={isFromSearch}
                                clusterer={clusterer}
                            />
                        );
                    })
                }
            </MarkerClusterer>
            <div className={`${styles['property-infobox']} absolute z-50 mb-2`}>
                {openedProperty && (
                    <div className="flex justify-center">
                        <div className="relative max-w-sm">
                            <MapPropertyBox
                                featuredAgentId={featuredAgentId}
                                property={openedProperty}
                                isFromSearch
                                sr="sr"
                                carouselImageHeight="180px"
                            />
                            <div
                                className="absolute text-2xl"
                                style={{
                                    right: -9,
                                    zIndex: 10,
                                    top: -18,
                                }}
                            >
                                <Tooltip title="Close" placement="right">
                                    <CloseCircleFilled
                                        className="rounded-full bg-white"
                                        onClick={() => setOpenedProperty(null)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});

MapContent.propTypes = {
    markers: PropTypes.any,
    isFromSearch: PropTypes.bool,
    featuredAgentId: PropTypes.string,
};

MapContent.defaultProps = {
    markers: [],
    isFromSearch: false,
    featuredAgentId: null,
};

const MapPropertyBox = memo((props) => {
    const { property, sr, carouselImageHeight } = props;
    const { slugUrl, features } = property || {};

    return (
        <div className="MapPropertyBox flex max-w-sm overflow-hidden rounded bg-white">
            <div className="w-40">
                <PropertyCarousel
                    property={property}
                    height={carouselImageHeight}
                    isFromSearch={false}
                    isPropertyInView
                    showActions={false}
                    ratio="80%"
                />
            </div>
            <div className="relative p-2 sm:p-3">
                <div className="w-full">
                    <h4 className="flex w-full items-center text-left text-lg">
                        <div className="text-overflow flex-1 ">
                            <PropertyPriceParse
                                property={property}
                                rentBondClass="text-xs text-gray-700"
                                className="text-left"
                            />
                        </div>
                    </h4>

                    <div className="text-sm font-semibold text-gray-700">
                        <PropertyAddress
                            property={property}
                            className="inline-block text-left capitalize"
                            newLine
                            query={{
                                slug: slugUrl,
                                sr,
                            }}
                        />
                    </div>

                    <div className="text-left ">
                        {features?.bedrooms || features?.bathrooms || features?.totalParking ? (
                            <>
                                <Divider orientation="horizontal" style={{ margin: '0 0 0.5rem 0' }} />
                                <MainFeatures
                                    className="flex justify-around"
                                    features={features}
                                    displayText
                                    classNames={{
                                        feature: 'property-feature rounded-full mr-2 text-base text-gray-500',
                                    }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
});

MapPropertyBox.propTypes = {
    property: PropTypes.object,
    sr: PropTypes.string,
    carouselImageHeight: PropTypes.string,
};

MapPropertyBox.defaultProps = {
    property: {},
    sr: null,
    carouselImageHeight: null,
};

const EnquiryButtonWithModal = memo((props) => {
    const { agent, agency, property } = props;
    const [isShowModal, setIsShowModal] = useState(false);

    const { bannerColor } = agency || {};
    const isNotWhiteColor = bannerColor && bannerColor !== '#fff';

    const buttonStyle = {
        background: (isNotWhiteColor && bannerColor) || PRIMARY,
        color: (isNotWhiteColor && getColorShade(bannerColor)) || PRIMARY ? '#fff' : '#3D4852',
        borderColor: (isNotWhiteColor && bannerColor) || PRIMARY,
    };
    return (
        <>
            <AgentPropertyEnquiryModal
                agent={agent}
                property={property}
                onClose={() => setIsShowModal(false)}
                visible={isShowModal}
                bannerColor={bannerColor}
                buttonStyle={buttonStyle}
            />
            <Button
                type="primary"
                style={{ ...buttonStyle }}
                onClick={() => setIsShowModal(true)}
                className=" text-overflow  mt-2 w-full"
            >
                Enquire Now
            </Button>
        </>
    );
});

EnquiryButtonWithModal.propTypes = {
    property: PropTypes.object,
    agent: PropTypes.object,
    agency: PropTypes.object,
};

EnquiryButtonWithModal.defaultProps = {
    property: {},
    agent: {},
    agency: {},
};

export default PropertyMapWithMarkerClusterer;
