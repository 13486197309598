import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faGlobeAsia, faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons';
import { Form, Row, Col, Input } from 'antd';
import compact from 'lodash/compact';
import Select from 'rc-select';
import debounce from 'lodash.debounce';
import LoadingDots from '~/components/LoadingDots';
import LocationSuggestionsQuery from '~/queries/LocationSuggestionsQuery.graphql';
// import SearchFilterModalMobile from '../SearchFilterModal/SearchFilterModalMobile';
import styles from './PropertyAdvancedSearchMobile.module.scss';

const PropertyAdvancedSearchMobile = ({ onChangeOfi, searchOptions, onChange, changeSearchUrl, onChangeSorting }) => {
    const client = useApolloClient();
    const [form] = Form.useForm();
    const [searchModalVisible, setSearchModalVisible] = useState(false);
    const [queryString, setQueryString] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentKeyword, setCurrentKeyword] = useState('');

    const { setFieldsValue, getFieldsValue } = form;

    const handleSearchModalVisible = () => {
        setSearchModalVisible(!searchModalVisible);
    };

    // const handleSubmitSearchModal = data => {
    //     setSearchModalVisible(false);
    //     setFieldsValue({ ...data });
    //     changeSearchUrl({ ...data }, searchOptions);
    // };

    const handleSearch = async (value) => {
        const keyword = value;

        setIsLoading(true);
        setCurrentKeyword(value);

        try {
            const {
                data: { locationSuggestions },
            } = await client.query({
                query: LocationSuggestionsQuery,
                variables: {
                    filter: {
                        keyword,
                        first: 10,
                    },
                },
            });

            setIsLoading(false);
            setDataSource(locationSuggestions || []);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearch = useCallback(
        debounce((value) => handleSearch(value), 0),
        []
    );

    const { group, ofiTime } = searchOptions || {};

    const keywordValues = searchOptions.keywords ? searchOptions.keywords.join(', ') : '';
    const locationValues = searchOptions.locations ? searchOptions.locations.join(', ') : '';

    const renderOptions = (sourceData = [], keywordValue) => {
        if (sourceData.length === 0 && keywordValue) {
            return [
                <Select.Option
                    key={keywordValue}
                    className="show-all py-3"
                    value={`k:${keywordValue}`}
                    title={keywordValue}
                >
                    <span className="location-item text-base">Search "{keywordValue}"</span>
                </Select.Option>,
            ];
        }
        const filteredResult = sourceData.filter((item) => item !== null);

        return (
            filteredResult.length && (
                <Select.OptGroup key="locations" label={<span className="text-xs">Locations</span>}>
                    {filteredResult.map((item) => (
                        <Select.Option key={item.full} className="show-all py-3" value={item.full}>
                            <div className="location-item flex items-center">
                                <FA
                                    icon={item.type === 'SUBURB' ? faMapMarkerAlt : faGlobeAsia}
                                    className="mr-2 text-xl"
                                />
                                <span>
                                    <span className="text-base">{item.full}</span>
                                    {item.type !== 'SUBURB' && (
                                        <span className="ml-1 text-xs text-gray-500">
                                            <span className="mr-1">&bull;</span>
                                            {item.type}
                                        </span>
                                    )}
                                </span>
                            </div>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )
        );
    };

    const renderRecentSearch = () => {
        const savedItemsJson = typeof localStorage !== 'undefined' ? localStorage.getItem('previousLocation') : '[]';
        const previousLocation = compact(JSON.parse(savedItemsJson));

        return (
            previousLocation.length && (
                <Select.OptGroup key="recentSearches" label={<span className="text-xs">Recent Searches</span>}>
                    {previousLocation.map((location) => (
                        <Select.Option key={location} className="show-all location-item py-3" value={`${location}`}>
                            <span className="location-item text-base">{location}</span>
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )
        );
    };

    return (
        <div>
            {/* <SearchFilterModalMobile
                searchModalVisible={searchModalVisible}
                onClose={handleSearchModalVisible}
                onOk={handleSubmitSearchModal}
                data={{
                    ...getFieldsValue([
                        'keyword',
                        'isIncludeSurrounding',
                        'isIncludeOffMarket',
                        'bed',
                        'price',
                        'bathroom',
                        'landArea',
                        'parking',
                        'category',
                        'features',
                        'group',
                    ]),
                }}
                type={group}
                queryString={queryString}
                searchOptions={searchOptions}
            /> */}
            <div className={styles.PropertyAdvancedSearchMobile}>
                <Row>
                    <Col xs={24}>
                        <Form
                            form={form}
                            initialValues={{
                                keyword: [
                                    ...(searchOptions.keywords
                                        ? searchOptions.keywords.map((keyword) => ({
                                              key: `k:${keyword}`,
                                              label: keyword,
                                          }))
                                        : []),
                                    ...(searchOptions.locations
                                        ? searchOptions.locations.map((keyword) => ({
                                              key: keyword,
                                              label: keyword,
                                          }))
                                        : []),
                                ],
                                parking: {
                                    min: searchOptions.parking.min,
                                    max: searchOptions.parking.max,
                                } || {
                                    min: 0,
                                    max: 6,
                                },
                                landArea: {
                                    min: searchOptions.landArea.min,
                                    max: searchOptions.landArea.max,
                                } || {
                                    min: 0,
                                    max: 200000,
                                },
                                category: searchOptions.category,
                                features: searchOptions.features,
                                isIncludeSurrounding: searchOptions.isIncludeSurrounding,
                                isIncludeOffMarket: searchOptions.isIncludeOffMarket,
                                group: searchOptions.group,
                                price: {
                                    min: searchOptions.price.min,
                                    max: searchOptions.price.max,
                                } || {
                                    min: 0,
                                    max: 2000000,
                                },
                                bed: {
                                    min: searchOptions.bed.min,
                                    max: searchOptions.bed.max,
                                } || {
                                    min: 0,
                                    max: 12,
                                },
                                bathroom: {
                                    min: searchOptions.bathroom.min,
                                    max: searchOptions.bathroom.max,
                                } || {
                                    min: 0,
                                    max: 12,
                                },
                            }}
                            onValuesChange={(changedValues, allValues) => {
                                // setFieldsValue({});
                                onChange(changedValues, allValues, searchOptions);
                            }}
                        >
                            <Row className="items-center justify-between">
                                <Col xs={24}>
                                    <Form.Item
                                        name="keyword"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a suburb',
                                            },
                                        ]}
                                        className="form-item search-option advanced-search-select mt-2 block w-auto"
                                    >
                                        <Select
                                            labelInValue
                                            className="w-full"
                                            animation="slide-up"
                                            placeholder="Type a suburb"
                                            multiple
                                            allowClear
                                            dropdownClassName="property-search-dropdown"
                                            dropdownStyle={{ zIndex: 1000 }}
                                            notFoundContent={isLoading ? <LoadingDots /> : null}
                                            onSearch={onSearch}
                                            filterOption={false}
                                        >
                                            {renderOptions(dataSource, currentKeyword)}
                                            {renderRecentSearch()}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <div>
                                    {/* <div className="flex px-4 py-2 items-center">
                                        <div className="mr-2">
                                            <Button
                                                type="link"
                                                onClick={handleSearchModalVisible}
                                                icon={<FA icon={faSlidersH} className="text-lg text-primary-500" />}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <Form.Item name="landArea" className="hidden">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="features" className="hidden">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="parking" className="hidden">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="bathroom" className="hidden">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="category" className="hidden">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="bed" className="hidden">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="price" className="hidden">
                                        <Input />
                                    </Form.Item> */}
                                </div>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

PropertyAdvancedSearchMobile.propTypes = {
    onChangeOfi: PropTypes.func,
    onChange: PropTypes.func,
    onChangeSorting: PropTypes.func,
    searchOptions: PropTypes.shape({
        keyword: PropTypes.string,
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        group: PropTypes.string,
        bed: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        price: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        parking: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        landArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        floorArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        bathroom: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        mapLocation: PropTypes.shape({
            radius: PropTypes.number,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        locations: PropTypes.arrayOf(PropTypes.string),
        category: PropTypes.arrayOf(PropTypes.string),
        features: PropTypes.arrayOf(PropTypes.string),
        isIncludeSurrounding: PropTypes.bool,
        isIncludeOffMarket: PropTypes.bool,
        sorting: PropTypes.string,
        keywords: PropTypes.array,
    }),
    changeSearchUrl: PropTypes.func,
};

PropertyAdvancedSearchMobile.defaultProps = {
    onChangeOfi: () => {},
    onChange: {},
    searchOptions: {},
    changeSearchUrl: () => {},
    onChangeSorting: () => {},
};

export default PropertyAdvancedSearchMobile;
