import { useQuery } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';
import LocationByFullQuery from './Operations.graphql';

const LocationResolver = React.memo(props => {
    const { locations, children } = props;

    if (!(locations && locations.length > 0)) {
        return children(null);
    }

    const { data } = useQuery(LocationByFullQuery, {
        variables: { locations },
        skip: !locations,
    });

    return children(data ? data.locationsByFull : []);
});

LocationResolver.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.any.isRequired,
};

LocationResolver.defaultProps = { locations: [] };

export default LocationResolver;
