/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import clsx from 'clsx';
import { Breadcrumb } from 'antd';

type Props = {
    className: string
    arrayOfLinks: ArrayOfLinksProps[]
}

type ArrayOfLinksProps = {
    as: string,
    breadcrumbName: string,
    href: string
}

const PublicHeaderBreadcrumbs = (props: Props) => {
    const { arrayOfLinks, className } = props || {}
    return (
        <div className={clsx('breadcrumb-header px-6 my-3', className)}>
            <Breadcrumb separator=">">
                {arrayOfLinks.map((route, index) => (
                    <Breadcrumb.Item key={route.breadcrumbName}>
                        {index === arrayOfLinks.length - 1 ? (
                            <span className="text-brown-500">{route.breadcrumbName}</span>
                        ) : (
                            <Link href={route.href} as={route.as}>
                                <a>
                                    <span className="text-brown-500">{route.breadcrumbName}</span>
                                </a>
                            </Link>
                        )}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </div>
    );
};

PublicHeaderBreadcrumbs.propTypes = {
    arrayOfLinks: PropTypes.array,
    className: PropTypes.string,
};

PublicHeaderBreadcrumbs.defaultProps = {
    arrayOfLinks: [],
    className: '',
};

export default PublicHeaderBreadcrumbs;
