import { useApolloClient } from '@apollo/client';
import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { faGlobeAsia, faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import LocationSuggestionsQuery from '~/queries/LocationSuggestionsQuery.graphql';

const AlertPropertySearch = memo((props) => {
    const { onChange, value } = props;

    const client = useApolloClient();
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentKeyword, setCurrentKeyword] = useState(undefined);
    const [selected, setSelected] = useState(value.map(({ full }) => ({ key: full, label: full })));

    useEffect(() => {
        if ('value' in props) {
            setSelected(value);
        }
    }, [value]);

    const handleSearch = async (values) => {
        const keyword = values;

        setIsLoading(true);
        setCurrentKeyword(values);

        try {
            const {
                data: { locationSuggestions },
            } = await client.query({
                query: LocationSuggestionsQuery,
                variables: {
                    filter: {
                        keyword,
                        first: 10,
                    },
                },
            });

            setIsLoading(false);
            setDataSource(locationSuggestions || []);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearch = useCallback(
        debounce((values) => handleSearch(values), 0),
        []
    );

    const renderOptions = (sourceData = [], keyword) => {
        if (sourceData.length === 0 && keyword) {
            return [
                <Select.Option key={keyword} className="show-all location-item py-3" value={`k:${keyword}`}>
                    <span className="location-item text-base">{keyword}</span>
                </Select.Option>,
            ];
        }

        return sourceData
            .filter((item) => item !== null)
            .map((item) => (
                <Select.Option key={item.full} className="show-all py-3" value={item.full}>
                    <div className="location-item flex items-center">
                        <FA icon={item.type === 'SUBURB' ? faMapMarkerAlt : faGlobeAsia} className="mr-2 text-xl" />
                        <span>
                            <span className="text-base">{item.full}</span>
                            {item.type !== 'SUBURB' && (
                                <span className="ml-1 text-xs text-gray-500">
                                    <span className="mr-1">&bull;</span>
                                    {item.type}
                                </span>
                            )}
                        </span>
                    </div>
                </Select.Option>
            ));
    };

    const triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        onChange(changedValue);
    };

    const handleChange = (values) => {
        if (!('value' in props)) {
            setSelected(values);
        }

        triggerChange(values);
    };

    return (
        <Select
            allowClear
            size="large"
            mode="multiple"
            labelInValue
            value={selected}
            placeholder="Search Suburb"
            notFoundContent={isLoading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearch}
            onChange={handleChange}
        >
            {renderOptions(dataSource, currentKeyword)}
        </Select>
    );
});

AlertPropertySearch.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.object),
};

AlertPropertySearch.defaultProps = {
    onChange: () => {},
    value: [],
};

export default AlertPropertySearch;
