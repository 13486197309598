import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import banner from '../../assets/banners/realty-ai-banner-600x1200.jpg';
import bannerMobile from '../../assets/banners/realty-ai-banner-600x500.jpg';

type Props = {
    className?: string;
    isMobile?: boolean;
};

const RealtyDiscoverBanner: React.FC<Props> = ({ className, isMobile = false }) => (
    <div className={className}>
        <Link href="/discover">
            <a target="_blank" rel="noreferrer">
                {isMobile ? (
                    <Image src={bannerMobile} alt="Realty AI" layout="intrinsic" width={500} height={600} />
                ) : (
                    <Image src={banner} alt="Realty AI" layout="intrinsic" width={300} height={600} />
                )}
            </a>
        </Link>
    </div>
);

export default RealtyDiscoverBanner;
