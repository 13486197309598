import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const PropertyStatusSelectMobile = ({ type, onStatusChange }) => {
    return (
        <div className="flex items-center">
            <Select
                onChange={onStatusChange}
                defaultValue={type.toLowerCase()}
                size="large"
                placeholder="Any"
                className="w-full"
            >
                <Select.Option value="buy">Property for sale</Select.Option>
                <Select.Option value="rent">Property for rent</Select.Option>
                <Select.Option value="sold">Sold property</Select.Option>
            </Select>
        </div>
    );
};

PropertyStatusSelectMobile.propTypes = { type: PropTypes.string };

PropertyStatusSelectMobile.defaultProps = { type: null };

export default PropertyStatusSelectMobile;
