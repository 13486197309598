/* eslint-disable react/forbid-prop-types */
/* eslint-disable object-curly-newline */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { connect } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import slice from 'lodash/slice';
import MainFeatures from '~/components/MainFeatures';
import OffMarketPropertyAddress from '~/components/OffMarketPropertyAddress';
import PropertyCarousel from '~/components/PropertyCarousel';
import usePropertyInView from '~/utils/usePropertyInView';
import { Consumer } from './Operations.graphql';
import './OffMarketPropertyCard.module.scss';

const OffMarketPropertyCard = ({
    offsetHeaderHeight,
    property,
    ofiTime,
    isFromSearch,
    hasScrolled,
    collectionId,
    isFeed,
    sr,
    carouselImageHeight,
    isHomePage,
    showCardActions,
    isInfoBox,
    isAuthenticated,
    searchOptions,
    searchUrl,
}) => {
    const { id, features } = property;
    const client = useApolloClient();
    const card = useRef(null);
    const isPropertyInView = usePropertyInView({
        offsetHeaderHeight,
        hasScrolled,
        property,
        card: card.current,
    });

    const [hasSubscription, setHasSubscription] = useState(false);

    const checkAuthentication = async () => {
        try {
            const {
                data: { viewer },
            } = await client.query({ query: Consumer });

            const { hasOffMarketSubscription } = viewer || {};

            if (hasOffMarketSubscription) {
                setHasSubscription(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            checkAuthentication();
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            checkAuthentication();
        }
    }, [isAuthenticated]);

    const propertyObject = hasSubscription
        ? property
        : {
              ...property,
              objects: slice(property.objects, 0, 1),
          };

    return (
        <div className="w-full h-full" ref={card}>
            <div
                className={`w-full h-full bg-white relative OffMarketPropertyCard overflow-hidden ${!isFeed &&
                    'sm:rounded-lg'}`}
                key={id}
            >
                <div className="w-full relative cursor-pointer">
                    <PropertyCarousel
                        collectionId={collectionId}
                        property={propertyObject}
                        ofiTime={ofiTime}
                        height={carouselImageHeight}
                        isFromSearch={isFromSearch}
                        searchOptions={searchOptions || null}
                        searchUrl={searchUrl}
                        sr={sr}
                        isHomePage={isHomePage}
                        isPropertyInView={isPropertyInView}
                        showActions={showCardActions}
                        isInfoBox={isInfoBox}
                        ratio="75%"
                    />
                </div>

                <div className="p-2 sm:p-3 relative">
                    <div className="w-full">
                        <h4 className="text-left text-lg flex items-center w-full mb-2">
                            <Tag className="tex-xs" color="#3490DC">
                                Off-Market
                            </Tag>
                        </h4>

                        <div className="font-semibold text-sm mb-2 text-gray-700 block">
                            <OffMarketPropertyAddress
                                hasSubscription={hasSubscription}
                                property={property}
                                newLine
                                query={{
                                    isFromSearch,
                                    sr,
                                    searchOptions: searchOptions || null,
                                    searchUrl,
                                }}
                            />
                        </div>
                        <div className="text-left mb-2">
                            <MainFeatures
                                features={features}
                                displayText
                                classNames={{
                                    feature: 'property-feature rounded-full mr-2 font-semibold text-base text-blue-400',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

OffMarketPropertyCard.propTypes = {
    collectionId: PropTypes.string,
    isFeed: PropTypes.bool,
    ofiTime: PropTypes.bool,
    isInfoBox: PropTypes.bool,
    property: PropTypes.shape({
        id: PropTypes.string,
        slugUrl: PropTypes.string,
        features: PropTypes.object,
        priceView: PropTypes.string,
        isUnderOffer: PropTypes.bool,
        status: PropTypes.string,
        agents: PropTypes.array,
        agency: PropTypes.object,
        objects: PropTypes.array,
    }),
    isFromSearch: PropTypes.bool,
    carouselImageHeight: PropTypes.string,
    sr: PropTypes.string,
    hasScrolled: PropTypes.bool,
    isHomePage: PropTypes.bool,
    showCardActions: PropTypes.bool,
    offsetHeaderHeight: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    searchUrl: PropTypes.string,
    searchOptions: PropTypes.string,
};

OffMarketPropertyCard.defaultProps = {
    collectionId: '',
    isFeed: false,
    ofiTime: false,
    isInfoBox: false,
    property: {},
    isFromSearch: false,
    carouselImageHeight: '16rem',
    sr: '',
    hasScrolled: false,
    isHomePage: false,
    showCardActions: true,
    offsetHeaderHeight: 0,
    isAuthenticated: false,
    searchUrl: '',
    searchOptions: '',
};

export default connect(state => ({
    isAuthenticated: state.auth.isAuthenticated,
}))(OffMarketPropertyCard);
