import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { EyeOutlined } from '@ant-design/icons';
// import { Tag } from 'antd';
import PropertyCarousel from '~/components/PropertyCarousel';
import PropertyPriceParse from '~/components/PropertyPriceParse';
import PropertyAddress from '~/components/PropertyAddress';
import MainFeatures from '~/components/MainFeatures';
import HideListing from '~/components/HideListing';

const PropertySearchResultHideProperty = ({
    isFromSearch,
    collectionId,
    isFeed,
    sr,
    carouselImageHeight,
    isHomePage,
    showCardActions,
    isInfoBox,
    isPropertyPage,
    searchOptions,
    searchUrl,
    // onChangeList,
    // getIndex,
    property,
    propertySearchVariables,
}) => {
    const { id, features, priceView, isUnderOffer, status } = property;
    const card = useRef(null);
    const propertyStatus = (status || 'current').toUpperCase();

    // const handleClickChange = () => {
    //     onChangeList(getIndex);
    // };

    return (
        <div className="PropertySearchResultHideProperty flex items-center justify-between">
            <div className="h-full w-full" ref={card}>
                <div
                    className={clsx('FeaturedPropertyCard relative relative h-full w-full overflow-hidden bg-white', {
                        'rounded-md': !isFeed,
                    })}
                    key={id}
                >
                    <div className="absolute right-0 z-10 p-3">
                        {/* eslint-disable-next-line max-len */}
                        {/* <EyeOutlined className="text-lg text-primary-500 sm:text-xl" onClick={handleClickChange} /> */}
                        <HideListing
                            // handleClickChange={handleClickChange}
                            hideStatus="hide"
                            propertyId={id}
                            propertySearchVariables={propertySearchVariables}
                        />
                    </div>
                    <div className="flex">
                        <div className="relative w-48 cursor-pointer overflow-hidden sm:w-40">
                            <PropertyCarousel
                                collectionId={collectionId}
                                property={property}
                                height={carouselImageHeight}
                                isFromSearch={isFromSearch}
                                searchOptions={searchOptions || null}
                                searchUrl={searchUrl}
                                sr={sr}
                                isHomePage={isHomePage}
                                showActions={showCardActions}
                                isInfoBox={isInfoBox}
                                ratio="100%"
                            />
                        </div>

                        <div className="property-card-info relative flex w-3/4 flex-col justify-between p-3 sm:p-3">
                            <div className="w-full p-2">
                                {/* eslint-disable-next-line max-len */}
                                <h4 className="text-overflow flex items-center text-left text-base font-medium text-gray-800">
                                    <div className={`flex-1 ${isPropertyPage ? 'break-word' : 'text-overflow'}`}>
                                        <PropertyPriceParse
                                            className="text-left"
                                            property={property}
                                            rentBondClass="text-xs text-gray-700"
                                            isPropertyPage={isPropertyPage}
                                        />
                                    </div>
                                </h4>

                                <div className="text-overflow my-1 text-sm font-semibold leading-loose text-gray-700">
                                    <PropertyAddress
                                        property={property}
                                        newLine
                                        query={{
                                            isFromSearch,
                                            sr,
                                            searchOptions: searchOptions || null,
                                            searchUrl,
                                        }}
                                        isPropertyPage={isPropertyPage}
                                    />
                                </div>
                                <div className="text-left">
                                    <MainFeatures
                                        features={features}
                                        displayText={!isPropertyPage}
                                        classNames={{
                                            // eslint-disable-next-line max-len
                                            feature: `rounded-full property-feature mr-2 text-gray-600 font-semibold text-sm`,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PropertySearchResultHideProperty.propTypes = {
    collectionId: PropTypes.string,
    property: PropTypes.shape({
        id: PropTypes.string,
        priceView: PropTypes.string,
        isUnderOffer: PropTypes.bool,
        status: PropTypes.string,
        features: PropTypes.object,
    }),
    isFromSearch: PropTypes.bool,
    isFeed: PropTypes.bool,
    sr: PropTypes.string,
    carouselImageHeight: PropTypes.string,
    isHomePage: PropTypes.bool,
    showCardActions: PropTypes.bool,
    isInfoBox: PropTypes.bool,
    isPropertyPage: PropTypes.bool,
    searchOptions: PropTypes.string,
    searchUrl: PropTypes.string,
    getIndex: PropTypes.number,
    onChangeList: PropTypes.func,
};

PropertySearchResultHideProperty.defaultProps = {
    collectionId: '',
    property: {},
    isFromSearch: false,
    isFeed: false,
    sr: '',
    carouselImageHeight: '3.5rem',
    isHomePage: false,
    showCardActions: true,
    isInfoBox: false,
    isPropertyPage: false,
    searchOptions: '',
    searchUrl: '',
    getIndex: 0,
    onChangeList: () => {},
};

export default PropertySearchResultHideProperty;
