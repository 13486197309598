/* eslint-disable react/forbid-prop-types */
/* eslint-disable radix */
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import omit from 'lodash.omit';
import { useEffect } from 'react';

const parseSearchOptionsFromUrl = (router) => {
    let searchOptions = router.query;
    let isNewConstruction;

    const price = {};
    const bed = {};
    const bathroom = {};
    const parking = {};
    const floorArea = {};
    const landArea = {};

    if (searchOptions.priceMin) {
        Object.assign(price, { min: Number.parseFloat(searchOptions.priceMin) });
    }

    if (searchOptions.priceMax) {
        Object.assign(price, { max: Number.parseFloat(searchOptions.priceMax) });
    }

    if (searchOptions.bedMin) {
        Object.assign(bed, { min: Number.parseFloat(searchOptions.bedMin) });
    }

    if (searchOptions.bedMax) {
        Object.assign(bed, { max: Number.parseFloat(searchOptions.bedMax) });
    }

    if (searchOptions.floorAreaMin) {
        Object.assign(floorArea, { min: Number.parseFloat(searchOptions.floorAreaMin) });
    }

    if (searchOptions.floorAreaMax) {
        Object.assign(floorArea, { max: Number.parseFloat(searchOptions.floorAreaMax) });
    }

    if (searchOptions.landAreaMin) {
        Object.assign(landArea, { min: Number.parseFloat(searchOptions.landAreaMin) });
    }

    if (searchOptions.landAreaMax) {
        Object.assign(landArea, { max: Number.parseFloat(searchOptions.landAreaMax) });
    }

    if (searchOptions.parkingMin) {
        Object.assign(parking, { min: Number.parseFloat(searchOptions.parkingMin) });
    }

    if (searchOptions.parkingMax) {
        Object.assign(parking, { max: Number.parseFloat(searchOptions.parkingMax) });
    }

    if (searchOptions.bathroomMin) {
        Object.assign(bathroom, { min: Number.parseFloat(searchOptions.bathroomMin) });
    }

    if (searchOptions.bathroomMax) {
        Object.assign(bathroom, { max: Number.parseFloat(searchOptions.bathroomMax) });
    }

    // const searchType = match.params.searchType ? match.params.searchType : '';
    // const searchType = searchOptions.group || urlGroup;

    const selectedKey = [
        {
            path: '/buy',
            group: 'BUY',
        },
        {
            path: '/rent',
            group: 'RENT',
        },
        {
            path: '/sold',
            group: 'SOLD',
        },
    ].find(({ path }) => router.asPath.startsWith(path));

    const group = selectedKey ? selectedKey.group : 'BUY';

    if (searchOptions.location) {
        let data = searchOptions.location;

        if (!Array.isArray(data)) {
            data = [data];
        }

        searchOptions = {
            ...searchOptions,
            locations: data,
        };
    }

    if (searchOptions.keyword) {
        let data = searchOptions.keyword;

        if (!Array.isArray(data)) {
            data = [data];
        }

        searchOptions = {
            ...searchOptions,
            keywords: data,
        };
    }

    if (searchOptions.category) {
        let data = searchOptions.category;

        if (!Array.isArray(data)) {
            data = [data];
        }
        searchOptions = {
            ...searchOptions,
            category: data,
        };
    }

    if (searchOptions.features) {
        let data = searchOptions.features;

        if (!Array.isArray(data)) {
            data = [data];
        }
        searchOptions = {
            ...searchOptions,
            features: data,
        };
    }

    if (searchOptions.page) {
        searchOptions = {
            ...searchOptions,
            page: Number.parseInt(searchOptions.page),
        };
    }

    if (searchOptions.pageSize) {
        searchOptions = {
            ...searchOptions,
            pageSize: Number.parseInt(searchOptions.pageSize),
        };
    }

    if (searchOptions.showOfiTime === 'false') {
        searchOptions = omit(searchOptions, 'ofiTime');
    }

    if (searchOptions.isNewConstruction === 'true') {
        isNewConstruction = true;
    } else if (searchOptions.isNewConstruction === 'false') {
        isNewConstruction = false;
    } else {
        isNewConstruction = undefined;
    }

    searchOptions = omit(searchOptions, ['keyword', 'location']);

    const checkSorting = () =>
        searchOptions.sorting === 'SALES_DATE' && group !== 'SOLD' ? 'FEATURED' : searchOptions.sorting;
    const setDefaultSorting = () => (group === 'SOLD' ? 'SALES_DATE' : 'FEATURED');

    searchOptions = {
        ...searchOptions,
        showMap: searchOptions.showMap !== 'false',
        // default false if group === SOLD
        isIncludeSurrounding: group === 'SOLD' ? false : searchOptions.isIncludeSurrounding !== 'false',
        isIncludeOffMarket: searchOptions.isIncludeOffMarket === 'true',
        sorting: searchOptions.sorting ? checkSorting() : setDefaultSorting(),
        price,
        bathroom,
        bed,
        // toilet,
        parking,
        floorArea,
        landArea,
        group,
        isNewConstruction,
    };

    return searchOptions;
};

const cleanSearchOptions = (searchOptions) => {
    const options = {
        location: searchOptions.locations,
        keyword: searchOptions.keywords,
        category: searchOptions.category,
        features: searchOptions.features,
        priceMin: searchOptions.price.min,
        priceMax: searchOptions.price.max,
        bedMin: searchOptions.bed.min,
        bedMax: searchOptions.bed.max,
        bathroomMin: searchOptions.bathroom.min,
        bathroomMax: searchOptions.bathroom.max,
        parkingMin: searchOptions.parking.min,
        parkingMax: searchOptions.parking.max,
        landAreaMin: searchOptions.landArea.min,
        landAreaMax: searchOptions.landArea.max,
        // floorAreaMin: searchOptions.floorArea.min,
        // floorAreaMax: searchOptions.floorArea.max,
        mapLocation: searchOptions.mapLocation,
        showMap: searchOptions.showMap === true ? undefined : false,
        ofiTime: searchOptions.ofiTime,
        // group: searchOptions.group,
        isIncludeSurrounding: searchOptions.isIncludeSurrounding === true ? undefined : false,
        isIncludeOffMarket: searchOptions.isIncludeOffMarket === false ? undefined : true,
        sorting: searchOptions.sorting,
        acceleratePreviewAgentId: searchOptions.acceleratePreviewAgentId,
        ref: searchOptions.ref,
        page: searchOptions.page || undefined,
        pageSize: searchOptions.pageSize || undefined,
        isNewConstruction: searchOptions.isNewConstruction,
    };

    return Object.keys(options).reduce((acc, key) => {
        const item = options[key];

        if (!(item === null || item === undefined || item === '' || (Array.isArray(item) && item.length === 0))) {
            acc[key] = item;
        }
        return acc;
    }, {});
};

// const buildQueryString = searchOptions => qs.stringify(cleanSearchOptions(searchOptions), { arrayFormat: 'repeat' });

const SearchPageQueryParameterParser = (props) => {
    const { children } = props;
    const router = useRouter();
    const searchOptions = parseSearchOptionsFromUrl(router);
    const { query } = router || {};

    const changeUrl = (searchOptionsData) => {
        const pathname = `/${searchOptionsData.group.toLowerCase()}/search`;

        router.push(
            {
                pathname,
                query: cleanSearchOptions(searchOptionsData),
            },
            // `${pathname}?${buildQueryString(searchOptionsData)}`,
            undefined,
            { shallow: true }
        );
    };

    useEffect(() => {
        if (query.sorting === 'SALES_DATE' && searchOptions.group !== 'SOLD') {
            changeUrl(searchOptions);
        }
    }, [query, searchOptions]);

    return children({
        searchOptions,
        changeUrl,
    });
};

SearchPageQueryParameterParser.propTypes = {
    urlPath: PropTypes.string,
    router: PropTypes.object,
    children: PropTypes.any,
};

SearchPageQueryParameterParser.defaultProps = {
    urlPath: '/search',
    router: {},
    children: {},
};

export default SearchPageQueryParameterParser;
