import React from 'react';
import PropTypes from 'prop-types';
import PopoverPricingFilterRange from '~/components/PopoverPricingFilterRange';
import { buyRange, rentRange } from '~/constants/range';

const PopoverPriceFilterRangeFormItem = ({ onRef, onChange, type, min, max, value, range, format, suffix }) => {
    const isRent = `${type}`.toLowerCase() === 'rent';

    // const rentFilter = isRent
    //     ? {
    //           min: 0,
    //           max: 5000,
    //           step: 5,
    //       }
    //     : {};

    return (
        <PopoverPricingFilterRange
            range={isRent ? rentRange : buyRange}
            onChange={onChange}
            format="price"
            min={min}
            max={max}
            value={value}
            suffix={suffix}
            // {...rentFilter}
            ref={onRef}
        />
    );
};

PopoverPriceFilterRangeFormItem.propTypes = {
    type: PropTypes.string,
};

PopoverPriceFilterRangeFormItem.defaultProps = {
    type: null,
};

export default PopoverPriceFilterRangeFormItem;
