/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';

const PropertyOfiTime = ({ searchOptions, numberOfDays, onChangeOfi }) => {
    const getDateArray = numberOfDaysValue => {
        const arr = [];

        for (let x = 0; x < numberOfDaysValue; x += 1) {
            arr.push(dayjs().add(x, 'days'));
        }
        return arr;
    };

    const isActive = date => {
        const { ofiTime } = searchOptions || {};
        return dayjs(date).format('YYYY-MM-DD') === ofiTime;
    };

    return (
        <div className="w-full rounded bg-white py-3 sm:block hidden mt-2">
            <Row gutter={12} align="middle" justify="space-between" className="w-full -m-0">
                {getDateArray(numberOfDays).map(date => (
                    <Col xs={8} sm={6} md={4} lg={3} xl={3} key={date} className="mb-2">
                        <div
                            className={`py-2 rounded cursor-pointer text-center hover:bg-blue-500 hover:text-white ${
                                isActive(date)
                                    ? 'text-primary-500 bg-white border border-primary-500'
                                    : 'bg-gray-300 text-gray-500'
                            }`}
                            onClick={() => onChangeOfi(dayjs(date).format('YYYY-MM-DD'))}
                            aria-hidden
                        >
                            <p className="text-xs font-semibold">
                                {date.toString() === dayjs().toString()
                                    ? 'TODAY'
                                    : dayjs(date)
                                          .format('ddd')
                                          .toUpperCase()}
                            </p>
                            <h1
                                className={`hover:bg-blue-500 hover:text-white ${
                                    isActive(date) ? 'text-primary-500 ' : 'text-gray-700'
                                }`}
                                onClick={() => onChangeOfi(dayjs(date).format('YYYY-MM-DD'))}
                                aria-hidden
                            >
                                {dayjs(date).format('D')}
                            </h1>
                            <p className="text-xs font-semibold">{dayjs(date).format('MMM')}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

PropertyOfiTime.propTypes = {
    numberOfDays: PropTypes.number,
    onChangeOfi: PropTypes.func,
    searchOptions: PropTypes.object,
};

PropertyOfiTime.defaultProps = {
    numberOfDays: 7,
    onChangeOfi: () => {},
    searchOptions: {},
};

export default PropertyOfiTime;
