/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';
import './ConsumerOffMarketPackage.module.scss';

const ConsumerOffMarketPackage = props => {
    const { onHandleSubmit, offMarketChosenPlan } = props;

    const [offMarketPlan, setOffMarketPlan] = useState(offMarketChosenPlan);

    const handleChangePackage = offMarketPackage => {
        setOffMarketPlan(offMarketPackage);
    };

    const premiumBorder = offMarketPlan === 'OFF_MARKET_PRO_ALERTS' ? 'border-gray-400' : 'border-primary-500';
    const proBorder = offMarketPlan === 'OFF_MARKET_PRO_ALERTS' ? 'border-primary-500' : 'border-gray-400';

    return (
        <div className="ConsumerOffMarketPackage lg:justify-between justify-center w-full">
            <h1 className=" text-primary-500 text-2xl text-center font-normal pb-2">Choose a Package</h1>
            <p className=" text-gray-700 text-center font-light">
                Search off-market properties to access "top draw" properties not advertised on the major portals.
            </p>
            <div className="flex justify-center py-4">
                <div className="max-w-md w-full">
                    <Divider className="my-4" />
                    <div className="flex justify-center w-full items-center">
                        <div
                            // Commented until PRO is available
                            // className={`cursor-pointer text-center border-2 ${premiumBorder} mr-4 px-5 h-56 py-8 max-w-xxs w-full transition`}
                            className={`cursor-pointer text-center border-2 ${premiumBorder} px-5 h-56 py-8 max-w-xxs w-full transition`}
                            onClick={() => handleChangePackage('OFF_MARKET_PREMIUM_ALERTS')}
                        >
                            <h1 className="text-lg mb-2">PREMIUM</h1>
                            <p className="text-base">$29 / Month +GST</p>
                            <Divider className="my-3 w-full" />
                            <ul className="list-disc text-left mx-auto inline-block pl-3">
                                <li className="text-sm mb-2">Cancel Anytime!</li>
                                <li className="text-sm mb-2">7 days free trial</li>
                            </ul>
                        </div>
                        {/* Commented until PRO is available */}
                        {/* <div
                            className={`cursor-pointer text-center border-2 ${proBorder} ml-4 px-5 h-56 py-8 max-w-xxs w-full transition`}
                            onClick={() => handleChangePackage('OFF_MARKET_PRO_ALERTS')}
                        >
                            <h1 className="text-lg mb-2">PRO</h1>
                            <p className="text-base">$199 / Month +GST</p>
                            <Divider className="my-3 w-full" />
                            <ul className="list-disc text-left mx-auto inline-block pl-5 pr-1">
                                <li className="text-sm mb-2">Unlimited suburbs</li>
                                <li className="text-sm mb-2">Up to 150 sms alert a month</li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="py-3 w-full flex justify-center items-center">
                <Button
                    onClick={() => onHandleSubmit(offMarketPlan)}
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className="max-w-xs"
                    block
                >
                    NEXT
                </Button>
            </div>
        </div>
    );
};

ConsumerOffMarketPackage.propTypes = {
    onHandleSubmit: PropTypes.func,
    offMarketChosenPlan: PropTypes.string,
};

ConsumerOffMarketPackage.defaultProps = {
    onHandleSubmit: () => {},
    offMarketChosenPlan: null,
};

export default ConsumerOffMarketPackage;
