/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);

const StripeLoader = ({ children }) => {
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        setStripe(stripePromise);
    }, []);

    return <>{stripe && <Elements stripe={stripe}>{children}</Elements>}</>;
};

StripeLoader.propTypes = { children: PropTypes.object };

StripeLoader.defaultProps = { children: {} };

export default StripeLoader;

// /* eslint-disable react/forbid-prop-types */
// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { StripeProvider } from 'react-stripe-elements';

// // import Head from 'next/head';

// class StripeLoader extends Component {
//     static propTypes = { children: PropTypes.object };

//     static defaultProps = { children: {} };

//     state = { stripe: null };

//     componentDidMount() {
//         if (window.Stripe) {
//             this.setState({ stripe: window.Stripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY) });
//         } else {
//             const selector = document.querySelector('#stripe-js');

//             if (selector !== null) {
//                 selector.addEventListener('load', () => {
//                     // Create Stripe instance once Stripe.js loads
//                     this.setState({ stripe: window.Stripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY) });
//                 });
//             }
//         }
//     }

//     render() {
//         const { stripe } = this.state;
//         const { children } = this.props;
//         return (
//             <>
//                 {/* <Head> */}
//                 {/*    /!*<script id="stripe-js" src="https://js.stripe.com/v3/" async />*!/ */}
//                 {/* </Head> */}
//                 <StripeProvider stripe={stripe}>{children}</StripeProvider>
//             </>
//         );
//     }
// }

// export default StripeLoader;
