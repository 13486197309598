import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import LandAreaFilterRange from '~/components/LandAreaFilterRange';

const ModalLandAreaFilterRangeFormItem = forwardRef(({ onChange, value }, ref) => (
    <div className="items-middle flex flex-col justify-center" ref={ref}>
        <div className="text-base font-medium">Land Area</div>
        <div className="mb-3">
            <LandAreaFilterRange value={value} onChange={onChange} min={0} selectOptions={{ style: { width: 110 } }} />
        </div>
    </div>
));
ModalLandAreaFilterRangeFormItem.propTypes = { onChange: PropTypes.func, value: PropTypes.object };

ModalLandAreaFilterRangeFormItem.defaultProps = { onChange: () => {} };

export default ModalLandAreaFilterRangeFormItem;
