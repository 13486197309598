import { useApolloClient } from '@apollo/client';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Checkbox, Divider, Drawer, Input, Modal, Form, Row, Col } from 'antd';
import 'rc-select/assets/index.css';
import { faGlobeAsia, faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import compact from 'lodash/compact';
import isEmpty from 'lodash.isempty';
import Select from 'rc-select';
import { loginUser } from '~/actions';
import LoadingDots from '~/components/LoadingDots';
import ModalBuyPriceFilterRangeFormItem from '~/components/ModalBuyPriceFilterRangeFormItem';
import ModalLandAreaFilterRangeFormItem from '~/components/ModalLandAreaFilterRangeFormItem';
import PropertyFeaturesFilter from '~components/PropertyFeaturesFilter';
import ModalNumberSlider from '~/components/SearchFilterModal/ModalNumSlider';
import ModalCategory from '~/components/SearchFilterModal/ModalCategory';
import PublicCreateOffMarketSubModal from '~/components/PublicCreateOffMarketSubModal';
import ParkingNumberSelect from '~/components/SearchFilterPopover/ParkingNumberSelect';
import BathroomNumberSelect from '~/components/SearchFilterPopover/BathroomNumberSelect';
import ModalBedFilterRangeFormItem from '~/components/ModalBedFilterRangeFormItem';
import PropertyStatusSelectMobile from '~components/PropertyStatusSelectMobile';
import NewOrEstablishedSelect from '~/components/SearchFilterPopover/NewOrEstablishedSelect';
import LocationSuggestionsQuery from '~/queries/LocationSuggestionsQuery.graphql';
import { Consumer } from './Operations.graphql';

const SearchFilterDrawerMobile = ({
    searchDrawerVisible,
    searchOptions,
    onClose,
    authUserId,
    isAuthenticated,
    data,
    onOk,
    type,
}) => {
    const [form] = Form.useForm();
    const client = useApolloClient();
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);
    const [currentKeyword, setCurrentKeyword] = useState(false);

    const { getFieldsValue, setFieldsValue } = form;

    const handleCancel = () => {
        onClose();
    };

    const checkAuthentication = async () => {
        if (!isAuthenticated) {
            setIsShowLoginModal(true);
        } else {
            try {
                const {
                    data: { viewer },
                } = await client.query({ query: Consumer });

                const { hasOffMarketSubscription } = viewer || {};

                if (!hasOffMarketSubscription) {
                    setIsShowLoginModal(true);
                } else {
                    setFieldsValue({ isIncludeOffMarket: !data.isIncludeOffMarket });
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const closeLoginModal = () => {
        setIsShowLoginModal(false);
    };

    const onChangeParking = value => {
        setFieldsValue({ parking: { min: value } });
    };

    const onChangeBathroom = value => {
        setFieldsValue({ bathroom: { min: value } });
    };

    const onStatusChange = value => {
        setFieldsValue({ group: value });
    };

    const onChangeConstruction = value => {
        setFieldsValue({
            isNewConstruction: value,
        });
    };

    const handleSubmitFilterDrawer = e => {
        e.preventDefault();
        setFieldsValue({
            keyword: [
                ...(searchOptions.keywords
                    ? searchOptions.keywords.map(keyword => ({
                          key: `k:${keyword}`,
                          label: keyword,
                      }))
                    : []),
                ...(searchOptions.locations
                    ? searchOptions.locations.map(keyword => ({
                          key: keyword,
                          label: keyword,
                      }))
                    : []),
            ],
        });

        const formData = getFieldsValue([
            'keyword',
            'isIncludeSurrounding',
            'isIncludeOffMarket',
            'price',
            'bed',
            'bathroom',
            'parking',
            'landArea',
            'category',
            'features',
            'group',
            'isNewConstruction',
        ]);
        onOk(formData);
    };

    if (!searchDrawerVisible) return '';

    return (
        <>
            <PublicCreateOffMarketSubModal
                visible={isShowLoginModal}
                onClose={closeLoginModal}
                authUserId={authUserId}
                headingText="Access our off-market properties using your social media"
            />
            <Drawer
                className="SearchFilterDrawerMobile"
                title="More Filters"
                width="100%"
                visible={searchDrawerVisible}
                onClose={handleCancel}
                destroyOnClose
                bodyStyle={{
                    height: '72vh',
                    overflow: 'auto',
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            className="w-full"
                            size="large"
                            onClick={handleSubmitFilterDrawer}
                            type="primary"
                            style={{ backgroundColor: '#F0060E', border: '1px solid #F0060E' }}
                        >
                            Save Changes
                        </Button>
                    </div>
                }
                // onOk={handleSubmitFilterDrawer}
            >
                <Form
                    form={form}
                    initialValues={{
                        parking: {
                            min: searchOptions.parking.min,
                            max: searchOptions.parking.max,
                        } || {
                            min: 0,
                            max: 6,
                        },
                        landArea: {
                            min: searchOptions.landArea.min,
                            max: searchOptions.landArea.max,
                        } || {
                            min: 0,
                            max: 200000,
                        },
                        category: searchOptions.category || ['All'],
                        features: searchOptions.features || null,
                        keyword: [
                            ...(searchOptions.keywords
                                ? searchOptions.keywords.map(keyword => ({
                                      key: `k:${keyword}`,
                                      label: keyword,
                                  }))
                                : []),
                            ...(searchOptions.locations
                                ? searchOptions.locations.map(keyword => ({
                                      key: keyword,
                                      label: keyword,
                                  }))
                                : []),
                        ],
                        isIncludeSurrounding: searchOptions.isIncludeSurrounding,
                        isIncludeOffMarket: searchOptions.isIncludeOffMarket,
                        group: searchOptions.group,
                        price: {
                            min: searchOptions.price.min,
                            max: searchOptions.price.max,
                        } || {
                            min: 0,
                            max: 2000000,
                        },
                        bed: {
                            min: searchOptions.bed.min,
                            max: searchOptions.bed.max,
                        } || {
                            min: 0,
                            max: 12,
                        },
                        bathroom: {
                            min: searchOptions.bathroom.min,
                            max: searchOptions.bathroom.max,
                        } || {
                            min: 0,
                            max: 12,
                        },
                        isNewConstruction: searchOptions.isNewConstruction,
                    }}
                    // onValuesChange={(changedValues, allValues) => {
                    //     onChange(changedValues, allValues, searchOptions);
                    // }}
                >
                    <div className="text-base font-medium">I am looking for a:</div>
                    <Form.Item name="group" className="w-auto form-item search-option block my-2">
                        <PropertyStatusSelectMobile type={type} onStatusChange={onStatusChange} />
                    </Form.Item>
                    <Form.Item name="isIncludeSurrounding" valuePropName="checked" className="form-item">
                        <Checkbox>
                            <span className="text-sm">Show nearby suburbs</span>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="isIncludeOffMarket"
                        valuePropName="checked"
                        className="form-item"
                        onClick={checkAuthentication}
                    >
                        <Checkbox className="pointer-events-none">
                            <span className="text-sm">Show off-market</span>
                        </Checkbox>
                    </Form.Item>
                    <Divider />

                    <div className="text-base font-medium">Categories</div>

                    <Form.Item name="category" className="w-auto form-item search-option block mt-6">
                        <ModalCategory />
                    </Form.Item>
                    <Divider />
                    <Form.Item name="price" className="w-auto form-item search-option block mt-6">
                        <ModalBuyPriceFilterRangeFormItem type={type} />
                    </Form.Item>
                    <Form.Item name="bed" className="w-auto form-item search-option block mt-2">
                        <ModalBedFilterRangeFormItem />
                    </Form.Item>
                    <div className="text-base font-medium mt-4">New or Established</div>
                    <Form.Item name="isNewConstruction" className="w-auto form-item search-option block mt-2">
                        <NewOrEstablishedSelect
                            currentValue={searchOptions.isNewConstruction}
                            onChange={onChangeConstruction}
                        />
                    </Form.Item>
                    <div className="text-base font-medium mt-4">Bath</div>
                    <Form.Item name="bathroom" className="w-auto form-item search-option block mt-2">
                        <BathroomNumberSelect onChange={onChangeBathroom} />
                    </Form.Item>
                    <div className="text-base font-medium mt-4">Parking</div>
                    <Form.Item name="parking" className="w-auto form-item search-option block mt-2">
                        <ParkingNumberSelect onChange={onChangeParking} />
                    </Form.Item>
                    <Form.Item name="landArea" className="w-auto form-item search-option block mt-6">
                        <ModalLandAreaFilterRangeFormItem />
                    </Form.Item>
                    <Divider className="mt-10" />
                    <Form.Item name="features" className="w-auto form-item search-option block mt-6">
                        <PropertyFeaturesFilter />
                    </Form.Item>
                    <Form.Item name="keyword" className="hidden">
                        <Input />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

SearchFilterDrawerMobile.propTypes = {
    searchDrawerVisible: PropTypes.bool,
    onClose: PropTypes.func,
    authUserId: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    onOk: PropTypes.func,
    type: PropTypes.string,
    queryString: PropTypes.shape({
        bedMin: PropTypes.number,
        bathroomMin: PropTypes.number,
        parkingMin: PropTypes.number,
        bedMax: PropTypes.number,
        bathroomMax: PropTypes.number,
        parkingMax: PropTypes.number,
    }),
    searchOptions: PropTypes.shape({
        keyword: PropTypes.string,
        showMap: PropTypes.bool,
        ofiTime: PropTypes.string,
        group: PropTypes.string,
        bed: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        price: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        parking: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        landArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        floorArea: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        bathroom: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        mapLocation: PropTypes.shape({
            radius: PropTypes.number,
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        locations: PropTypes.arrayOf(PropTypes.string),
        category: PropTypes.arrayOf(PropTypes.string),
        features: PropTypes.arrayOf(PropTypes.string),
        isIncludeSurrounding: PropTypes.bool,
        isIncludeOffMarket: PropTypes.bool,
        sorting: PropTypes.string,
        keywords: PropTypes.array,
        acceleratePreviewAgentId: PropTypes.string,
        isNewConstruction: PropTypes.bool,
    }),
};

SearchFilterDrawerMobile.defaultProps = {
    searchDrawerVisible: false,
    onClose: () => {},
    data: {},
    onOk: () => {},
    type: 'BUY',
    queryString: {},
    authUserId: null,
    isAuthenticated: false,
    searchOptions: {},
};

export default connect(
    state => ({
        isAuthenticated: state.auth.isAuthenticated,
        authUserId: state.auth.user ? state.auth.user.id : null,
    }),
    dispatch => ({
        onLoginUser(accessToken) {
            dispatch(loginUser(accessToken));
        },
    })
)(SearchFilterDrawerMobile);
