import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import FilterRange from '~/components/FilterRange';
import { bedRange } from '~/constants/range';

const ModalBedFilterRangeFormItem = forwardRef(({ type, value, onChange, ...rest }, ref) => (
    <div className="flex flex-col justify-center" ref={ref}>
        <div className="text-base font-medium">Bed</div>
        <div className="mb-3 ">
            <FilterRange
                value={value}
                onChange={onChange}
                range={bedRange}
                {...rest}
                selectOptions={{ style: { width: 110 } }}
            />
        </div>
    </div>
));

ModalBedFilterRangeFormItem.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
};

ModalBedFilterRangeFormItem.defaultProps = { onChange: () => {} };
export default ModalBedFilterRangeFormItem;
