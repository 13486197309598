import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import styles from './NewOrEstablishedSelect.module.scss';
const { Option } = Select;

const NewOrEstablishedSelect = (props) => {
    const { onChange, currentValue } = props;

    return (
        <div className={`${styles.cutomSelect} w-full`}>
            <Select onChange={onChange} size="default" placeholder="Any" className="w-full" defaultValue={currentValue}>
                <Option value={true} className="text-sm">
                    New
                </Option>
                <Option value={false} className="text-sm">
                    Established
                </Option>
            </Select>
        </div>
    );
};

NewOrEstablishedSelect.propTypes = { onChange: PropTypes.func };

NewOrEstablishedSelect.defaultProps = { onChange: () => {} };

export default NewOrEstablishedSelect;
