/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import NumberPlusMinusInput from '../NumberPlusMinusInput';

const FeatureFilterFormItem = forwardRef(({ onChange, feature, value }, ref) => {
    const [val, setVal] = useState(value.min);

    useEffect(() => {
        if (value.min !== val) {
            setVal(value.min);
        }
    }, [value]);

    const handleChange = values => {
        setVal(values);
        onChange({
            min: values,
            max: undefined,
        });
    };

    return <NumberPlusMinusInput ref={ref} label={feature} value={val} onChange={handleChange} />;
});

FeatureFilterFormItem.propTypes = {
    onChange: PropTypes.func,
    feature: PropTypes.string,
};

FeatureFilterFormItem.defaultProps = {
    onChange: () => {},
    feature: '',
};

export default FeatureFilterFormItem;
