import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const categories = [
    'All',
    'Acreage',
    'Apartment',
    'Unit',
    'Villa',
    'Flat',
    'House',
    'Studio',
    'Terrace',
    'Retirement',
    'Town House',
    'Block Of Units',
    'Duplex Semi Detached',
    'Land',
];

class ModalCategory extends Component {
    static propTypes = { onChange: PropTypes.func };

    static defaultProps = { onChange: () => {} };

    constructor(props) {
        super(props);
        const { value } = this.props;

        this.state = { selected: value };
    }

    componentWillReceiveProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            const { value } = nextProps;
            this.setState({ selected: value });
        }
    }

    handleChange = (value) => {
        const { selected } = this.state;

        let newSelected = [];

        if (value === 'All') {
            newSelected = ['All'];
        } else if (selected.includes(value)) {
            const index = selected.indexOf(value);

            if (index > -1) {
                newSelected = [...selected.slice(0, index), ...selected.slice(index + 1)];
            }
        } else {
            const index = selected.indexOf('All');
            if (index > -1) {
                newSelected = [...selected.slice(0, index), ...selected.slice(index + 1)];
                newSelected = [...newSelected, value];
            } else {
                newSelected = [...selected, value];
            }
        }

        this.setState({ selected: newSelected });
        this.triggerChange(newSelected);

        // if (!('value' in this.props)) {
        // this.setState({ selected: newSelected });
        // }
    };

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const { onChange } = this.props;
        onChange(changedValue);
    };

    render() {
        const { selected } = this.state;
        return (
            <section>
                {categories.map((category) => (
                    <Button
                        type={selected.includes(category) ? 'primary' : ''}
                        className="mb-2 mr-2 rounded"
                        onClick={() => this.handleChange(category)}
                        key={category}
                    >
                        <span className="px-1">{category}</span>
                    </Button>
                ))}
            </section>
        );
    }
}

export default ModalCategory;
