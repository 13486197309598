import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PhoneOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import BrokerEnquiryForm from '~/components/BrokerEnquiryForm';

const BrokerEnquiryModal = memo((props) => {
    const { propertyId, onClose, visible, firstName, brokerId, bannerColor } = props;
    return (
        <Modal
            centered
            footer={null}
            onCancel={onClose}
            visible={visible}
            title={
                <span className="">
                    <PhoneOutlined className="mr-2" />
                    Send&nbsp;
                    {firstName}
                    &nbsp;a Message
                </span>
            }
        >
            <BrokerEnquiryForm
                bannerColor={bannerColor}
                propertyId={propertyId}
                brokerId={brokerId}
                onClose={onClose}
            />
        </Modal>
    );
});

BrokerEnquiryModal.propTypes = {
    onClose: PropTypes.func,
    propertyId: PropTypes.string,
    brokerId: PropTypes.string,
    firstName: PropTypes.string,
    visible: PropTypes.bool,
    bannerColor: PropTypes.string,
};

BrokerEnquiryModal.defaultProps = {
    onClose: () => {},
    propertyId: '',
    firstName: '',
    bannerColor: '',
    brokerId: null,
    visible: false,
};
export default BrokerEnquiryModal;
