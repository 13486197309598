import React, { memo } from 'react';
import { Button } from 'antd';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import './WhatsappButton.module.scss';

type WhatsappButtonProps = {
  contactNo: string
  propertyTitle: string
}

const WhatsappButton = memo(({ contactNo, propertyTitle }: WhatsappButtonProps) => {
  const defaultMessage = `Hi, please send me more information about the "${propertyTitle}" property.`;

  return (
    <a href={`https://wa.me/${contactNo}/?text=${defaultMessage}`} target="_blank" rel="noreferrer">
      <Button className="whatsapp-button" shape="round">
        <FA icon={faWhatsapp} className="mr-2" /> Ask on Whatsapp
      </Button>
    </a>
  );
});

export default WhatsappButton;
