/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EnvironmentOutlined, LeftOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Input, message, Modal, Radio, Row, Form } from 'antd';
import { trackEvent } from '~/lib/analytics';
import { getColorShade } from '~/utils/colors';
import { getBlackOrWhiteTextColorBasedOnBgColor } from '~/utils/colors';

import PRIMARY from '~/constants/colors';
import PersonalCollectionStatement from '~/components/PersonalCollectionStatement';
import useIpApi from '~/hooks/useIpApi';
import styles from './AgentEnquiryForm.module.scss';
import { CreateAgentEnquiryMutation } from './Operations.graphql';

const AgentEnquiryForm = ({ agency, agentId, bannerColor, onSuccess }) => {
    const [form] = Form.useForm();
    const { resetFields, getFieldValue } = form;
    const [createAgentEnquiry] = useMutation(CreateAgentEnquiryMutation);
    const [chosen, setChosen] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [next, setNext] = useState(false);
    const { ipApiData } = useIpApi();

    const handleSubmit = (values) => {
        setIsLoading(true);

        const transformedValues = {
            ...values,
            agentId,
            enquiries: [chosen],
            referrer: window.document.referrer || null,
            pageUrl: window.location.href,
            ipInfo: {
                ipAddress: ipApiData.query,
                continent: ipApiData?.continent,
                continentCode: ipApiData?.continentCode,
                country: ipApiData?.country,
                countryCode: ipApiData?.countryCode,
                region: ipApiData?.region,
                regionName: ipApiData?.regionName,
                city: ipApiData?.city,
                district: ipApiData?.district,
                zip: ipApiData?.zip,
                lat: ipApiData?.lat,
                lon: ipApiData?.lon,
                timezone: ipApiData?.timezone,
                offset: ipApiData?.offset,
                currency: ipApiData?.currency,
                isp: ipApiData?.isp,
                org: ipApiData?.org,
                as: ipApiData?.as,
                asname: ipApiData?.asname,
                mobile: ipApiData?.mobile,
            },
        };

        createAgentEnquiry({ variables: { input: transformedValues } })
            .then(() => {
                trackEvent('Submitted Agent Enquiry', {
                    dimension_1: parseInt(agentId, 10),
                    dimension_2: parseInt(agency.id, 10),
                });

                Modal.success({
                    title: 'Enquiry Successfully Sent',
                    onOk: () => {
                        setChosen(null);
                        resetFields();
                        onSuccess();
                    },
                });
            })
            .catch(() => {
                message.error('Encountered an Error');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChange = ({ target: { value } }) => {
        setChosen(value);
    };

    const back = () => {
        // setChosen(null);
        setNext(false);
    };

    const handleNext = () => {
        setNext(true);
    };

    const isNotWhiteColor = bannerColor && bannerColor !== '#ffffff';
    const buttonStyle = {
        background: (isNotWhiteColor && bannerColor) || PRIMARY,
        //  color: (isNotWhiteColor && getColorShade(bannerColor)) || PRIMARY ? '#fff' : '#3D4852',
        color: getBlackOrWhiteTextColorBasedOnBgColor(agency?.bannerColor ?? '#000') ?? '#000',
    };

    return (
        <Form
            onFinish={handleSubmit}
            className={styles.AgentEnquiryForm}
            form={form}
            initialValues={{ enquiries: getFieldValue('enquiries') ? onChange : null }}
        >
            <Row justify="center" align="middle" className="w-full">
                <Col xs={23}>
                    {!next && (
                        <>
                            <Form.Item name="enquiries" rules={[{ required: true }]} className="w-full">
                                <div className={styles.agentFormWrapper}>
                                    <Radio.Group onChange={onChange} className="w-full">
                                        <Radio.Button value="SELLING_PROPERTY" className="choices mb-2 w-full">
                                            <span className="text-sm sm:text-lg">Selling a property</span>
                                        </Radio.Button>
                                        <br />
                                        <Radio.Button value="PROPERTY_MANAGEMENT" className="choices mb-2 w-full">
                                            <span className="text-sm sm:text-lg">Property management</span>
                                        </Radio.Button>
                                        <br />
                                        <Radio.Button value="PROPERTY_INFO" className="choices mb-2 w-full">
                                            <span className="text-sm sm:text-lg">Information about a property</span>
                                        </Radio.Button>
                                        <br />
                                        <Radio.Button value="GENERAL_INQUIRY" className="choices mb-2 w-full">
                                            <span className="text-sm sm:text-lg">General enquiry</span>
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    size="large"
                                    htmlType="submit"
                                    className="text-overflow mb-2 mr-2 w-full rounded text-sm uppercase"
                                    disabled={!getFieldValue('enquiries')}
                                    style={{ ...buttonStyle, borderRadius: '0.25rem', marginBottom: '0.5rem' }}
                                    onClick={handleNext}
                                >
                                    <span className="px-1 text-xs">Next</span>
                                </Button>
                            </Form.Item>
                        </>
                    )}
                    {next && (
                        <>
                            <h2 className="mb-4 rounded-full border border-gray-400 bg-white px-4 py-1 text-base font-medium text-gray-700">
                                {chosen === 'SELLING_PROPERTY' && 'Selling a Property'}
                                {chosen === 'PROPERTY_MANAGEMENT' && 'Property management'}
                                {chosen === 'PROPERTY_INFO' && 'Information about a property'}
                                {chosen === 'GENERAL_INQUIRY' && 'General enquiry'}
                            </h2>
                            <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">
                                Name
                            </span>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Your name is required',
                                    },
                                ]}
                                className="w-full"
                            >
                                <Input
                                    className="form-input w-full"
                                    size="large"
                                    placeholder="John Smith"
                                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            </Form.Item>
                            {chosen !== 'General enquiry' && (
                                <>
                                    <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">
                                        Property Address
                                    </span>
                                    <Form.Item name="propertyAddress" className="w-full">
                                        <Input
                                            className="form-input w-full"
                                            size="large"
                                            placeholder="2/13 Central Coast Highway, Erina, NSW 2250"
                                            prefix={<EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        />
                                    </Form.Item>
                                </>
                            )}
                            <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">
                                Email
                            </span>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Your email is required',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email',
                                    },
                                ]}
                                className="w-full"
                            >
                                <Input
                                    className="form-input w-full"
                                    size="large"
                                    placeholder="example@domain.com"
                                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            </Form.Item>
                            <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">
                                Phone
                            </span>
                            <Form.Item name="contactNumber" className="w-full">
                                <Input
                                    className="form-input w-full"
                                    size="large"
                                    placeholder="(07) 4521 3379"
                                    prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            </Form.Item>
                            <span className="inline-block py-1 text-xs font-semibold uppercase text-gray-700">
                                Message
                            </span>
                            <Form.Item
                                name="message"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Your message is required',
                                    },
                                ]}
                                className="w-full"
                            >
                                <Input.TextArea
                                    rows={4}
                                    className="form-input"
                                    placeholder="Your message to the agent"
                                />
                            </Form.Item>
                            <div className="flex-no-wrap flex flex-row">
                                <Button
                                    size="large"
                                    className="mb-4 mr-1 flex-1 text-sm uppercase"
                                    onClick={() => back()}
                                    style={{ ...buttonStyle }}
                                >
                                    <LeftOutlined className="text-xs font-bold" />
                                    &nbsp;Back
                                </Button>
                                <Button
                                    size="large"
                                    htmlType="submit"
                                    className="text-overflow w-3/4 text-sm uppercase"
                                    loading={isLoading}
                                    style={{ ...buttonStyle }}
                                >
                                    Send Enquiry
                                </Button>
                            </div>
                            <PersonalCollectionStatement />
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

AgentEnquiryForm.propTypes = {
    agency: PropTypes.object,
    agentId: PropTypes.string,
    bannerColor: PropTypes.string,
    onSuccess: PropTypes.func,
};

AgentEnquiryForm.defaultProps = {
    agency: {},
    agentId: null,
    bannerColor: '',
    onSuccess: () => {},
};

export default AgentEnquiryForm;
